import { usePricingFormStore } from '~/stores/pricing-form';
import { report } from '~/utils/telemetry';

export function useAnalyticsHelpers() {
  const store = usePricingFormStore();

  function getSegmentId(silent = false) {
    const analytics = (window.analytics = window.analytics || []);
    try {
      return analytics.user().id() || analytics.user().anonymousId();
    } catch (error) {
      if (!silent) {
        report(error);
      }
      return '';
    }
  }

  function getGDPROptInPreferences() {
    const samsaraCookies = useCookie('samsara_cookies', {
      maxAge: 86400 * 180, // 180 days
    });
    return {
      functionalOptIn: samsaraCookies.value
        ? samsaraCookies.value.includes('fn')
        : true,
      analyticsOptIn: samsaraCookies.value
        ? samsaraCookies.value.includes('an')
        : false,
      advertisingOptIn: samsaraCookies.value
        ? samsaraCookies.value.includes('ad')
        : false,
    };
  }

  function getUTMCookies() {
    const ATTRIBUTION_COOKIES = [
      'keyword',
      'utm_campaign',
      'utm_source',
      'utm_medium',
      'utm_content',
      'utm_term',
      'utm_ts',
      'utm_ext_ad_id',
      'utm_ext_adset_id',
      'utm_ext_campaign_id',
      'fpv',
      'referrer',
      'cid',
      'gclid',
      '_mkto_trk',
      'msclkid',
    ];

    return ATTRIBUTION_COOKIES.map((key) => {
      const cookieRef = useCookie(key);
      const cookie = {};
      cookie.key = key;
      cookie.value = cookieRef.value;
      return cookie;
    }).filter((cookie) => cookie.value !== '');
  }

  function getQuantcastID() {
    // The quantcast cookie needs to be set because it is used as orderId
    // in the tracking pixel on the thank you pages. The orderId is used
    // by quantcast for attribution.
    const quantcastId = useCookie('quantcast_id', { maxAge: 90 });
    if (!quantcastId.value) {
      // If the cookie doesn't exist we create a new value and store it
      const randomID = (1 + Math.random()).toString(36).substr(2, 9);
      quantcastId.value = randomID;
      return randomID;
    } else {
      // If the cookie is already defined, we return it
      return quantcastId;
    }
  }

  function getOptimizelyExperimentName() {
    const optimizelyEndUserId = useCookie('optimizelyEndUserId');
    return optimizelyEndUserId.value;
  }

  function updateOptimizelyExperimentValues() {
    if (process.client) {
      try {
        (async () => {
          const maxAttempts = 10;
          let attempts = 0;
          // wait for script to load
          while (
            typeof window.optimizelyEdge === 'undefined' &&
            attempts < maxAttempts
          ) {
            attempts += 1;
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
          if (
            attempts <= maxAttempts &&
            typeof window.optimizelyEdge !== 'undefined'
          ) {
            const experiments = window.optimizelyEdge
              .get('state')
              .getActiveExperiments();
            if (experiments) {
              for (const [key, value] of Object.entries(experiments)) {
                const flag = value.name.replace(/ /g, '_');
                const flagValue = value.variation.name.replace(/ /g, '_');

                updateExperimentValue(flag, flagValue);

                // Run analytics after store update, getting this info into the pricing form is priority one
                const { analyticsTrack } = useAnalytics();
                const { fullPath } = useRoute();

                analyticsTrack('Optimizely Experiment Evaluated', {
                  experiment: value.name,
                  variation: value.variation.name,
                  url: fullPath,
                });
              }
            }
          }
        })();
      } catch (error) {
        report(error);
      }
    }
  }

  function updateExperimentValue(flag, value) {
    const currentExp = store.experimentName
      ? store.experimentName.split(',')
      : [];
    const filtered = currentExp.filter((x) => !x.includes(flag));
    filtered.push(`${flag}=${value},`);
    const filterEmpty = filtered.filter((x) => String(x).trim());
    store.experimentName = filterEmpty.toString();
  }

  return {
    getGDPROptInPreferences,
    getUTMCookies,
    getQuantcastID,
    getOptimizelyExperimentName,
    getSegmentId,
    updateExperimentValue,
    updateOptimizelyExperimentValues,
  };
}
