<template>
  <div class="yt-embed-container">
    <div class="yt-embed-inner aspect--16x9 relative">
      <iframe
        v-if="youtubePlayerState === 'play'"
        id="youtubeiframe"
        ref="ytVideo"
        v-lazy-load
        :title="title"
        :src="'https://www.youtube.com/embed/' + video.id"
        class="youtubeIframe absolute h-full w-full"
        allow="autoplay; fullscreen"
      ></iframe>
      <slot></slot>
    </div>
    <LinksContentfulLink v-if="id" :id="id" label="video" />
    <p v-if="video.style === 'Blog' && video.footnote" class="mt-1 text-xs">
      {{ video.footnote }}
    </p>
  </div>
</template>
<style lang="scss" scoped>
  .image--cover-image {
    &:hover {
      .svg--play-icon {
        transform-origin: center;
        transition: transform 300ms ease;
        transform: scale(1.1);
      }
    }
    &::after {
      content: '';
      @apply absolute bottom-0 left-0 w-full;

      height: 66%;
      background: linear-gradient(0deg, #000 -20.25%, rgba(0, 0, 0, 0) 87%);
    }
  }
</style>
<script>
  export default {
    props: {
      video: {
        type: Object,
        required: true,
      },
      youtubePlayerState: {
        type: String,
        default: 'unstarted',
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
      title: {
        type: String,
        required: false,
        default: 'Youtube Video',
      },
    },
    data() {
      return {
        interval: null,
        youtubeVideoEl: null,
      };
    },
    watch: {
      youtubePlayerState(newValue, oldValue) {
        if (newValue === 'play') {
          this.checkYtVideo();
        }
      },
    },
    mounted() {
      if (this.youtubePlayerState === 'play') {
        this.checkYtVideo();
      }
    },
    methods: {
      checkYtVideo() {
        if (this.interval === null) {
          this.interval = setInterval(this.playYtVideo, 300);
        }
      },
      clearIntervalPoll() {
        clearInterval(this.interval);
        this.interval = null;
        this.youtubeVideoEl = null;
      },
      playYtVideo() {
        if (!this.youtubeVideoEl) {
          this.youtubeVideoEl = document.querySelector('#youtubeiframe');
        }
        if (this.youtubeVideoEl !== undefined) {
          const src = this.youtubeVideoEl.src + '?autoplay=1';
          this.youtubeVideoEl.setAttribute('src', src);
          this.clearIntervalPoll();
        } else {
          this.clearIntervalPoll();
        }
      },
    },
  };
</script>
