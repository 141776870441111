<template>
  <div class="container">
    <div class="flex items-center justify-center">
      <div class="column w-full pt-1 md:pt-2 pb-1 md:pb-2 text-center">
        <a href="/">
          <img
            src="https://images.ctfassets.net/bx9krvy0u3sx/2VYZz1Kc03q7QZ2nOsq86F/b28bf0d1bd56494978d6c62cc36479df/logo_samsara.png"
            alt="samsara logo"
            class="inline max-w-[200px]"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {};
</script>
