<template>
  <div>
    <p
      class="p--subhead mb-0 cursor-pointer"
      @click.prevent="openModal = !openModal"
    >
      {{ arrowlink.text
      }}<span
        ><svg
          class="inline"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12.5" cy="12.5" r="12.5" fill="#0384FB" />
          <path
            d="M17 12.5L10.25 16.3971L10.25 8.60289L17 12.5Z"
            fill="white"
          />
        </svg>
      </span>
    </p>
    <ModalsTheModal
      v-if="arrowlink.linkUrl"
      :is-open="openModal"
      close-location="outer"
      bg="bg-transparent"
      @close="closeModal"
    >
      <div class="modal__content m-auto w-full">
        <iframe
          v-if="openModal"
          v-lazy-load
          title="embedded video"
          height="480"
          width="853"
          :src="`https://www.youtube.com/embed/${arrowlink.linkUrl}?rel=0&showinfo=0&modestbranding=1&autoplay=1`"
          frameborder="0"
          allow="autoplay; encrypted-media;"
          allowfullscreen
          class="m-auto"
        ></iframe>
      </div>
    </ModalsTheModal>
  </div>
</template>
<style lang="scss" scoped>
  .p--subhead {
    @apply font-medium text-blue-dark;
  }
</style>
<script>
  export default {
    props: {
      arrowlink: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        openModal: false,
      };
    },
    methods: {
      closeModal() {
        this.openModal = false;
      },
    },
  };
</script>
