<template>
  <span :class="classes" class="icon">
    <ImagesNuxtImg
      class="icon mb-1 mt-8 inline-block h-24 w-24"
      :url="src"
      :alt="alt"
      :style="styles"
    />
    <h5 v-if="icon.header" class="icon-header" v-html="icon.header" />
    <LinksContentfulLink v-if="id" :id="id" label="icon" />
  </span>
</template>

<script setup>
  const props = defineProps({
    icon: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  });

  const styles = computed(() => {
    const styles = {
      maxWidth: props.icon.maxWidth ? `${props.icon.maxWidth}px` : null,
    };
    return styles;
  });

  const src = computed(() => {
    return props.icon && props.icon.image
      ? props.icon.image.fields.file.url
      : '';
  });

  const alt = computed(() => {
    return props.icon && props.icon.alt ? props.icon.alt : 'icon';
  });

  const classes = computed(() => {
    if (props.icon.classes && Array.isArray(props.icon.classes)) {
      return props.icon.classes.join(' ');
    }
    return props.icon.classes ? props.icon.classes : '';
  });
</script>

<style lang="scss" scoped>
  .text-center {
    h5 {
      margin: 0 auto;
    }
  }
  .icon {
    &-header {
      max-width: 150px;
    }
  }
  .img-w-auto {
    img {
      width: auto;
    }
  }

  .h-auto {
    img {
      height: auto;
    }
  }
</style>
