<template>
  <header
    v-if="getNestedProperty(data, 'fields.staticHeader', false) !== 'None'"
    id="header"
    class="relative z-50 block"
    :class="{ 'h-[52px] md:h-[75px]': !isTransparent }"
  >
    <HeadersTheNavigation
      v-if="getNestedProperty(data, 'fields.staticHeader', false) === 'Default'"
      :is-transparent="isTransparent"
      :hide-pricing="hidePricing"
      :banner-closed="bannerClosed"
      :sticky-header="getNestedProperty(data, 'fields.stickyHeader', null)"
      :custom-pricing="customPricing"
    />
    <HeadersSimpleNavigation
      v-else-if="
        getNestedProperty(data, 'fields.staticHeader', false) === 'Simple'
      "
      :hide-pricing="hidePricing"
    />
    <HeadersCustomNavigation
      v-else-if="
        getNestedProperty(data, 'fields.staticHeader', false) === 'Custom'
      "
      :list="
        data.fields.navigationItems !== undefined
          ? data.fields.navigationItems[0].fields.listItems
          : []
      "
      :fields="data.fields"
    />
    <HeadersTheNavigation
      v-else
      :is-transparent="isTransparent"
      :sticky-header="getNestedProperty(data, 'fields.stickyHeader', null)"
      :secondary-navigation-title="
        getNestedProperty(data, 'fields.secondaryNavigationTitle', '')
      "
      :hide-pricing="hidePricing"
      :banner-closed="bannerClosed"
      :custom-pricing="customPricing"
    />
  </header>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        required: false,
        default: () => {
          return {
            fields: {
              staticHeader: false,
              stickyHeader: false,
            },
          };
        },
      },
      isTransparent: {
        type: Boolean,
        required: false,
        default: false,
      },
      hidePricing: {
        type: Boolean,
        required: false,
        default: false,
      },
      hideBanner: {
        type: Boolean,
        required: false,
        default: false,
      },
      customPricing: {
        type: String,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        bannerClosed: false,
      };
    },
    methods: {
      bannerExists(value) {
        this.bannerClosed = value;
      },
    },
  };
</script>
