<template>
  <section
    v-show="entryObj.visibility || entryObj.visibility === undefined"
    :id="entryObj?.uniqueId"
    :data-type="entryObj.dataType"
    :class="{
      'lw-wrapper--freescroll !p-0': navigationActive || freeScrollActive,
    }"
    class="lw-wrapper w-full px-gap2 pt-space2 lg:container"
  >
    <div
      v-if="entryObj.listItems"
      ref="listContainer"
      class="lw-wrapper-inner"
      :class="[innerWrapperClasses, freeScrollClasses]"
    >
      <ListsListItem
        v-for="(item, index) of items"
        ref="listItem"
        :key="index"
        :list-item-data-id="index"
        :list-item-ctfl-id="item?.sys?.id"
        :item-type="entryObj.listType"
        :item-fields="item.fields"
        :aspect-ratio="entryObj?.featuredMediaAspectRatio"
        :content-type="item?.sys?.contentType?.sys?.id || 'blogPost'"
        :item-link-text="entryObj?.linkText || 'read more'"
        :visible-limit="entryObj?.visibleLimit || 3"
        :media-position="entryObj?.featuredMediaPosition || 'object-center'"
        :show-author="entryObj?.showAuthor || false"
        :total-items="items.length"
        :class="[
          listItemClasses(index),
          itemsPerRowDesktop,
          itemsPerRowTablet,
          itemsPerRowMobile,
          defaultItemClasses,
          freeScrollItemClasses,
        ]"
        class="text-left transition-all duration-100 ease-in-out"
      />
      <div
        v-if="entryObj.loadAdditionalItemsText"
        :class="[
          {
            ['border-t-1 border-gray-light']: !shouldHideOnMobile,
          },
          'mb-4 flex w-full justify-center pt-2',
          buttonClasses,
        ]"
      >
        <ButtonsSemanticButton
          v-show="total > numberShown"
          classes="btn btn--blue cursor-pointer"
          :class="buttonClasses"
          :text="
            entryObj.loadAdditionalItemsText
              ? entryObj.loadAdditionalItemsText
              : ''
          "
          @click="showMore"
          @keyup="showMore"
        />
        <ButtonsSemanticButton
          v-show="total <= numberShown"
          classes="btn btn--blue cursor-pointer"
          :class="buttonClasses"
          :text="entryObj.loadAdditionalItemsText ? 'View Less' : ''"
          @click="showLess"
          @keyup="showLess"
        />
      </div>
    </div>
    <span
      v-if="entryObj?.navigation && isMobileDevice"
      class="relative flex justify-center pt-2"
    >
      <ButtonsSemanticButton
        :text="''"
        :aria-label="'Previous'"
        :classes="'rounded-full w-3 h-3 shadow-1 mr-1/2'"
        class="active:scale-90 active:shadow-none"
        :disabled="previousButtonDisabled"
        @click="previous"
        ><IconsPreviousArrowIcon
          :dropshadow="true"
          class="pointer-events-none h-full w-full"
          :fill-color="!previousButtonDisabled ? '#0384FB' : '#333'"
        />
      </ButtonsSemanticButton>
      <ButtonsSemanticButton
        :text="''"
        :aria-label="'Next'"
        :classes="'rounded-full w-3 h-3 shadow-1 ml-1/2'"
        class="active:scale-90 active:shadow-none"
        :disabled="nextButtonDisabled"
        @click="next"
      >
        <IconsNextArrowIcon
          :dropshadow="true"
          class="pointer-events-none h-full w-full"
          :fill-color="!nextButtonDisabled ? '#0384FB' : '#333'"
        />
      </ButtonsSemanticButton>
    </span>
  </section>
</template>
<script>
  export default {
    props: {
      entryObj: {
        type: Object,
        required: true,
      },
      visibility: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data() {
      const limit =
        this.entryObj?.limitVisibleItems && this.entryObj?.visibleLimit
          ? this.entryObj?.visibleLimit
          : 3;

      const shouldHideOnMobile =
        this.entryObj?.listStyle === 'Hide Mobile Logos' || false;

      return {
        list: [],
        observer: null,
        listItemIndex: 0,
        isMobileDevice: false,
        nextButtonDisabled: false,
        previousButtonDisabled: true,
        total: this.entryObj.listItems ? this.entryObj.listItems.length : 0, // total num of listItems
        visibleLimit: limit, // Stores how many per page are shown
        numberShown: limit, // Stores how many are currently being shown
        shouldHideOnMobile, // If we should only limit on mobile views
        buttonClasses: {
          'shown-on-mobile': shouldHideOnMobile,
        },
      };
    },
    computed: {
      transformAspectRatio() {
        const dictionary = {
          '16:9': 'aspect--16x9',
          '3:2': 'aspect--3x2',
          '1:1': 'aspect--1x1',
          '4:3': 'aspect--4x3',
          '3:4': 'aspect-[3/4]',
        };
        return dictionary[this.entryObj?.featuredMediaAspectRatio || '3:2'];
      },
      itemsLength() {
        return this.items ? this.items.length : 0;
      },
      items() {
        return this.entryObj.listItems;
      },
      itemsPerRowDesktop() {
        const dict = {
          '4 Items': 'lg:w-3/12',
          '3 Items': 'lg:w-4/12',
          '2 Items': 'lg:w-6/12',
          '1 Item': 'lg:w-12/12',
        };
        return this.entryObj.itemsPerRowDesktop
          ? dict[this.entryObj.itemsPerRowDesktop]
          : 'lg:w-4/12';
      },
      itemsPerRowTablet() {
        const dict = {
          '4 Items': 'md:w-3/12',
          '3 Items': 'md:w-4/12',
          '2 Items': 'md:w-6/12',
          '1 Item': 'md:w-12/12',
        };
        return this.entryObj.itemsPerRowTablet
          ? dict[this.entryObj.itemsPerRowTablet]
          : 'md:w-3/12';
      },
      freeScrollClasses() {
        return this.entryObj.freeScroll && this.isMobileDevice
          ? ` gap-1 flex-nowrap w-full overflow-x-scroll overflow-y-hidden h-full transition-all scroll-smooth`
          : '';
      },
      defaultItemClasses() {
        return !this.entryObj.freeScroll ? ' block px-1/2' : '';
      },
      freeScrollItemClasses() {
        return this.entryObj.freeScroll && this.isMobileDevice
          ? ' flex-[0_0_280px]'
          : '';
      },
      navigationActive() {
        return !!(
          this.entryObj.navigation !== undefined &&
          this.entryObj.navigation &&
          this.isMobileDevice
        );
      },
      freeScrollActive() {
        return !!(
          this.entryObj.freeScroll !== undefined &&
          this.entryObj.freeScroll &&
          this.isMobileDevice
        );
      },
      alignmentClasses() {
        const alignments = {
          Center: 'justify-center',
        };
        return alignments[this.entryObj.listAlignment]
          ? alignments[this.entryObj.listAlignment]
          : 'align-stretch';
      },
      defaultClasses() {
        return ' flex flex-row flex-wrap';
      },
      innerWrapperClasses() {
        return this.alignmentClasses + this.defaultClasses;
      },
      itemsPerRowMobile() {
        const dict = {
          '4 Items': 'w-3/12',
          '3 Items': 'w-4/12',
          '2 Items': 'w-6/12',
          '1 Item': 'w-12/12',
        };
        return this.entryObj.itemsPerRowMobile
          ? dict[this.entryObj.itemsPerRowMobile]
          : 'w-12/12';
      },
    },
    watch: {
      listItemIndex(newValue) {
        if (parseFloat(newValue) === 0) {
          this.previousButtonDisabled = true;
          this.nextButtonDisabled = false;
          this.$refs.listContainer.style.transform = 'translateX(20px)';
        } else if (parseFloat(newValue) < this.itemsLength - 1) {
          this.previousButtonDisabled = false;
          this.nextButtonDisabled = false;
          this.$refs.listContainer.style.transform = 'translateX(0px)';
        } else {
          this.previousButtonDisabled = false;
          this.nextButtonDisabled = true;
          this.$refs.listContainer.style.transform = 'translateX(-20px)';
        }
      },
      navigationActive: {
        handler(newValue) {
          if (newValue) {
            this.$refs.listContainer.scrollLeft = 0;
          }
        },
        immediate: true,
      },
    },
    mounted() {
      window.addEventListener('resize', this.onWindowResize);
      this.onWindowResize();
      if (
        (this.navigationActive || this.freeScrollActive) &&
        this.$refs.listContainer &&
        this.$refs.listItem
      ) {
        this.runObserver();
      }
    },
    unmounted() {
      window.removeEventListener('resize', this.onWindowResize);
      if (this.observer) {
        this.observer.disconnect();
      }
    },

    methods: {
      isMobile() {
        return window.innerWidth < 761;
      },
      runObserver() {
        this.observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                this.listItemIndex =
                  entry.target.getAttribute('list-item-data-id');
              }
            });
          },
          {
            threshold: 0.85,
          }
        );
        this.$refs.listItem.forEach((element) => {
          if (element.$el) {
            this.observer.observe(element.$el);
          }
        });
      },
      onWindowResize() {
        this.isMobileDevice = window.innerWidth < 761;
        if (this.navigationActive) {
          this.$refs.listContainer.scrollLeft = 0;
          this.listItemIndex = 0;
        }
      },
      setScrollBy() {
        if (this.listItemIndex < this.itemsLength - 1) {
          this.$refs.listContainer.scrollLeft =
            this.$refs.listItem[this.listItemIndex].$el.offsetLeft - 20;
        } else {
          this.$refs.listContainer.scrollLeft =
            this.$refs.listItem[this.listItemIndex].$el.offsetLeft;
        }
      },
      previous() {
        this.listItemIndex--;

        this.setScrollBy();
      },
      next() {
        this.listItemIndex++;

        this.setScrollBy();
      },
      showMore() {
        this.numberShown += this.visibleLimit;
      },
      showLess() {
        this.numberShown -= this.visibleLimit;
      },
      listItemClasses(index) {
        return {
          // Only hide this item on mobile if: Contentful is set to shouldHideOnMobile &&
          // this index is higher than the number that should be shown && limitVisibleItems is not false
          'hidden-on-mobile':
            this.shouldHideOnMobile &&
            index + 1 > this.numberShown &&
            this.entryObj.limitVisibleItems !== false,
          'list-item-hide-mobile sm-max:basis-6/12 py-1':
            this.shouldHideOnMobile,
          'md:border-r-1 md:border-gray-light':
            this.shouldHideOnMobile && index % 2 === 0,
          'md:border-r-1 md:border-gray-light ':
            this.shouldHideOnMobile && index % 4 !== 3,
        };
      },
    },
  };
</script>
<style lang="scss" scoped>
  .shown-on-mobile {
    @apply flex justify-center;

    @screen md {
      @apply hidden;
    }
  }
  .hidden-on-mobile {
    @apply hidden;
    @screen md {
      @apply flex;
    }
  }

  .list-item-hide-mobile :deep(img) {
    @apply m-auto block max-h-[45px] w-auto  max-w-[154px];
  }
</style>
