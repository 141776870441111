<template>
  <form
    v-if="type === 'Search'"
    class="flex-column relative mt-[35px] flex w-full justify-between lg:w-[428px]"
    method="POST"
    @submit.prevent
  >
    <input
      type="search"
      :v-model="searchTerm"
      class="search-input block h-[58px] w-full rounded-full border-solid pl-[24px]"
      :class="{
        'placeholder-primary-blue': placeholderTextColor === 'Primary Blue',
        'placeholder-tertiary-300': placeholderTextColor === 'Neutral 300',
      }"
      :placeholder="placeholder"
      :aria-label="placeholder"
      autocomplete="off"
      spellcheck="false"
      pattern="^[^ ].+[^ ]$"
      required
      @input="debounceSearch"
    />
    <button
      class="search-submit-btn absolute right-0 top-[50%] z-10 m-0 h-full w-[60px] translate-y-[-50%] cursor-pointer rounded-br-full rounded-tr-full bg-white p-0 pr-[20px] transition-all hover:scale-90 hover:!bg-slate-50 active:scale-75"
      @click.prevent="formSubmit()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
      >
        <circle
          cx="11.1129"
          cy="11.1129"
          r="9.40323"
          :stroke="iconColor"
          stroke-width="3.41935"
        />
        <path
          d="M25.4199 26.4999L17.7264 18.8064"
          :stroke="iconColor"
          stroke-width="3.41935"
          stroke-linecap="round"
        />
      </svg>
    </button>
    <TooltipsToolTip
      v-if="tooltip && tooltipText !== ''"
      :text="tooltipText"
      :visible="tootipIsVisible"
      :theme="'light'"
    />
    <LinksContentfulLink v-if="id" :id="id" label="input" />
  </form>
</template>

<script>
  import debounce from 'lodash.debounce';
  export default {
    props: {
      placeholder: {
        type: String,
        default: 'Search',
      },
      type: {
        type: String,
        required: true,
        default: 'Search',
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
      iconColor: {
        type: String,
        required: false,
        default: '#00263E',
      },
      placeholderTextColor: {
        type: String,
        required: false,
        default: 'Neutral 300',
      },
      tooltip: {
        type: Boolean,
        required: false,
        default: false,
      },
      tooltipText: {
        type: String,
        required: false,
        default: '',
      },
      endpoint: {
        type: String,
        required: false,
        default: null,
      },
    },
    setup() {
      const router = useRouter();

      return {
        router,
      };
    },
    data() {
      return {
        searchTerm: '',
        tootipIsVisible: false,
      };
    },
    methods: {
      checkSearchTerm() {
        if (this.searchTerm === '') {
          this.tootipIsVisible = true;
        }
        this.resetToolTip();
      },
      resetToolTip() {
        setTimeout(() => {
          this.tootipIsVisible = false;
        }, 2000);
      },
      debounceSearch: debounce(function (e) {
        this.searchTerm = e.target.value;
      }, 100),
      formSubmit() {
        this.checkSearchTerm();
        if (this.searchTerm !== '') {
          this.router.push({
            path: `${this.endpoint}`,
            query: { search: `${this.searchTerm}` },
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  input::placeholder,
  input[type='search'] {
    font-size: 20px;
  }

  input[type='search'].dark {
    background: #222;
    color: #fff;
  }

  input[type='search'].light {
    background: #fff;
    color: #222;
  }

  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  input[type='search'].dark::-webkit-search-cancel-button {
    filter: invert(1);
  }
  .search-input:focus-visible + .search-submit-btn,
  .search-input:focus + .search-submit-btn {
    @apply border-y-2 border-r-2 border-transparent bg-transparent;
  }
</style>
