export enum ButtonAction {
  PLAY_AUDIO = 'Play Audio',
  TOGGLE_MODAL = 'Toggle Modal',
  JUMP_LINK = 'Jump Link',
}

export enum ButtonVariant {
  PRIMARY_BLUE = 'Primary Blue',
}

export enum Icon {
  AUDIO = 'Audio',
  DOWNLOAD = 'Download',
  ARROW_DOWN = 'Arrow Down',
  PLAY = 'Play',
  MODAL = 'Modal',
  PHONE = 'Phone',
}

export enum Colors {
  RED_PRIMARY = 'Red Primary',
  RED_SECONDARY = 'Red Secondary',
  WHITE_PRIMARY = 'White Primary',
  WHITE_SECONDARY = 'White Secondary',
  GREEN_SECONDARY_OPEN = 'Green Secondary Open',
  BLUE_PRIMARY = 'Blue Primary',
  BLUE_PRIMARY_OUTLINE = 'Blue Primary Outline',
  BLUE_SECONDARY_SQUARE = 'Blue Primary Square',
}

export enum IconPosition {
  BEFORE_TEXT = 'Before Text',
  AFTER_TEXT = 'After Text',
}
