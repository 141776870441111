<template>
  <section
    class="ds-default-slider relative w-full overflow-hidden lg:container"
    :class="{
      'bg-blue-lighter p-1 md:!p-4':
        carousel?.backgroundColor === 'Light Blue' || false,
      'bg-white p-1 md:p-4': carousel?.backgroundColor === 'White' || false,
      'ds-default-slider--one-column': carousel?.layout === 'One Column',
      'media-loaded': mediaLoaded,
    }"
  >
    <h2
      v-if="carousel.title"
      class="mb-1 px-gap md:mb-0"
      :class="{
        'text-left': carousel.textAlignment
          ? carousel.textAlignment === 'Left'
          : false,
        'text-center': carousel.textAlignment
          ? carousel.textAlignment === 'Center'
          : false,
        'text-right': carousel.textAlignment
          ? carousel.textAlignment === 'Right'
          : false,
      }"
    >
      {{ carousel.title }}
    </h2>
    <div
      class="ds-default-slider-slide--wrapper relative block w-full md:hidden"
    >
      <div
        v-for="(slide, index) in carousel.slides"
        :key="'C' + index"
        ref="slidesTop"
        :class="{
          'flex-col-reverse md:flex-row':
            carousel?.layout === 'Two Columns' ||
            carousel?.layout === undefined,
          'flex-col md:h-[600px]': carousel?.layout === 'One Column',
          'active flex': activeIndex === index,
          hidden: activeIndex !== index,
        }"
        class="ds-default-slider--slide relative w-full justify-between px-gap transition-all"
      >
        <div
          v-if="
            slide.fields.image &&
            slide.fields.image.fields.image &&
            slide.fields.image.fields.image.fields.file
          "
          :class="{
            'h-auto w-full md:w-6/12':
              carousel?.layout === 'Two Columns' ||
              carousel.layout === undefined,
            'h-full w-full': carousel?.layout === 'One Column',
          }"
          class="relative mb-space2 block md:mb-0"
        >
          <ImagesFigureNextGenImg
            v-if="
              slide.fields.image.fields.image.fields.file &&
              slide.fields.image.fields.caption
            "
            class="relative block h-auto"
            :url="slide.fields.image.fields.image.fields.file.url"
            :image="slide.fields.image.fields.image"
            :alt="
              slide.fields.image.fields.alt ? slide.fields.image.fields.alt : ''
            "
          >
            <MarkdownTheMarkdown
              :source="slide.fields?.image?.fields?.caption || ''"
            />
          </ImagesFigureNextGenImg>
          <ImagesNuxtImg
            v-else-if="slide.fields.image.fields.image.fields.file"
            :url="slide.fields.image.fields.image.fields.file.url"
            :alt="
              slide.fields.image.fields.alt ? slide.fields.image.fields.alt : ''
            "
            class="w-full"
          />
        </div>
        <div
          v-else-if="
            slide.fields.image &&
            slide.fields.image.fields.video &&
            slide.fields.image.fields.video.fields.file &&
            slide.fields.image.fields.video.fields.file.contentType ===
              'video/mp4'
          "
          class="aspect--16x9 relative mb-space2 h-0 md:container"
        >
          <VideosTheVideo
            class="absolute bottom-0 left-0 right-0 top-0"
            :mp4-player-state="setVideoState(index)"
            :video="slide.fields.image.fields"
            :lazy="true"
          />
        </div>
      </div>
    </div>
    <nav
      v-if="
        carousel.labelsType === 'Bars' && carousel.labelsPlacement === 'Top'
      "
      ref="nav"
      :class="{
        'mb-0': carousel?.layout === 'One Column',
        'mb-2': !carousel.layout || carousel.layout === 'Two Columns',
      }"
      class="ds-default-slider-nav--bars relative mb-1 mt-1 w-full md:mt-[30px]"
    >
      <ul
        ref="scrollNavBars"
        :class="{ 'ml-[11px]': activeIndex === 0 }"
        class="ds-nav-menu ds-nav-menu--bars relative mt-0 inline-block h-auto w-full overflow-y-visible overflow-x-scroll whitespace-nowrap pr-2 md:ml-0 md:mr-0 md:mt-1 md:flex md:flex-row md:flex-nowrap md:items-center md:justify-around md:overflow-x-visible md:pl-0 md:pr-0"
      >
        <li
          v-for="(navItem, index) in carousel.slides"
          :key="'A' + index"
          :data-index="index"
          :class="{
            active: activeIndex === index,
            'h-[170px] md:h-auto': navItem.fields.subLabel,
            'h-auto': !navItem.fields.subLabel,
          }"
          class="ds-nav-item relative mx-[2px] inline-block w-8/12 md:flex md:w-full md:flex-grow md:flex-col md:self-stretch"
          @click="setActiveSlide(index), navInteracted()"
        >
          <span
            class="transition-all"
            :class="{
              'cursor-pointer hover:opacity-100': activeIndex !== index,
              'opacity-70': activeIndex !== index,
            }"
          >
            <h3
              v-if="navItem.fields.label"
              :class="{
                'font-medium': index === activeIndex,
                'font-normal': index !== activeIndex,
                'text-2xl': carousel.labelFontSize
                  ? carousel.labelFontSize === 'Large'
                  : false,
                'text-xl':
                  carousel.labelFontSize === undefined ||
                  carousel.labelFontSize === 'Default',
                'text-[17px]': carousel.labelFontSize
                  ? carousel.labelFontSize === 'Small'
                  : false,
              }"
            >
              {{ navItem.fields.label ? navItem.fields.label : '' }}
            </h3>
            <span
              class="indicator z-10 block h-[5px] w-full overflow-hidden bg-gray-lightest"
            >
              <span
                class="indicator-animated absolute z-10 h-[5px] w-full origin-left scale-0"
                :class="{
                  play: index === activeIndex && !navActive,
                  pause: index !== activeIndex,
                  active: navActive && index === activeIndex,
                }"
              ></span>
            </span>
          </span>
          <p
            v-if="navItem.fields.subLabel"
            :class="{
              'translate-y-[0px] opacity-100': index === activeIndex,
              'translate-y-[-3px] opacity-0': index !== activeIndex,
            }"
            class="ds-default-slider--sublabel absolute mb-0 mt-[20px] w-full pb-0 transition-all md:relative md:mt-0 md:pt-[15px]"
          >
            {{ navItem.fields.subLabel }}
          </p>
        </li>
      </ul>
    </nav>
    <nav
      v-if="
        carousel.labelsType === 'Arrows' && carousel.labelsPlacement === 'Top'
      "
      ref="nav"
      class="relative mt-space2 w-full md:mt-3"
    >
      <ul
        class="flex flex-col justify-evenly md:flex-row md:flex-wrap md:justify-between lg:flex-nowrap"
      >
        <li
          v-for="(navItem, index) in carousel.slides"
          :key="'B' + index"
          :class="{ 'active ': activeIndex === index }"
          class="ds-nav-item h-3 font-bold uppercase hover:cursor-pointer hover:opacity-70 md:mr-1 md:h-auto md:w-[194px] md:pb-1 md:pt-0 lg:pb-0"
          @click="setActiveSlide(index)"
        >
          <span class="space-between flex h-full flex-row md:h-auto">
            <IconsArrowIcon
              class="mr-[12px] w-[24px] self-center md:mt-[7px] md:self-start"
            />
            <span
              class="self-center"
              :class="{
                'text-dark': activeIndex !== index,
                'text-blue underline': activeIndex === index,
              }"
              >{{ navItem.fields.label ? navItem.fields.label : '' }}</span
            ></span
          >
        </li>
      </ul>
    </nav>
    <div class="ds-default-slider-slide--wrapper relative w-full">
      <div
        v-for="(slide, index) in carousel.slides"
        :key="'C' + index"
        ref="slidesBottom"
        :class="{
          'flex-col-reverse md:flex-row':
            carousel?.layout === 'Two Columns' || carousel.layout === undefined,
          'flex-col md:h-[600px]': carousel?.layout === 'One Column',
          'active flex': activeIndex === index,
          hidden: activeIndex !== index,
          'items-center': slide.fields.mediaPosition === 'Center',
        }"
        class="ds-default-slider--slide relative w-full justify-between px-gap transition-all"
      >
        <div
          v-if="slide.fields.content"
          :class="{
            'w-full md:w-5/12':
              carousel?.layout === 'Two Columns' ||
              carousel.layout === undefined,
            'w-full': carousel?.layout === 'One Column',
          }"
          class="ds-text relative h-full md:pr-1"
        >
          <RichTextRenderer
            class="ds-text-content"
            :document="slide.fields.content"
            :node-renderers="nodeRenderers"
          />
        </div>
        <div
          v-if="
            slide.fields.image &&
            slide.fields.image.fields.image &&
            slide.fields.image.fields.image.fields.file
          "
          :class="{
            'h-auto w-full md:w-6/12':
              carousel?.layout === 'Two Columns' ||
              carousel.layout === undefined,
            'h-full w-full': carousel?.layout === 'One Column',
          }"
          class="relative mb-space2 hidden md:mb-0 md:block"
        >
          <ImagesFigureNextGenImg
            v-if="
              slide.fields.image.fields.image.fields.file &&
              slide.fields.image.fields.caption
            "
            class="relative block h-auto"
            :url="slide.fields.image.fields.image.fields.file.url"
            :alt="
              slide.fields.image.fields.alt ? slide.fields.image.fields.alt : ''
            "
          >
            <MarkdownTheMarkdown
              :source="slide.fields?.image?.fields?.caption || ''"
            />
          </ImagesFigureNextGenImg>
          <ImagesNuxtImg
            v-else-if="slide.fields.image.fields.image.fields.file"
            :url="slide.fields.image.fields.image.fields.file.url"
            :alt="
              slide.fields.image.fields.alt ? slide.fields.image.fields.alt : ''
            "
            class="w-full"
          />
        </div>
        <div
          v-else-if="
            slide.fields.image &&
            slide.fields.image.fields.video &&
            slide.fields.image.fields.video.fields.file &&
            slide.fields.image.fields.video.fields.file.contentType ===
              'video/mp4'
          "
          :class="{
            'aspect--16x9 h-0 md:container':
              carousel?.layout === 'One Column' ||
              carousel.layout === undefined,
            'md:h-[300px] md:w-6/12': carousel?.layout === 'Two Columns',
          }"
          class="relative hidden md:block"
        >
          <VideosTheVideo
            class="absolute bottom-0 left-0 right-0 top-0"
            :mp4-player-state="setVideoState(index)"
            :video="slide.fields.image.fields"
            :lazy="true"
          />
        </div>
      </div>
    </div>
    <nav
      v-if="
        carousel.navigation === true &&
        carousel.navigationPlacement === 'Fixed Bottom Left'
      "
      aria-label="Previous and Next Buttons"
      class="ds-navigation-arrows relative bottom-0 left-0 hidden md:block"
    >
      <ButtonsSemanticButton
        :text="''"
        :aria-label="'Previous'"
        :classes="'rounded-full w-3 h-3 shadow-1 mr-1/2'"
        class="active:scale-90 active:shadow-none"
        :disabled="previousButtonState"
        @click="previous"
        ><IconsPreviousArrowIcon
          :dropshadow="true"
          class="pointer-events-none h-full w-full"
          :fill-color="!previousButtonState ? '#0384FB' : '#333'"
        />
      </ButtonsSemanticButton>
      <ButtonsSemanticButton
        :text="''"
        :aria-label="'Next'"
        :classes="'rounded-full w-3 h-3 shadow-1'"
        class="active:scale-90 active:shadow-none"
        :disabled="nextButtonState"
        @click="next"
      >
        <IconsNextArrowIcon
          :dropshadow="true"
          class="pointer-events-none h-full w-full"
          :fill-color="!nextButtonState ? '#0384FB' : '#333'"
        />
      </ButtonsSemanticButton>
    </nav>
    <nav
      v-if="
        carousel.labelsType === 'Arrows' &&
        carousel.labelsPlacement === 'Bottom'
      "
      ref="nav"
      class="relative mt-space2 w-full"
    >
      <ul
        class="flex flex-col justify-evenly md:flex-row md:flex-wrap md:justify-between lg:flex-nowrap"
      >
        <li
          v-for="(slide, index) in carousel.slides"
          :key="'D' + index"
          :class="{ 'active ': activeIndex === index }"
          class="ds-nav-item h-3 font-bold uppercase hover:cursor-pointer hover:opacity-70 md:mr-1 md:h-auto md:w-[194px] md:pb-1 md:pt-0 lg:pb-0"
          @click="setActiveSlide(index)"
        >
          <span class="space-between flex h-full flex-row md:h-auto">
            <IconsArrowIcon
              class="mr-[12px] w-[24px] self-center md:mt-[7px] md:self-start"
            />
            <span
              class="self-center"
              :class="{
                'text-dark': activeIndex !== index,
                'text-blue underline': activeIndex === index,
              }"
              >{{ slide.fields.label ? slide.fields.label : '' }}</span
            ></span
          >
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      carousel: {
        type: Object,
        required: true,
      },
    },
    setup() {
      const activeIndex = ref(0);

      return {
        mediaLoaded: ref(false),
        sublabelWidth: 'auto',
        state: ref('pause'),
        navActive: ref(false),
        interval: ref(null),
        activeIndex,
        observer: ref(null),
        nextButtonDisabled: ref(false),
        previousButtonDisabled: ref(true),
      };
    },
    computed: {
      isMobile() {
        return !!(window.innerWidth <= 800 || this.$device.isMobile);
      },
      nextButtonState() {
        return !(
          this.activeIndex === 0 ||
          this.activeIndex < this.carousel.slides.length - 1
        );
      },
      previousButtonState() {
        return !(
          this.activeIndex > 0 ||
          this.activeIndex === this.carousel.slides.length
        );
      },
      processSlides() {
        return this.carousel.slides
          ? this.carousel.slides.filter(
              (slide) => slide.fields.isVisible !== false
            )
          : this.slides;
      },
      currentSlide() {
        return this.visibleSlides;
      },
    },
    watch: {
      mediaLoaded(newValue) {
        return newValue;
      },
      navActive() {
        if (this.navActive && this.carousel.animated === true) {
          this.clearIntervalMethod();
        }
      },
      activeIndex: {
        handler(newValue) {
          this.activeIndex = newValue;
        },
        immediate: true,
      },
    },
    mounted() {
      if (
        this.carousel.animated === true &&
        this.carousel.labelsType === 'Bars'
      ) {
        this.loop();
        if (this.isMobile) {
          this.createIntersectionObserver();
          this.runObserver();
        }
      }
    },
    methods: {
      createIntersectionObserver() {
        this.observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                this.activeIndex = Number(
                  entry.target.getAttribute('data-index')
                );
              }
            });
          },
          {
            threshold: 0.85,
          }
        );
      },
      runObserver() {
        if (document.querySelector('.ds-nav-menu--bars .ds-nav-item')) {
          document
            .querySelectorAll('.ds-nav-menu--bars .ds-nav-item')
            .forEach((navItem) => {
              this.observer.observe(navItem);
            });
        }
      },
      setVideoState(index) {
        return this.activeIndex === index ? 'play' : 'pause';
      },
      navInteracted() {
        this.navActive = true;
        if (this.carousel.animated === true) {
          this.clearIntervalMethod();
        }
      },
      clearIntervalMethod() {
        clearInterval(this.interval);
        this.interval = null;
        this.state = 'play';
      },
      previous() {
        if (
          this.activeIndex > 0 ||
          this.activeIndex === this.carousel.slides.length
        ) {
          this.activeIndex -= 1;
        }
        this.setActiveSlide(this.activeIndex);
      },
      next() {
        if (
          this.activeIndex === 0 ||
          this.activeIndex < this.carousel.slides.length
        ) {
          this.activeIndex += 1;
        }
        this.setActiveSlide(this.activeIndex);
      },
      loop() {
        if (this.interval === null && !this.navActive) {
          let count = 0;
          this.interval = setInterval(() => {
            count++;
            if (count >= this.processSlides.length) {
              this.clearIntervalMethod();
              count = 0;
              this.loop();
            }
            this.setActiveSlide(count);
          }, 10000);
        }
      },
      animate() {
        if (this.$refs.slidesTop && this.$refs.slidesTop[this.activeIndex]) {
          this.$refs.slidesTop[this.activeIndex].animate(
            [
              {
                transform: 'translateX(-3px)',
                opacity: '0',
              },

              {
                transform: 'translateX(0px)',
                opacity: '1',
              },
            ],
            {
              duration: 300,
            }
          );
        }
        if (
          this.$refs.slidesBottom &&
          this.$refs.slidesBottom[this.activeIndex]
        ) {
          this.$refs.slidesBottom[this.activeIndex].animate(
            [
              {
                transform: 'translateX(-3px)',
                opacity: '0',
              },

              {
                transform: 'translateX(0px)',
                opacity: '1',
              },
            ],
            {
              duration: 300,
            }
          );
        }
      },
      scrollToActiveSlide() {
        this.$refs.scrollNavBars.scrollTo({
          left:
            this.activeIndex === 0 ||
            this.$refs.scrollNavBars.children.length === this.activeIndex
              ? this.$refs.scrollNavBars.children[this.activeIndex].offsetLeft
              : this.$refs.scrollNavBars.children[this.activeIndex].offsetLeft -
                Math.round(
                  window.innerWidth -
                    this.$refs.scrollNavBars.children[this.activeIndex]
                      .offsetWidth
                ) /
                  2,
          behavior: 'smooth',
        });
      },
      setActiveSlide(index) {
        this.activeIndex = index;
        if (this.carousel.animated === true) {
          this.animate();
        }
        if (this.$refs.scrollNavBars) {
          if (this.isMobile) {
            this.scrollToActiveSlide(this.activeIndex);
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .ds-default-slider {
    :deep() {
      figure {
        figcaption,
        .figure-gradient {
          @apply hidden md:block;
        }
      }
    }

    &.ds-default-slider--one-column {
      :deep(.video__container),
      :deep(video) {
        @apply h-full w-full;
      }

      .ds-default-slider-nav--bars {
        @apply mb-0;
      }
    }

    .ds-nav-menu {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      .ds-nav-item {
        @apply transition-all duration-300 ease-out;
        &.active {
          @apply pointer-events-none;

          &:hover {
            @apply cursor-default;
          }
        }

        .indicator-animated {
          background: theme('colors.blue.DEFAULT');
        }
      }
      &::-webkit-scrollbar {
        @apply hidden;
      }
    }
    .indicator-animated {
      &.play {
        animation-name: progress;
        animation-duration: 10s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
      }
      &.pause {
        transform: scaleX(0);
      }

      &.active {
        transform: scaleX(1);
        animation: none;
      }
    }
  }

  @keyframes progress {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }
  @keyframes fadeinfromright {
    from {
      opacity: 0;
      transform: translateX(-3);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
</style>
