<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30" cy="30.5" r="30" :fill="fillColor" />
    <path
      d="M32.0691 20.1553L42.4139 30.5001L32.0691 40.8449"
      stroke="white"
      stroke-width="5"
    />
    <path d="M42.4143 30.5H14.4833" stroke="white" stroke-width="5" />
  </svg>
</template>
<script>
  export default {
    props: {
      fillColor: {
        type: String,
        required: false,
        default: '#0384FB',
      },
    },
  };
</script>
