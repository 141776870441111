import { defineStore } from 'pinia';

interface DevModeWidgetState {
  urlMap: Map<string, string>;
  showEntries: boolean;
}

export const useDevModeWidgetStore = defineStore<string, DevModeWidgetState>(
  'devModeWidget',
  {
    state: () => ({
      urlMap: new Map(),
      showEntries: false,
    }),
    actions: {
      saveEntryURL(url: string, path: string) {
        if (url && typeof url === 'string') {
          this.urlMap.set(path, url);
        }
      },
      setShowEntries() {
        this.showEntries = !this.showEntries;
      },
    },
    getters: {
      hasURL: (state) => (path: string) => state.urlMap.has(path),
      getURL: (state) => (path: string) => state.urlMap.get(path),
      getShowEntries: (state) => state.showEntries,
    },
  }
);
