import { samsaraHosts } from '~/constants/known-hosts';

interface GeoResponse {
  Country: string;
  isEU: boolean;
}

const EU_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'UK',
];

export async function getGeo() {
  const host = window.location.host;
  const defaultResponse = {
    code: 'US',
    isEU: false,
    isNorthAmerica: true,
  };

  if (samsaraHosts.includes(host)) {
    try {
      const { Country } = await $fetch<GeoResponse>(`https://${host}/geo`);

      const code = Country === 'GB' || Country === 'IE' ? 'UK' : Country;
      const isEU = EU_COUNTRIES.includes(code);
      const isNorthAmerica = ['US', 'CA'].includes(code);

      return {
        code,
        isEU,
        isNorthAmerica,
      };
    } catch (error) {
      // what should we do if geo fails?
      return defaultResponse;
    }
  } else {
    return defaultResponse;
  }
}
