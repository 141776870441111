import { ctflMappings } from '~/constants/locales';

export function generateAvailableLocales(locales = null) {
  let availableLocales = [];
  if (locales) {
    for (const [key, value] of Object.entries(locales)) {
      if (key === 'fr-CA') {
        if (locales['fr'] && value !== false) {
          availableLocales.push('fr-CA');
        } else if (value === true) {
          availableLocales.push(key);
        }
      } else if (key === 'en-CA') {
        if (locales['en-US'] && value !== false) {
          availableLocales.push('en-CA');
        } else if (value === true) {
          availableLocales.push(key);
        }
      } else if (value === true) {
        availableLocales.push(key);
      }
    }
  }
  return availableLocales;
}

export function generateHead(
  title,
  description,
  indexing = 'all',
  image,
  links,
  route = null
) {
  const usHrefLang =
    route !== null
      ? [
          {
            rel: 'alternate',
            hreflang: 'en-US',
            route: route,
            vmid: 'en-US',
          },
        ]
      : null;
  return {
    title: title,
    link:
      links && links.length > 0 ? links : usHrefLang !== null ? usHrefLang : '',
    meta: [
      {
        hid: 'og_title',
        property: 'og:title',
        content: title,
      },
      {
        hid: 'twitter_title',
        name: 'twitter:title',
        content: title,
      },
      {
        hid: 'twitter_image',
        property: 'twitter:image',
        content: image,
      },
      {
        hid: 'twitter:site',
        property: 'twitter:site',
        content: '@Samsara',
      },
      {
        hid: 'twitter:card',
        property: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        hid: 'description',
        name: 'description',
        content: description,
      },
      {
        hid: 'og_description',
        property: 'og:description',
        content: description,
      },
      {
        hid: 'robots',
        name: 'robots',
        content: indexing,
      },
      {
        hid: 'og_image',
        property: 'og:image',
        content: image,
      },
    ],
  };
}

export function generateHreflangLinks(
  availableLocales,
  currentLocale,
  baseUrl,
  fullPath
) {
  const links = [];
  if (Array.isArray(availableLocales) && availableLocales.length > 0) {
    const buildRoute = (locale) => {
      let route;
      if (currentLocale === 'en-US') {
        route =
          locale !== 'en-US' ? '/' + ctflMappings[locale] + fullPath : fullPath;
      } else if (locale === 'en-US') {
        route = fullPath.replace(`${currentLocale}/`, '');
      } else {
        route = fullPath.replace(currentLocale, ctflMappings[locale]);
      }
      return route;
    };
    availableLocales.forEach((locale) => {
      if (Object.prototype.hasOwnProperty.call(ctflMappings, locale)) {
        const vmID = locale === 'en-US' ? { vmid: 'en-US' } : '';
        links.push({
          rel: 'alternate',
          hreflang: ctflMappings[locale] === 'mx' ? 'es' : locale,
          href: baseUrl + buildRoute(locale),
          ...vmID,
        });
      }
    });
  }
  return links;
}
