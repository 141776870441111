<template>
  <div class="h-[25px] w-[25px]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect x="11" y="16" width="2" height="5" rx="1" fill="#1F78F4" />
      <rect
        x="9.13403"
        y="14.9641"
        width="2"
        height="5"
        rx="1"
        transform="rotate(30 9.13403 14.9641)"
        fill="#1F78F4"
      />
      <rect
        x="8.03589"
        y="13.134"
        width="2"
        height="5"
        rx="1"
        transform="rotate(60 8.03589 13.134)"
        fill="#1F78F4"
      />
      <rect
        x="8"
        y="11"
        width="2"
        height="5"
        rx="1"
        transform="rotate(90 8 11)"
        fill="#1F78F4"
      />
      <rect
        x="9.03589"
        y="9.13397"
        width="2"
        height="5"
        rx="1"
        transform="rotate(120 9.03589 9.13397)"
        fill="#1F78F4"
      />
      <rect
        x="6.63403"
        y="4.70578"
        width="2"
        height="5"
        rx="1"
        transform="rotate(-30 6.63403 4.70578)"
        fill="#1F78F4"
      />
      <rect x="11" y="3" width="2" height="5" rx="1" fill="#1F78F4" />
      <rect
        x="15.634"
        y="3.70578"
        width="2"
        height="5"
        rx="1"
        transform="rotate(30 15.634 3.70578)"
        fill="#1F78F4"
      />
      <rect
        x="19.2942"
        y="6.63397"
        width="2"
        height="5"
        rx="1"
        transform="rotate(60 19.2942 6.63397)"
        fill="#1F78F4"
      />
      <rect
        x="21"
        y="11"
        width="2"
        height="5"
        rx="1"
        transform="rotate(90 21 11)"
        fill="#1F78F4"
      />
      <rect
        x="20.2942"
        y="15.634"
        width="2"
        height="5"
        rx="1"
        transform="rotate(120 20.2942 15.634)"
        fill="#1F78F4"
      />
      <rect
        xmlns="http://www.w3.org/2000/svg"
        x="13.134"
        y="15.9641"
        width="2"
        height="5"
        rx="1"
        transform="rotate(-30 13.134 15.9641)"
        fill="#1F78F4"
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
  svg {
    rect {
      animation: lds-spinner 1.2s linear infinite;
      &:nth-child(1) {
        animation-delay: -1.1s;
      }
      &:nth-child(2) {
        animation-delay: -1s;
      }
      &:nth-child(3) {
        animation-delay: -0.9s;
      }
      &:nth-child(4) {
        animation-delay: -0.7s;
      }
      &:nth-child(5) {
        animation-delay: -0.6s;
      }
      &:nth-child(6) {
        animation-delay: -0.5s;
      }
      &:nth-child(7) {
        animation-delay: -0.4s;
      }
      &:nth-child(8) {
        animation-delay: -0.3s;
      }
      &:nth-child(9) {
        animation-delay: -0.2s;
      }
      &:nth-child(10) {
        animation-delay: -0.1s;
      }
      &:nth-child(11) {
        animation-delay: 0s;
      }
    }
  }

  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
</style>
