export function useCreateMarketo() {
  onMounted(() => {
    if (process.client) {
      const marketoScript = document.createElement('script');
      marketoScript.type = 'text/javascript';
      marketoScript.src = '//app-sj30.marketo.com/js/forms2/js/forms2.min.js';
      document.getElementsByTagName('head')[0].appendChild(marketoScript);
    }
  });
}
