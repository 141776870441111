<template>
  <div
    v-if="(isSandbox || isStaging) && hasURL(route.fullPath)"
    id="dev-mode-widget"
    class="font-mono fixed bottom-1 left-1 z-[9999] transition-all duration-300 ease-in-out"
    :class="[
      {
        'border-sol h-auto rounded-md border-1 border-solid border-gray-300 bg-transparent bg-white px-1 py-2 shadow-md':
          !closed,
        'h-[35px] w-[35px] rounded-r-md border-transparent': closed,
      },
    ]"
  >
    <button
      class="dmw--button absolute rounded-full bg-white transition-all hover:scale-105 active:scale-95"
      :class="{
        'open right-[-10px] top-[-10px] h-[30px] w-[30px]': !closed,
        'closed right-[3px] top-[3px] h-[35px] w-[35px]': closed,
      }"
      @click="toggle"
    >
      <div
        class="absolute left-[50%] top-[50%] w-full translate-x-[-50%] translate-y-[-50%] p-[5px]"
      >
        <IconsSvgGear fill="#0384FB" class="dmw--button-gear h-full w-full" />
        <IconsSvgCloseIcon
          stroke="#0384FB"
          class="dmw--button-close h-full w-full p-[3px]"
        />
      </div>
    </button>
    <div class="w-full" :class="{ hidden: closed }">
      <NuxtLink
        class="block w-fit overflow-hidden text-ellipsis border-2 border-black p-1/2 text-left text-xs transition-all hover:bg-gray-lighter md:text-base"
        :to="getURL(route.fullPath)"
        target="_blank"
        external
      >
        <span class="inline font-bold">
          View On: <LogosSvgContentful class="inline" />
          <span class="underline">{{ getURL(route.fullPath).slice(-22) }}</span>
        </span>
      </NuxtLink>
      <div class="justify-left flex flex-row content-center items-center pt-1">
        <span class="inline pr-1/2 text-xxs font-bold uppercase"
          >show entries:
        </span>
        <button
          class="show-inner border-w-1 relative inline h-[25px] w-[50px] rounded-full border-2 border-solid border-gray-dark text-white transition-all"
          :class="{
            'bg-white': getShowEntries,
            'bg-gray-200': !getShowEntries,
          }"
          @click="widgetStore.setShowEntries"
        >
          <div
            class="transition-duration-[1s] shadow-[0 2px 5px rgba(0, 0, 0, 0.2)] absolute top-[50%] h-[98%] w-[25px] translate-y-[-50%] rounded-full border-2 border-solid border-white transition-all ease-in-out"
            :class="{
              'translate-x-[20px] bg-green': getShowEntries,
              'translate-x-0 bg-blue-dark': !getShowEntries,
            }"
          ></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
  const { isSandbox, isStaging } = useRuntimeConfig().public;
  const route = useRoute();

  const widgetStore = useDevModeWidgetStore();
  const { getURL, hasURL, getShowEntries } = storeToRefs(widgetStore);

  const closed = ref(true);

  const toggle = () => {
    closed.value = !closed.value;
  };
</script>

<style lang="scss" scoped>
  .dmw--button {
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
    &.open {
      .dmw--button-gear {
        @apply hidden;
      }
      .dmw--button-close {
        @apply block;
      }
    }
    &.closed {
      .dmw--button-close {
        @apply hidden;
      }
      .dmw--button-gear {
        @apply block;
      }
    }
  }
</style>
