<template>
  <div class="carousel carousel--stories mb-3 pt-1">
    <Swiper
      v-if="carousel.slides"
      ref="successSwiper"
      v-bind="successOptions"
      :modules="[SwiperNavigation]"
    >
      <SwiperSlide v-for="(slide, index) in carousel.slides" :key="index">
        <div class="carousel__slide">
          <LinksLocaleLink
            v-if="slide.fields.link"
            :link="slide.fields.link"
            class="wrapper--card relative block overflow-hidden shadow-md"
          >
            <ImagesPicture
              :image="slide.fields.image.fields"
              classes="object-cover"
              :lazy="true"
            />
            <div class="wrapper--content absolute bg-white p-1">
              <RichTextRenderer
                v-if="slide.fields.content"
                :document="slide.fields.content"
                :node-renderers="nodeRenderers"
              />
            </div>
          </LinksLocaleLink>
        </div>
      </SwiperSlide>
      <div slot="button-prev" class="prev--arrow-teal">
        <svg
          id="Layer_1"
          version="1.1"
          baseProfile="tiny"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="50px"
          height="68.6px"
          viewBox="0 0 50 68.6"
          overflow="scroll"
          xml:space="preserve"
        >
          <polygon
            fill="#FFFFFF"
            points="16.6,17.8 32.3,34.1 16.6,50.5 17.4,51.2 33.7,34.1 17.4,17.1 "
          />
        </svg>
      </div>
      <div slot="button-next" class="next--arrow-teal">
        <svg
          id="Layer_1"
          version="1.1"
          baseProfile="tiny"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="50px"
          height="68.6px"
          viewBox="0 0 50 68.6"
          overflow="scroll"
          xml:space="preserve"
        >
          <polygon
            fill="#FFFFFF"
            points="16.6,17.8 32.3,34.1 16.6,50.5 17.4,51.2 33.7,34.1 17.4,17.1 "
          />
        </svg>
      </div>
    </Swiper>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },

    props: {
      carousel: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        document: this.carousel.content,
        successOptions: {
          loop: true,
          navigation: {
            nextEl: '.next--arrow-teal',
            prevEl: '.prev--arrow-teal',
          },
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 'auto',
          allowTouchMove: true,
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .swiper-container {
    @apply pb-1;

    &::before,
    &::after {
      content: '';
      background: linear-gradient(
        90deg,
        #fff 0,
        hsla(0, 0%, 100%, 0.81) 10%,
        hsla(0, 0%, 100%, 0.57) 26%,
        hsla(0, 0%, 100%, 0.54) 27%,
        hsla(0, 0%, 100%, 0.4) 39%,
        hsla(0, 0%, 100%, 0.35) 42%,
        hsla(0, 0%, 100%, 0.29) 49%,
        hsla(0, 0%, 100%, 0.27) 50%,
        hsla(0, 0%, 100%, 0.14) 64%,
        hsla(0, 0%, 100%, 0.08) 74%,
        hsla(0, 0%, 100%, 0.02) 90%,
        hsla(0, 0%, 100%, 0)
      );
      top: 0;
      left: 0;
      position: absolute;
      height: 100%;
      width: 185px;
      z-index: 2;
    }

    &::after {
      right: 0;
      left: auto;
      transform: scaleX(-1);
    }
  }

  .next--arrow-teal,
  .prev--arrow-teal {
    @apply right-0 z-10 overflow-hidden bg-teal;

    height: 68px;
    width: 50px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    transition: all 0.3s ease-in;

    @media (max-width: theme('screens.md')) {
      display: none;
    }
  }

  .prev--arrow-teal {
    @apply left-0 right-auto;

    svg {
      transform: scaleX(-1);
    }
  }

  .swiper-slide {
    @apply mr-1;

    width: 255px;
  }

  .wrapper--card {
    padding-bottom: 87px;

    .wrapper--content {
      transition: top 0.3s ease-in-out;
      top: calc(100% - 90px);

      h5 {
        @apply mb-0 text-xs tracking-wide;
      }

      p {
        font-size: 12px;
      }

      h3 {
        font-size: 22px;
        line-height: 1.4;
      }

      .p--xs-eyebrow {
        @apply text-blue;
      }
    }

    &:hover {
      .wrapper--content {
        top: 0;
      }
    }
  }
</style>
