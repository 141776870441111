export default defineNuxtPlugin((nuxtApp) => {
  const mixpanelCookie = useCookie(
    'mp_81f04dbeb4db7ff80420f5b348506aa7_mixpanel'
  );

  nuxtApp.hook('app:suspense:resolve', () => {
    window.analytics = window.analytics || {};
    if (analytics.initialized) {
      if (mixpanelCookie.value) {
        const loadedMixpanelCookie = mixpanelCookie.value;
        const mixpanelDistinctId = loadedMixpanelCookie.distinct_id;
        if (mixpanelDistinctId) {
          analytics.setAnonymousId(mixpanelDistinctId);
        }
      }
    }
  });
});
