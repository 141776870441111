<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    class="spci-svg-plus-circle-icon transition-all ease-out"
    :class="active ? 'rotate-45 ease-out' : ''"
    :fill="active ? '#0384FB' : 'none'"
  >
    <circle
      cx="17.8188"
      cy="18"
      r="17"
      :stroke="active ? '#fff' : strokeColor"
    />
    <line
      x1="7.98776"
      y1="18"
      x2="27.6498"
      y2="18"
      :stroke="active ? '#fff' : strokeColor"
    />
    <line
      x1="17.9878"
      y1="8.2775"
      x2="17.9878"
      y2="27.7225"
      :stroke="active ? '#fff' : strokeColor"
    />
  </svg>
</template>
<script>
  export default {
    props: {
      strokeColor: {
        type: String,
        required: false,
        default: '#0384FB',
      },
      active: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  };
</script>
