<template>
  <span v-if="customText.format === 'Un-Styled'" ref="contentRef"></span>
  <MarkdownTheMarkdown
    v-else-if="customText.format === 'Subhead' && content"
    class="ct-custom-text p--subhead"
    :source="content"
  />
  <p v-else-if="content" :class="textClasses" v-html="content"></p>
</template>

<script setup>
  const props = defineProps({
    customText: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  });

  const contentRef = ref(null);
  const content = ref(null);

  const renderFragment = () => {
    content.value = props.customText.content;
    const fragment = document
      .createRange()
      .createContextualFragment(props.customText.content);
    if (fragment && contentRef.value) {
      contentRef.value.replaceChildren(fragment);
    } else if (contentRef.value) {
      contentRef.value.appendChild(props.customText.content);
    }
  };

  watch(
    () => props.customText,
    () => {
      renderFragment();
    }
  );

  const textClasses = computed(() => {
    const styles = {
      Subhead: 'p--subhead',
      Eyebrow: 'p--xs-eyebrow text-base tracking-wide font-bold text-blue',
      'Paragraph Large': 'p--large',
      'Fine Print': 'p--fine-print',
      'Stat Figure': 'p--stat-figure',
      'Stat Text': 'p--stat-text',
      'Giant Stat': 'p--giant-stat',
      'Arrows List': 'ul--arrows-list',
    };
    return styles[props.customText.format];
  });
  onMounted(() => {
    if (props.customText.content) {
      renderFragment();
    }
  });
</script>
