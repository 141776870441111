<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
  >
    <path
      class="ai--path-body transition-all"
      d="M9 6L1 6"
      :stroke="stroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      class="ai--path-arrow transition-all"
      d="M6 1.5L11 6L6 10.5"
      :stroke="stroke"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
<script>
  export default {
    props: {
      stroke: {
        type: String,
        required: false,
        default: '#0384FB',
      },
    },
  };
</script>
