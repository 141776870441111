<template>
  <header
    ref="customHeader"
    class="cn-custom-navigation-header z-10 h-4 w-full bg-white"
    :class="{
      active: mobileMenu,
      inactive: !mobileMenu,
    }"
  >
    <BannersAnnouncementBanner
      v-if="fields?.announcementBanner && !announceHeaderClosed"
      ref="announcementBanner"
      :sticky="false"
      :text="fields?.announcementBannerText || ''"
      :link="fields?.announcementBannerLink || '/'"
      :link-text="fields?.announcementBannerLinkText || ''"
      @bannerExists="bannerExists"
    />
    <nav
      ref="desktopNav"
      :class="`${positionState} ${scrollUpState} transition-all duration-100`"
      class="cn-desktop-nav z-10 h-4 w-full flex-wrap justify-between bg-white px-[2%]"
    >
      <div class="flex justify-center">
        <span
          v-if="fields.logo !== undefined"
          class="relative flex h-auto w-[175px] justify-center"
        >
          <ImagesNuxtImg
            v-if="fields.logo.fields.file !== undefined"
            :url="fields.logo.fields.file.url"
            :lazy="true"
            :alt="
              fields.logo.fields.description !== undefined
                ? fields.logo.fields.description
                : ''
            "
            class="relative block w-full object-contain"
          />
        </span>
        <LinksLocaleLink
          v-if="fields.logo === undefined"
          link="/"
          title="samsara"
          class="flex max-w-[150px] self-center pr-1/2"
          ><LogosSamsaraLogo class="w-full"
        /></LinksLocaleLink>
        <LinksLocaleLink
          v-if="fields?.titleLink"
          :link="fields?.titleLink || '/'"
          class="self-center"
        >
          <p
            class="mb-0 border-l-1 border-gray-lightest pb-0 pl-1/2 text-[26px] text-gray-dark hover:text-blue"
          >
            {{ fields?.title || '' }}
          </p></LinksLocaleLink
        >
        <p
          v-else-if="fields?.title"
          class="mb-0 flex self-center border-l-1 border-gray-lightest pb-0 pl-1/2 text-[26px] text-gray-dark"
        >
          {{ fields?.title || '' }}
        </p>
      </div>
      <ul
        v-if="list.length > 0"
        class="m-0 flex h-full flex-row justify-center p-0"
      >
        <li
          v-for="(item, index) in list"
          :key="index"
          class="flex h-full items-center px-1"
        >
          <LinksLocaleLink
            :link="item?.fields?.path || '/'"
            class="mb-0"
            :class="{
              'px-0 py-1 font-medium text-blue-dark hover:text-blue md:px-0 md:py-0':
                item.fields.style === 'Minimal Navy Dark',
              'btn btn--blue': item.fields.style === 'Button Primary',
            }"
            >{{ item?.fields?.text || 'nav item' }}</LinksLocaleLink
          >
        </li>
      </ul>
      <span v-if="fields?.button" class="flex h-full flex-row justify-center">
        <span class="flex h-full items-center px-1 [&>a]:mb-0">
          <ButtonsTheButton :button="fields?.button?.fields" />
        </span>
      </span>
    </nav>
    <nav
      :class="`${positionState} ${scrollUpState} transition-all duration-100`"
      class="cn-mobile-nav z-10 h-4 w-full flex-wrap justify-between bg-white px-[2%]"
    >
      <div class="flex justify-center">
        <span
          v-if="fields.logo !== undefined"
          class="relative flex h-auto w-[175px] justify-center"
        >
          <ImagesNuxtImg
            v-if="fields.logo.fields.file !== undefined"
            :url="fields.logo.fields.file.url"
            :lazy="true"
            :alt="
              fields.logo.fields.description !== undefined
                ? fields.logo.fields.description
                : ''
            "
            class="relative block w-full object-contain"
          />
        </span>
        <LinksLocaleLink
          v-if="fields.logo === undefined"
          link="/"
          class="flex max-w-[150px] self-center pr-1/2"
        >
          <LogosSamsaraLogo class="w-full"
        /></LinksLocaleLink>
        <LinksLocaleLink
          v-if="fields?.titleLink"
          :link="fields?.titleLink || '/'"
          class="self-center"
        >
          <p
            class="mb-0 border-l-1 border-gray-lightest pb-0 pl-1/2 text-[26px] text-gray-dark hover:text-blue"
          >
            {{ fields?.title || '' }}
          </p></LinksLocaleLink
        >
        <p
          v-else-if="fields?.title"
          class="mb-0 flex self-center border-l-1 border-gray-lightest pb-0 pl-1/2 text-[26px] text-gray-dark"
        >
          {{ fields?.title || '' }}
        </p>
      </div>
      <div
        v-if="list.length > 0"
        class="cn-mobile-menu-icon flex h-full flex-row justify-center"
        @click="toggleMobileMenu()"
      >
        <div
          class="flex w-[24px] flex-col items-center justify-evenly self-center"
        >
          <span
            class="mb-[6px] block h-[2px] w-full bg-blue-dark duration-100"
          ></span>
          <span
            class="mb-[6px] block h-[2px] w-full bg-blue-dark duration-100"
          ></span>
          <span
            class="mb-[6px] block h-[2px] w-full bg-blue-dark duration-100"
          ></span>
        </div>
      </div>
    </nav>
    <nav
      ref="mobileMenu"
      class="cn-mobile-menu relative top-0 block h-auto w-full bg-white px-[2%]"
    >
      <ul class="flex h-full flex-row justify-center bg-white">
        <li
          v-for="(item, index) in list"
          :key="index"
          class="flex h-full items-center border-b-1 border-gray-light px-1 py-1/2"
        >
          <LinksLocaleLink
            :link="item?.fields?.path || '/'"
            class="mb-0"
            :class="{
              'size-[24px] h-full px-0 font-medium text-blue-dark':
                item.fields.style === 'Minimal Navy Dark',
              'btn btn--blue': item.fields.style === 'Button Primary',
            }"
            >{{ item?.fields?.text || 'nav item' }}</LinksLocaleLink
          >
        </li>
        <li v-if="fields.button" class="flex h-full items-center px-0 py-1/2">
          <ButtonsTheButton :button="fields?.button?.fields" />
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
  export default {
    props: {
      fields: {
        type: Object,
        required: false,
        default() {
          return {
            internalName: 'Custom Navigation',
            navigationItems: [
              {
                fields: {
                  internalName: 'Navigation List',
                  limitVisibleItems: false,
                  listItems: [
                    {
                      fields: {
                        internalName: 'Navigation Link',
                        path: '/',
                        style: 'Minimal Navy Dark',
                        text: 'Nav Item',
                      },
                    },
                    {
                      fields: {
                        internalName: 'Navigation Link',
                        path: '/',
                        style: 'Minimal Navy Dark',
                        text: 'Nav Item',
                      },
                    },
                    {
                      fields: {
                        internalName: 'Navigation Link',
                        path: '/',
                        style: 'Minimal Navy Dark',
                        text: 'Nav Item',
                      },
                    },
                    {
                      fields: {
                        internalName: 'Navigation Link',
                        path: '/',
                        style: 'Minimal Navy Dark',
                        text: 'Nav Item',
                      },
                    },
                    {
                      fields: {
                        internalName: 'Navigation Link',
                        path: '/',
                        style: 'Minimal Navy Dark',
                        text: 'Nav Item',
                      },
                    },
                  ],
                },
              },
            ],
          };
        },
      },
      list: {
        type: Array,
        required: true,
      },
      persistent: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    setup() {
      const { analyticsTrack } = useAnalytics();
      const announceHeaderClosed = useCookie('announceHeaderClosed', {
        path: '/',
        expires: 0 * 60 * 24 * 7, // 1 week
      });

      const localePath = useLocalePath();

      return {
        announceHeaderClosed,
        analyticsTrack,
        localePath,
      };
    },
    data() {
      return {
        mobileMenu: false,
        positionState: 'relative',
        announcementBannerHeight: 0,
        previousScrollPosition: 0,
        scrollUpState: '',
      };
    },
    computed: {
      wrapperClasses() {
        return {
          fixed: this.fields.sticky,
          relative: !this.fields.sticky || this.fields.sticky === undefined,
        };
      },
    },
    watch: {
      positionState() {
        this.$nextTick(() => {
          if (this.$refs.mobileMenu && this.$refs.customHeader) {
            if (this.positionState === 'fixed top-0') {
              this.$refs.mobileMenu.style.marginTop = `${this.$refs.customHeader.offsetHeight}px`;
              this.$refs.mobileMenu.style.position = 'fixed';
            } else {
              this.$refs.mobileMenu.style.marginTop = '0px';
              this.$refs.mobileMenu.style.position = 'relative';
            }
          }
        });
      },
    },
    beforeMount() {
      window.addEventListener('resize', this.onWindowResize);
      window.addEventListener('scroll', this.onWindowScroll);
    },
    unmounted() {
      window.removeEventListener('resize', this.onWindowResize);
      window.removeEventListener('scroll', this.onWindowScroll);
    },
    mounted() {
      this.onWindowResize();
      this.previousScrollPosition = window.scrollY;
      this.onWindowScroll();
    },
    methods: {
      bannerExists() {
        this.announceHeaderClosed = true;
      },
      onWindowResize() {
        if (this.$refs.announcementBanner) {
          this.announcementBannerHeight =
            this.$refs.announcementBanner.$el.offsetHeight;
        }
      },
      setPositionState() {
        const DEFAULT_CUSTOM_NAV_HEIGHT = 80;

        const currentScrollPosition = window.scrollY;

        if (
          this.fields.announcementBanner &&
          !this.announceHeaderClosed &&
          this.$refs.customHeader
        ) {
          if (currentScrollPosition >= this.announcementBannerHeight) {
            this.positionState = 'fixed top-0';
            this.$emitter.emit(
              'custom-nav-height',
              this.$refs?.customHeader?.offsetHeight ??
                DEFAULT_CUSTOM_NAV_HEIGHT
            );
          } else {
            this.positionState = 'relative';
            this.$emitter.emit(
              'custom-nav-height',
              this.$refs?.customHeader?.offsetHeight ??
                DEFAULT_CUSTOM_NAV_HEIGHT
            );
          }
        } else if (this.fields?.sticky) {
          this.positionState = 'fixed top-0';
          this.$emitter.emit(
            'custom-nav-height',
            this.$refs?.customHeader?.offsetHeight ?? DEFAULT_CUSTOM_NAV_HEIGHT
          );
        } else {
          this.positionState = 'relative';
          this.$emitter.emit(
            'custom-nav-height',
            this.$refs?.customHeader?.offsetHeight ?? DEFAULT_CUSTOM_NAV_HEIGHT
          );
        }
      },
      presentOnScrollUp() {
        const DEFAULT_CUSTOM_NAV_HEIGHT = 80;
        const currentScrollPosition = window.scrollY;
        if (
          this.previousScrollPosition > currentScrollPosition &&
          currentScrollPosition >=
            this.announcementBannerHeight + DEFAULT_CUSTOM_NAV_HEIGHT
        ) {
          this.scrollUpState = 'up--active position--scroll-up';
        } else if (
          currentScrollPosition !== 0 &&
          this.previousScrollPosition < currentScrollPosition &&
          currentScrollPosition >=
            this.announcementBannerHeight + DEFAULT_CUSTOM_NAV_HEIGHT
        ) {
          this.scrollUpState = 'position--scroll-up';
        }
        this.previousScrollPosition = currentScrollPosition;
      },
      onWindowScroll() {
        this.setPositionState();
        if (!this.persistent) {
          this.presentOnScrollUp();
        }
      },
      trackRolesLink(link, text) {
        this.analyticsTrack('Careers Nav Roles CTA Clicked', {
          link,
          text,
        });
      },
      toggleMobileMenu() {
        this.mobileMenu = !this.mobileMenu;
        if (this.mobileMenu) {
          document.querySelector('html').classList.add('overflow-y-hidden');
        } else {
          document.querySelector('html').classList.remove('overflow-y-hidden');
        }
      },
    },
  };
</script>
<style scoped lang="scss">
  .active {
    .cn-mobile-menu {
      ul {
        @apply block h-screen;
      }
      li {
        @apply h-auto;
      }
    }
    .cn-mobile-menu-icon {
      span:nth-child(1) {
        @apply translate-x-[4px] translate-y-[8px] rotate-45;
      }

      span:nth-child(2) {
        @apply opacity-0;
      }

      span:nth-child(3) {
        @apply translate-x-[4px] translate-y-[-8px] -rotate-45;
      }
    }
  }

  .cn-desktop-nav {
    @apply hidden;
    @media (min-width: 1280px) {
      @apply flex;
    }
  }

  .cn-mobile-nav {
    @apply flex;
    @media (min-width: 1280px) {
      @apply hidden;
    }
  }

  .inactive {
    .cn-mobile-menu {
      @apply hidden;
    }
  }

  .position--scroll-up {
    top: -80px;

    &.up--active {
      top: 0;
    }
  }
</style>
