<template>
  <div
    class="tt-tooltip-container size-[12px] absolute bottom-[-50px] w-fit h-auto left-1 rounded-[16px] bg-white p-1/4 pl-1/2 pr-1/2"
    :class="
      visible ? 'opacity-100 translate-y-[-3px]' : 'opacity-0 translate-y-0'
    "
  >
    {{ text }}
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        required: true,
        default: 'This is a tooltip!',
      },
      visible: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
    watch: {
      visible(newValue, old) {
        return newValue;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .tt-tooltip-container {
    box-shadow: 0 0 9px rgb(0 0 0 / 13%);
    transition: all 0.3s ease-out;
    &.active {
      transform: translateY(-3px);
      opacity: 1;
    }
    &.inactive {
      transform: translateY(0);
      opacity: 0;
    }
  }
</style>
