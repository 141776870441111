<template>
  <div class="carousel carousel--content w-full px-gap2 lg:container">
    <div class="-mx-gap mb-5 mt-5 flex flex-wrap justify-center">
      <div class="column relative w-11/12 shadow-lg md:w-11/12">
        <div class="relative flex h-full flex-wrap justify-start">
          <div
            class="column carousel__slide--image hidden h-full w-12/12 md:block md:w-5/12"
          >
            <Swiper
              v-if="carousel.slides"
              v-bind="imageOptions"
              class="h-full"
              :modules="[SwiperNavigation, Controller]"
              @swiper="onImageSwiper"
            >
              <SwiperSlide
                v-for="(slide, index) in carousel.slides"
                :key="index"
              >
                <div
                  v-if="slide.fields.image.fields"
                  class="carousel__slide h-full"
                >
                  <ImagesPicture
                    :image="
                      getNestedProperty(slide, 'fields.image.fields', false)
                    "
                    classes="w-full block h-full block object-cover"
                    :lazy="true"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div class="column carousel__slide w-12/12 md:w-7/12">
            <Swiper
              v-if="carousel.slides"
              v-bind="contentOptions"
              :modules="[SwiperNavigation, Controller]"
              @swiper="onContentSwiper"
            >
              <SwiperSlide
                v-for="(slide, index) in carousel.slides"
                :key="index"
              >
                <div class="carousel__slide mb-2 pb-2 sm:mb-0 md:px-3 md:py-2">
                  <div
                    class="image__wrap mb-1 block overflow-hidden md:mb-0 md:hidden"
                  >
                    <ImagesPicture
                      :image="
                        getNestedProperty(slide, 'fields.image.fields', false)
                      "
                      classes="object-cover"
                      :lazy="true"
                    />
                  </div>
                  <div
                    v-if="slide.fields.content"
                    class="content__wrap px-1 md:px-0"
                  >
                    <RichTextRenderer
                      :document="
                        getNestedProperty(slide, 'fields.content', false)
                      "
                      :node-renderers="nodeRenderers"
                    />
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div
          v-if="carousel.slides.length > 1"
          class="column carousel__slide--next absolute top-0 hidden h-full w-12/12 opacity-25 md:block md:w-5/12"
        >
          <Swiper
            v-bind="nextOptions"
            class="h-full"
            :modules="[SwiperNavigation, Controller]"
            @swiper="onNextSwiper"
          >
            <SwiperSlide v-for="(slide, index) in carousel.slides" :key="index">
              <div class="carousel__slide h-full">
                <ImagesPicture
                  :image="
                    getNestedProperty(slide, 'fields.image.fields', false)
                  "
                  classes="w-full block h-full block object-cover"
                  :lazy="false"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <ClientOnly>
          <div
            v-if="carousel.slides.length > 1"
            class="carousel__nav mt-1 hidden justify-end md:flex"
          >
            <div class="prev--content" @click="prev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="92"
                height="27"
              >
                <defs><path d="M0 0h92v27H0z"></path></defs>
                <clipPath>
                  <use xlink:href="#a" overflow="visible"></use>
                </clipPath>
                <path
                  clip-path="url(#b)"
                  fill="none"
                  stroke-miterlimit="10"
                  d="M79 1.1l12 12.5L79 26M90.7 13.5H1.5"
                ></path>
              </svg>
            </div>
            <div class="next--content ml-1" @click="next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="92"
                height="27"
              >
                <defs><path d="M0 0h92v27H0z"></path></defs>
                <clipPath>
                  <use xlink:href="#a" overflow="visible"></use>
                </clipPath>
                <path
                  clip-path="url(#b)"
                  fill="none"
                  stroke-miterlimit="10"
                  d="M79 1.1l12 12.5L79 26M90.7 13.5H1.5"
                ></path>
              </svg>
            </div>
          </div>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { Controller } from 'swiper/modules';
  import { getNestedProperty } from '~/utils';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      carousel: {
        type: Object,
        required: true,
      },
    },
    setup() {
      const imageSwiperRef = ref(null);
      const contentSwiperRef = ref(null);
      const nextSwiperRef = ref(null);

      const onImageSwiper = (swiper) => {
        imageSwiperRef.value = swiper;
      };
      const onContentSwiper = (swiper) => {
        contentSwiperRef.value = swiper;
      };
      const onNextSwiper = (swiper) => {
        nextSwiperRef.value = swiper;
      };

      return {
        Controller,
        imageSwiperRef,
        contentSwiperRef,
        nextSwiperRef,
        onImageSwiper,
        onContentSwiper,
        onNextSwiper,
      };
    },
    data() {
      return {
        document: this.carousel.content,
        imageOptions: {
          loop: true,
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 1,
          allowTouchMove: false,
        },
        contentOptions: {
          loop: true,
          navigation: {
            nextEl: '.next--content',
            prevEl: '.prev--content',
          },
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 1,
          allowTouchMove: true,
          breakpoints: {
            736: {
              allowTouchMove: false,
            },
          },
        },
        nextOptions: {
          loop: true,
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 1,
          allowTouchMove: false,
        },
      };
    },
    mounted() {
      this.$nextTick(() => {
        this.contentSwiperRef.controller.control = this.imageSwiperRef;

        if (this.nextSwiperRef && this.carousel.slides.length > 1) {
          this.nextSwiperRef.slideTo(1);
        }
      });

      this.contentSwiperRef.update();

      // In some cases nextSwiperRef is not defined because there is only one slide
      if (this.nextSwiperRef) {
        this.nextSwiperRef.update();
      }
    },
    methods: {
      prev() {
        this.contentSwiperRef.slidePrev();
        this.nextSwiperRef.slidePrev();
      },
      next() {
        this.contentSwiperRef.slideNext();
        this.nextSwiperRef.slideNext();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .image__wrap {
    min-height: 300px;
  }
  @media (max-width: theme('screens.md')) {
    :deep(img) {
      @apply w-full;
    }
  }
  .carousel__slide--next {
    left: 100%;
  }
  .swiper-slide {
    height: auto;
  }
  .prev--content,
  .next--content {
    @apply transition-all duration-100 ease-in-out;

    cursor: pointer;

    user-select: none;
    stroke: theme('colors.gray.dark');

    &:hover {
      stroke: theme('colors.blue.DEFAULT');
    }

    &:focus {
      outline: none;
    }
  }
  .prev--content {
    transform: scaleX(-1);
  }
</style>
