import { v4 as uuidv4 } from 'uuid';

/**
 * @deprecated This function will be removed in future versions.
 * Please use the built-in useId composable
 */
export const generateUUID = () => {
  const uuid = uuidv4();
  return uuid;
};

export const fadeIn = (el, display) => {
  el.style.opacity = 0;
  el.style.display = display || 'block';

  (function fade() {
    let opacityVal = parseFloat(el.style.opacity);
    if (!((opacityVal += 0.1) > 1)) {
      el.style.opacity = opacityVal;
      requestAnimationFrame(fade);
    }
  })();
};

export const fadeOut = (el) => {
  el.style.opacity = 1;

  (function fade() {
    if ((el.style.opacity -= 0.1) < 0) {
      el.style.display = 'none';
    } else {
      requestAnimationFrame(fade);
    }
  })();
};
