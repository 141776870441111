<template>
  <footer
    v-if="!loading && footer"
    class="relative bg-blue-dark px-0 pb-4 pt-0"
  >
    <nav class="small-text container">
      <LinksLocaleLink
        :link="'/'"
        title="Samsara Homepage"
        class="hidden md:inline-block"
      >
        <LogosSvgLogoMain :fill="'white'" class="max-w-[144px]" />
      </LinksLocaleLink>
      <div
        class="mb-1 flex flex-wrap justify-between border-t-1 border-[#4C5E64] py-2 md:mb-0 md:mt-1 md:py-0"
      >
        <ul
          class="ftn-links mx-0 ml-0 flex w-full flex-wrap px-gap pb-2 pl-0 md:mt-1 md:w-8/12 md:pb-0"
        >
          <li
            v-for="(link, index) in footer.paidSupportLinks.links"
            :key="index"
            class="mb-0 block w-6/12 text-xs font-light leading-[30px] text-white hover:underline hover:opacity-100 md:w-auto md:pr-[7px] md:leading-[22px]"
          >
            <LinksLocaleLink
              v-if="link.href && link.text"
              :link="link.href"
              class="m-0 text-white md:mr-1/4"
              >{{ link.text }}
              <ImagesNuxtImg
                v-if="link.img"
                :url="link.img"
                :alt="link.alt"
                :classes="'ml-1/4 mr-1/4 inline'"
              />
            </LinksLocaleLink>
          </li>
        </ul>
        <div
          class="column copywrite mx-0 w-12/12 pl-0 text-center md:mt-1 md:w-2/12 md:max-w-[125px] md:text-right"
        >
          <div
            class="m-auto block w-full md:hidden"
            style="margin-bottom: 10px"
          >
            <LinksLocaleLink
              :link="'/'"
              title="Samsara Homepage"
              class="flex justify-center"
            >
              <LogosSvgLogoMain :color="'white'" class="max-w-[144px]" />
            </LinksLocaleLink>
          </div>
          <p class="font-regular mb-0 text-xs text-white opacity-75">
            © {{ new Date().getFullYear() }} Samsara Inc.
          </p>
        </div>
      </div>
    </nav>
  </footer>
</template>
<script>
  import { flagMappings, localeMappings } from '~/constants/locales';
  import { report } from '~/utils/telemetry';

  export default {
    async setup() {
      const { locale } = useI18n();
      const localePath = useLocalePath();

      const { data, error, pending } = await useAsyncGql({
        operation: 'FooterTermsNavigation',
        variables: {
          locale: localeMappings[locale.value],
        },
      });

      if (error.value) {
        report(error.value);
      }

      const selectedLocale = error.value
        ? ''
        : data.value.header.countryDropdowns.countries.filter(
            (country) => country.selected
          )[0].country_name;

      return {
        footer: data.value?.footer ?? '',
        loading: pending.value,
        selectedLocale,
        localePath,
      };
    },
    methods: {
      setLocale(selectedLocale) {
        this.$i18n
          .setLocale(selectedLocale === 'us' ? 'en-US' : selectedLocale)
          .then(() => {
            this.$router.push(this.localePath('/'));
          });
      },
    },
  };
</script>

<style lang="scss">
  .ftn-links {
    @screen md {
      li {
        &::after {
          content: '|';
        }
        &:last-child {
          &::after {
            content: '';
          }
        }
      }
    }
  }
</style>
