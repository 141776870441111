const bots = [
  'amazon-qbusiness',
  'ahrefsbot',
  'baiduspider',
  'bingbot',
  'bingpreview',
  'facebookexternal',
  'petalbot',
  'pinterest',
  'datadogsynthetics',
  'screaming frog',
  'headlesschrome',
  'yahoo! slurp',
  'yandexbot',
  'adsbot-google',
  'apis-google',
  'duplexweb-google',
  'feedfetcher-google',
  'google favicon',
  'google web preview',
  'google-read-aloud',
  'googlebot',
  'googleweblight',
  'mediapartners-google',
  'storebot-google',
  'mj12bot',
  'semrushbot',
  'dotbot',
  'exabot',
  'gigabot',
  'ia_archiver',
  'spbot',
  'twitterbot',
  'linkedinbot',
  'curl',
  'python-urllib',
  'ruby',
  'nutch',
  'linkdexbot',
  'sogou spider',
  'slackbot',
  'vkshare',
  'xovibot',
  'applebot',
  'a6-indexer',
  'archive.org_bot',
  'google-structured-data-testing-tool',
  'anthropic',
  'openai',
  'gpt-3',
  'gpt-4',
  'cohere',
  'ai21',
  'microsoft-bot-framework',
  'huggingface',
  'ibm-watson'
];

export default bots;
