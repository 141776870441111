<template>
  <div class="mb-2 w-full p-0">
    <div class="mb-space font-bold leading-loose text-white">
      <LinksLocaleLink
        :link="props.column.main.href"
        class="text-white no-underline hover:no-underline"
      >
        {{ props.column.main.text }}</LinksLocaleLink
      >
    </div>
    <ul>
      <li
        v-for="(link, index) in props.column.links"
        :key="index"
        class="mb-[12px] pl-0 font-normal"
      >
        <LinksLocaleLink
          :link="link.href"
          class="text-xs leading-6 text-white opacity-90 hover:underline hover:opacity-100"
          >{{ link.text }}</LinksLocaleLink
        >
      </li>
    </ul>
  </div>
</template>

<script setup>
  const props = defineProps({
    column: {
      type: Object,
      required: true,
    },
  });
</script>
