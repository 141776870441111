<template>
  <div
    class="mt-1 w-12/12 px-gap text-center"
    :class="type === 'Product Callout Double' ? 'md:w-8/12' : 'md:w-4/12'"
  >
    <div class="card__wrapper flex h-full flex-col bg-blue">
      <div class="px-3">
        <MarkdownTheMarkdown :source="content.content" />
      </div>
      <div
        v-if="content.image && content.secondaryImage"
        class="img__wrapper mx-4 flex flex-wrap overflow-hidden"
      >
        <div class="column z-10 w-12/12 px-gap md:w-5/12">
          <ImagesPicture
            :image="content.image.fields"
            classes="z-10 relative m-auto"
          />
          <p
            class="p--xs relative z-10 mt-1/2 font-bold uppercase tracking-wide text-white"
          >
            {{ getNestedProperty(content, 'image.fields.alt', false) }}
          </p>
        </div>
        <div class="column z-10 w-12/12 px-gap md:ml-2/12 md:w-5/12">
          <ImagesPicture
            :image="content.secondaryImage.fields"
            classes="z-10 relative  m-auto"
          />
          <p
            class="p--xs relative z-10 mt-1/2 font-bold uppercase tracking-wide text-white"
          >
            {{ getNestedProperty(content, 'secondaryImage.fields.alt', false) }}
          </p>
        </div>
      </div>
      <template v-else-if="content.image && !content.secondaryImage">
        <div class="column mt-auto w-12/12 px-gap">
          <ImagesPicture :image="content.image.fields" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { getNestedProperty } from '~/utils';

  export default {
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
    computed: {
      type() {
        return this.content.type;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card--product-callout {
    border-radius: 8px;
    .card__wrapper {
      border-radius: 8px;
      :deep() {
        h5 {
          @apply mt-3 inline-block rounded-full border-1 border-black px-2 py-1/4 text-white;

          background-color: #062b44;
        }
        p:not(.p--xs) {
          @apply mt-1 font-medium text-white;
        }
      }
    }
    .img__wrapper {
      @apply bg-no-repeat;
      background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/6VE7SeFUMh9eL07Wc5I4B0/5f18de48da5c4af8f6345b7d0d6c3728/Vector__1_.svg');
      background-size: 68px auto;
      background-position: center;
      @screen md {
        background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/4geJQxJAbWCXPeZUuF7qWo/2a30d82355bb115678023004523a5b8a/blue-squiggle.svg');
        background-size: auto 68px;
        background-position: center 20px;
      }
      .column {
        @apply relative;
        &::before {
          @apply absolute right-0 top-0 z-0 h-full w-full;

          content: '';
          background: rgb(3, 132, 251);
          background: linear-gradient(
            180deg,
            rgba(3, 132, 251, 0) 0%,
            rgba(3, 132, 251, 1) 50%
          );
          @screen md {
            background: rgb(3, 132, 251);
            background: linear-gradient(
              90deg,
              rgba(3, 132, 251, 0) 0%,
              rgba(3, 132, 251, 1) 30%
            );
          }
        }
        &:first-of-type {
          &::before {
            background: rgb(3, 132, 251);
            background: linear-gradient(
              0deg,
              rgba(3, 132, 251, 0) 0%,
              rgba(3, 132, 251, 1) 50%
            );
            @screen md {
              background: rgb(3, 132, 251);
              background: linear-gradient(
                270deg,
                rgba(3, 132, 251, 0) 0%,
                rgba(3, 132, 251, 1) 30%
              );
            }
          }
        }
      }
    }
  }
</style>
