<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
  >
    <path d="M1 1L7.5 7.5L14 1" :stroke="color" stroke-width="2" />
  </svg>
</template>
<script>
  export default {
    props: {
      color: {
        type: String,
        required: false,
        default: '#0384FB',
      },
    },
  };
</script>
