export default {
  'en-US': {
    error: '404 Error',
    title: 'PAGE NOT FOUND',
    body: 'Looking for something else? Samsara can help you track those assets down.',
    linkText: 'See how',
  },
  ca: {
    error: '404 Error',
    title: 'PAGE NOT FOUND',
    body: 'Looking for something else? Samsara can help you track those assets down.',
    linkText: 'See how',
  },
  uk: {
    error: '404 Error',
    title: 'PAGE NOT FOUND',
    body: 'Looking for something else? Samsara can help you track those assets down.',
    linkText: 'See how',
  },
  ie: {
    error: '404 Error',
    title: 'PAGE NOT FOUND',
    body: 'Looking for something else? Samsara can help you track those assets down.',
    linkText: 'See how',
  },
  fr: {
    error: '404 Error',
    title: 'Page non trouvée',
    body: "Vous cherchez quelque chose d'autre ? Samsara peut vous aider à localiser vos actifs. ",
    linkText: 'Découvrir comment',
  },
  'fr-be': {
    error: '404 Error',
    title: 'Page non trouvée',
    body: "Vous cherchez quelque chose d'autre ? Samsara peut vous aider à localiser vos actifs. ",
    linkText: 'Découvrir comment',
  },
  'fr-ca': {
    error: '404 Error',
    title: 'Page non trouvée',
    body: "Vous cherchez quelque chose d'autre ? Samsara peut vous aider à localiser vos actifs. ",
    linkText: 'Découvrir comment',
  },
  'fr-ch': {
    error: '404 Error',
    title: 'Page non trouvée',
    body: "Vous cherchez quelque chose d'autre ? Samsara peut vous aider à localiser vos actifs. ",
    linkText: 'Découvrir comment',
  },
  de: {
    error: '404 Error',
    title: 'Seite nicht gefunden',
    body: 'Suchen Sie etwas anderes? Samsara kann Ihnen helfen, Ihre Assets zu finden.',
    linkText: 'Finden Sie heraus wie',
  },
  nl: {
    error: '404 Error',
    title: 'PAGINA NIET GEVONDEN',
    body: 'Op zoek naar iets anders? Samsara helpt je om al jouw assets gemakkelijk terug te vinden.',
    linkText: 'Bekijk hoe',
  },
  be: {
    error: '404 Error',
    title: 'PAGINA NIET GEVONDEN',
    body: 'Op zoek naar iets anders? Samsara helpt je om al jouw assets gemakkelijk terug te vinden.',
    linkText: 'Bekijk hoe',
  },
  mx: {
    error: 'Error 404',
    title: 'Págino no encontrada',
    body: '¿Estabas buscando algo más? Samsara te ayuda a rastrearlo.',
    linkText: 'Descúbre cómo',
  },
};
