import { isStorageAvailable } from '~/utils/analytics';
import { generateUUID } from '~/utils/html-helpers';

export default defineNuxtPlugin({
  name: 'set-session-id',
  parallel: true,
  hooks: {
    // You can directly register Nuxt app runtime hooks here
    'app:mounted'() {
      if (
        isStorageAvailable('sessionStorage') &&
        !sessionStorage.getItem('_session_id')
      ) {
        const key = generateUUID();
        sessionStorage.setItem('_session_id', key);
      }
    },
  },
  env: {
    // Set this value to `false` if you don't want the plugin to run when rendering server-only or island components.
    islands: false,
  },
});
