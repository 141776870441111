export function createToPath(toPath: string, base: string) {
  // check if path has a locale in it
  const regex = /^\/[a-z]{2}\//i;

  if (regex.test(toPath)) {
    return `${base}${toPath.slice(3)}`;
  } else {
    if (base === '') {
      return '/';
    }
    return toPath.length > 3 ? `${base}${toPath}` : base;
  }
}

export function extractRootPath(path: string) {
  const segments = path.split('/');

  if (segments.length > 1 && segments[1].length === 2) {
    return `/${segments.slice(2).join('/')}`;
  } else {
    return path;
  }
}

export function extractLocaleFrom(path: string) {
  const segments = path.split('/');

  if (segments.length > 1 && segments[1].length === 2) {
    return segments[1];
  } else {
    return 'us';
  }
}

export const operatingGeos = new Map([
  ['US', { locale: 'us', base: '' }],
  ['MX', { locale: 'mx', base: '/mx' }],
  ['CA', { locale: 'ca', base: '/ca' }],
  ['GB', { locale: 'uk', base: '/uk' }],
  ['IE', { locale: 'uk', base: '/uk' }],
  ['DE', { locale: 'de', base: '/de' }],
  ['FR', { locale: 'fr', base: '/fr' }],
  ['NL', { locale: 'nl', base: '/nl' }],
]);
