<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="30"
      cy="30.5"
      r="30"
      transform="rotate(-180 30 30.5)"
      :fill="fillColor"
    />
    <path
      d="M27.9308 40.8447L17.5859 30.4999L27.9308 20.1551"
      stroke="white"
      stroke-width="5"
    />
    <path d="M17.5856 30.5L45.5166 30.5" stroke="white" stroke-width="5" />
  </svg>
</template>
<script>
  export default {
    props: {
      fillColor: {
        type: String,
        required: false,
        default: '#0384FB',
      },
    },
  };
</script>
