<template>
  <svg
    class="ml-1/2 inline"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.5" cy="12.5" r="12.5" fill="white" />
    <path d="M17 12.5L10.25 16.3971L10.25 8.60289L17 12.5Z" :fill="fill" />
  </svg>
</template>
<script>
  export default {
    props: {
      fill: {
        type: String,
        required: false,
        default: '#0384FB',
      },
    },
  };
</script>
