<template>
  <button
    v-if="type === 'Default' || !type"
    :class="classes"
    :aria-label="ariaLabel"
    :disabled="disabled"
  >
    {{ text || '' }}<slot />
  </button>
  <select
    v-else-if="type === 'Select'"
    class="btn bold relative h-[57px] w-full border-1 border-blue bg-white px-[20px] py-[17px] text-left uppercase text-blue-dark md:w-[241px]"
    @change="switchSelect($event)"
  >
    <option v-for="value in selectOptions" :key="value" :value="value">
      {{ value }}
    </option>
  </select>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        required: false,
        default: 'Check Our Prices',
      },
      type: {
        type: String,
        required: false,
        default: 'Default',
      },
      selectOptions: {
        type: Array,
        required: false,
        default: () => [],
      },
      ariaLabel: {
        type: String,
        required: false,
        default: '',
      },
      classes: {
        type: String,
        required: false,
        default: 'btn btn--blue btn--blue:hover',
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      dropshadow: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    mounted() {
      if (this.selectOptions && this.type === 'Select') {
        this.$emitter.emit('select-value', this.selectOptions[0]);
      }
    },
    methods: {
      switchSelect(event) {
        this.$emitter.emit('select-value', event.target.value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  button:disabled,
  button[disabled] {
    @apply pointer-events-none opacity-[.25];
  }
  select {
    background: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' viewBox='0 0 20 10' fill='none' %3e%3cpath d='M20 0L0 0L10.2703 10L20 0Z' fill='%230384FB' /%3e%3c/svg%3e")
      white no-repeat calc(100% - 10px) !important;
    background-position: calc(100% - 0.75rem) center;
    appearance: none;
    @apply p-0 pl-[20px];
    &:active,
    &:focus {
      @apply border-blue;
    }
  }
</style>
