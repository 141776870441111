<template>
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="cursor-pointer"
  >
    <path d="M14 8L0 0V16L14 8Z" :fill="fill" />
  </svg>
</template>
<script>
  export default {
    props: {
      fill: {
        type: String,
        required: false,
        default: '#00263E',
      },
    },
  };
</script>
