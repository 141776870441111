<template>
  <footer
    v-if="!pending"
    id="site-footer"
    class="relative bg-blue-dark px-0 py-4"
  >
    <nav class="small-text container">
      <div class="-mx-gap flex flex-wrap">
        <div class="hidden w-6/12 px-gap md:flex md:w-3/12">
          <div
            class="p--title mb-2 w-full font-semibold leading-loose text-white"
          >
            <LinksLocaleLink
              link="/"
              class="text-white no-underline hover:no-underline"
            >
              <ImagesNuxtImg
                v-if="header?.logoMobile?.url"
                :url="header.logoMobile.url"
                :alt="header.logoMain.description"
                :classes="'logo'"
            /></LinksLocaleLink>
          </div>
        </div>
        <div class="w-6/12 px-gap md:w-3/12">
          <FootersFooterColumn
            v-if="footer?.fleetManagementLinks"
            :column="footer.fleetManagementLinks"
          />
          <FootersFooterColumn
            v-if="footer?.industriesLinks"
            :column="footer.industriesLinks"
          />
          <FootersFooterColumn
            v-if="footer?.industrialLinks"
            :column="footer.industrialLinks"
            class="md:hidden"
          />
        </div>
        <div class="hidden w-6/12 flex-wrap px-gap md:flex md:w-3/12">
          <FootersFooterColumn
            v-if="footer?.resourcesLinks"
            :column="footer.resourcesLinks"
          />
          <FootersFooterColumn
            v-if="footer?.industrialLinks"
            :column="footer.industrialLinks"
          />
        </div>
        <div class="flex w-6/12 flex-wrap px-gap md:w-3/12">
          <FootersFooterColumn
            v-if="footer?.companyLinks"
            :column="footer.companyLinks"
          />
          <FootersFooterColumn
            v-if="footer?.resourcesLinks"
            :column="footer.resourcesLinks"
            class="md:hidden"
          />
        </div>
      </div>

      <div v-if="footer" class="flex flex-wrap">
        <div class="column mx-0 w-12/12 md:my-1 md:w-6/12 lg:px-gap">
          <div class="select--mobile-lang flex">
            <LazyImagesNuxtImg :url="currentFlag.url" :alt="currentFlag.alt" />
            <select
              v-if="header?.countryDropdowns?.countries"
              v-model="selectedLocale"
              name="select-region"
              class="select--region ml-1/4 bg-transparent text-xs text-white"
              @change.prevent.stop="changeLocale"
            >
              <option disabled selected class="text-xs">
                {{ selectedLocale }}
              </option>
              <option
                v-for="(country, index) in header.countryDropdowns.countries"
                :key="index"
                :value="country.value.toLowerCase()"
                class="text-xs"
                :class="{ hidden: country.selected }"
              >
                {{ country.country_name }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-if="footer?.socialLinksCollection?.items"
          class="column mb-2 mt-1 flex w-12/12 items-center justify-center md:my-1 md:w-6/12 md:justify-end"
        >
          <LinksLocaleLink
            v-for="(image, index) in footer.socialLinksCollection.items"
            :key="index"
            :link="image.description"
            target="_blank"
            ><ImagesNuxtImg
              v-if="image"
              :url="image.url"
              :alt="image.description"
              :classes="'mx-1 w-1 opacity-75 hover:underline hover:opacity-100 md:ml-2 md:mr-0'"
          /></LinksLocaleLink>
        </div>
      </div>
      <div
        v-if="footer?.supportLinks?.links"
        class="wrapper--lang flex flex-wrap justify-between"
      >
        <ul class="legal mx-0 my-1 flex flex-wrap px-gap pl-0">
          <li
            v-for="(link, index) in footer.supportLinks.links"
            :key="index"
            class="legal-links mb-1/2 font-light text-white"
          >
            <LinksLocaleLink
              v-if="link.href && link.text"
              :link="link.href"
              class="text-white"
              >{{ link.text }}
              <ImagesNuxtImg
                v-if="link.img"
                :url="link.img"
                :alt="link.alt"
                :classes="'ml-1/4 inline'"
              />
            </LinksLocaleLink>
          </li>
        </ul>
        <div
          class="column copywrite mx-0 my-1 w-12/12 pl-0 text-center md:text-right"
        >
          <div
            class="m-auto block w-full md:hidden"
            style="margin-bottom: 10px"
          >
            <LinksLocaleLink
              link="/"
              class="text-white no-underline hover:no-underline"
            >
              <ImagesNuxtImg
                v-if="header?.logoMobile?.url"
                :url="header.logoMobile.url"
                :alt="header.logoMain.description"
                :classes="'logo m-auto'"
            /></LinksLocaleLink>
          </div>
          <p class="font-regular mb-0 text-xs text-white opacity-75">
            © {{ new Date().getFullYear() }} Samsara Inc.
          </p>
        </div>
      </div>
    </nav>
  </footer>
</template>

<script setup>
  import { flagMappings, localeMappings } from '~/constants/locales';
  import { report } from '~/utils/telemetry';

  const { locale } = useI18n();
  const { fullPath } = useRoute();
  const { analyticsTrack } = useAnalytics();

  const { data, error, pending } = await useAsyncGql({
    operation: 'FooterNavigation',
    variables: {
      locale: localeMappings[locale.value],
    },
  });

  if (error.value) {
    report(error.value.message);
  }

  const selectedLocale = ref('');

  selectedLocale.value = error.value
    ? ''
    : data.value?.header?.countryDropdowns?.countries?.filter(
        (country) => country.selected
      )?.[0]?.country_name ?? '';

  const header = data.value?.header ?? {};
  const footer = data.value?.footer ?? {};

  const currentFlag = computed(() => {
    const flag = {
      url: 'https://images.ctfassets.net/bx9krvy0u3sx/4evkfX9H4vFGqTr268gY9Z/0202ee3bc4ccd161f850b158e863551f/Group_5.svg',
      alt: 'Image of a globe',
    };

    if (footer?.flagImagesCollection?.items.length) {
      const matchedFlag = footer.flagImagesCollection.items.find((image) => {
        return image.description === flagMappings[locale.value];
      });
      return {
        ...flag,
        alt: `${matchedFlag.title} flag`,
      };
    }

    return flag;
  });

  const trackClick = (buttonName, locale) => {
    analyticsTrack('Button Clicked', {
      locale,
      buttonName,
    });
  };

  const changeLocale = async () => {
    const basePath =
      selectedLocale.value === 'us' ? '' : `/${selectedLocale.value}`;
    const host = window.location.origin;
    let newPath = basePath + fullPath;

    trackClick('Locale Selector', selectedLocale.value);

    if (locale.value !== 'en-US') {
      for (const key in localeMappings) {
        if (fullPath.split('/').includes(`${key}`)) {
          const pathSegments = fullPath.split('/');
          newPath = basePath + '/' + pathSegments.slice(2).join('/');
          break;
        }
      }
    }

    try {
      await $fetch.raw(host + newPath);
      await navigateTo({ path: newPath });
    } catch (error) {
      await navigateTo({ path: '/' });
    }
  };
</script>

<style lang="scss">
  #site-footer {
    .container {
      @media (max-width: theme('screens.lg')) {
        @apply max-w-full;
      }
    }
    .logo {
      width: 132px;

      @screen lg {
        width: 144px;
      }
    }

    .select--region {
      @apply w-full cursor-pointer;

      line-height: 30px;
      margin-left: 10px;
      opacity: 0.9;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    .wrapper--lang {
      border-top: 1px solid #4c5e64;
    }

    .select--mobile-lang {
      padding: 15px;
      border: 1px solid #b2bec5;
      width: 100%;
      cursor: pointer;

      @screen md {
        width: 216px;
      }

      &:hover {
        opacity: 1;
      }

      img {
        @apply h-1 w-auto cursor-pointer opacity-100;

        height: 23px;
        width: 23px;
      }
    }

    ul {
      @apply ml-0;
      li {
        line-height: 22px;
        font-size: 14px;
        display: block;

        &::marker {
          @apply hidden;
        }
      }
    }

    .legal {
      @apply w-full;

      @screen md {
        max-width: 620px;
      }
    }

    .legal-links {
      @apply w-6/12 text-xs;

      line-height: 22px;

      &:hover {
        @apply underline opacity-100;
      }

      &:nth-child(even) {
        padding-left: 15px;
      }

      @screen md {
        @apply w-auto;
        margin-right: 7px;
        margin-bottom: 5px;

        &::after {
          content: '|';
          margin-left: 7px;
        }

        &:last-child::after {
          content: ' ';
        }
      }

      @screen md {
        &:nth-child(even) {
          padding-left: 0;
        }
      }
    }

    .copywrite {
      @screen md {
        max-width: 125px;
      }
    }
  }
</style>
