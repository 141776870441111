<template>
  <div>
    <FormKit
      v-if="!formSubmitted"
      id="inline-cta-form"
      type="form"
      :actions="false"
      #default="{ value, state, disabled }"
      form-class="inline-cta-form"
      :incomplete-message="false"
      @submit="submitHandler"
    >
      <div
        class="w-fit-content flex flex-row flex-wrap rounded-md bg-white p-space2 md:flex-nowrap"
      >
        <div class="w-full min-w-[300px]">
          <template v-for="(input, index) in questions" :key="index">
            <section class="input-section" :class="input.fields.type">
              <component
                :is="formComponents.get(input.fields.type)"
                :placeholder="input.fields.label"
                :name="input.fields.name"
                :error-messages="input.fields.errorMessage"
                :options="input.fields.valueList"
                :content="input.fields.content"
                type="inline"
              />
            </section>
          </template>
        </div>
        <div class="w-full">
          <FormKit
            type="submit"
            :label="buttonText"
            input-class="w-full h-full text-white border-1 uppercase rounded tracking-wide text-xs text-center font-bold inline-block pt-[14px] pb-[12px] px-[20px] cursor-pointer min-w-[150px] max-h-[45px] bg-blue border-blue"
            outer-class="!pt-0 !pr-0"
          >
            <template v-if="submitLoading">
              <IconsSpinner />
            </template>
            <template v-else>{{ buttonText }}</template>
          </FormKit>
        </div>
      </div>
    </FormKit>
  </div>
</template>

<script setup>
  import { usePricingFormStore } from '~/stores/pricing-form';
  import { LOCALE_CODE_TO_COUNTRY } from '~/constants/locales';

  const store = usePricingFormStore();
  const { analyticsTrack, analyticsAlias, analyticsIdentify } = useAnalytics();
  const { fullPath } = useRoute();
  const { isMobile } = useDevice();
  const { locale } = useI18n();

  const localeSite = LOCALE_CODE_TO_COUNTRY[locale.value.toLowerCase()];

  defineProps({
    formId: {
      type: String,
      required: true,
    },
    successResponse: {
      type: String,
      required: false,
      default: null,
    },
    questions: {
      type: Object,
      required: false,
      default: null,
    },
    channelName: {
      type: String,
      required: false,
      default: null,
    },
    thankYou: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Submit',
    },
    gated: {
      type: Boolean,
      required: false,
      default: false,
    },
    action: {
      type: String,
      required: false,
      default: 'Default',
    },
    type: {
      type: String,
      required: false,
      default: 'Default',
    },
  });

  const formComponents = new Map([
    ['firstnameLastname', resolveComponent('FormsFkTextNameShortForm')],
    ['text', resolveComponent('FormsFkTextCompanyShortForm')],
    ['email', resolveComponent('FormsFkTextEmailShortForm')],
    ['phone', resolveComponent('FormsFkTextPhoneShortForm')],
    ['radio', resolveComponent('FormsFkRadioAssetCountShortForm')],
    ['optin', resolveComponent('FormsFkCheckboxOptInDisclaimers')],
  ]);

  const submitLoading = ref(false);

  const submitHandler = async (formValues) => {
    submitLoading.value = true;
    if (formValues.email) {
      store.emailValid = true;
      store.$patch((state) => {
        state.formFields.email = formValues.email;
      });
      analyticsAlias(formValues.email);

      analyticsIdentify(formValues.email, {
        email: formValues.email,
      });
      // This might need to be different depending on if user has completed the form or not
      analyticsTrack('Form Submitted', {
        form_type: 'Inline CTA Form',
        form_url: fullPath,
        is_mobile: isMobile,
        locale_site: localeSite,
        tracking_id: 'inline_cta_form',
      });
      submitLoading.value = false;
      await navigateTo('/pricing');
    } else {
      submitLoading.value = false;
    }
  };

  onMounted(() => {
    // Track form started
    analyticsTrack('Form Started', {
      form_type: 'Inline CTA Form',
      form_url: fullPath,
      is_mobile: isMobile,
      locale_site: localeSite,
      tracking_id: 'inline_cta_form',
    });
  });
</script>

<style lang="scss" scoped>
  .formkit-outer {
    padding: 10px 10px 0px 0px;
    &[data-family='button'] {
      padding-top: 20px;
    }
  }

  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .button-group {
    margin-top: 16px;
  }

  .hidden-radio {
    display: none;
  }

  .fleet-size-button {
    &.selected {
      background-color: #007bff;
      color: #fff;
      border-color: #007bff;
    }
  }
  .formkit-form {
    @apply flex flex-wrap justify-center;
  }
  .input-section {
    @apply mb-1 h-full min-h-[45px] w-full md:mb-0 md:pr-1/2;

    &.radio,
    &.optin {
      @apply w-full;
    }

    :deep(.formkit-outer) {
      @apply mb-0;
    }

    :deep(input) {
      @apply h-full min-h-[45px] rounded-[4px] p-1/2 text-blue-dark;
    }
  }
  .inline-cta-form {
    :deep(label) {
      @apply mb-1/4 block text-left text-xs font-medium text-blue-dark before:hidden;
    }
    :deep(.formkit-message) {
      @apply text-left;
    }
    :deep(legend) {
      @apply text-left;
    }
    :deep(.formkit-inner) {
      @apply relative;

      input {
        @apply mt-0 outline outline-1 outline-gray-light hover:outline-2;

        &:hover {
          outline-color: #0384fb !important;
        }

        &:focus {
          @apply outline-2;
          filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
          outline-color: #0384fb !important;
        }
      }
    }
    :deep(.formkit-messages) {
      @apply mb-[-20px];
    }
  }
</style>
