import { ValidationState } from '~/types/enums/validation-state';
export function useGetValidationState(input: any) {
  return computed(() => {
    if (
      input.value?.node?.context.state.valid &&
      input.value?.node?.context.state.blurred
    )
      return ValidationState.VALID;
    else if (
      !input.value?.node?.context.state.valid &&
      input.value?.node?.context.state.blurred
    )
      return ValidationState.INVALID;
  });
}
