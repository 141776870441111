<template>
  <div class="flex w-full flex-row justify-center pl-[3px]">
    <div class="disclaimer text-center sm:max-w-[500px]">
      <div
        v-if="props.content"
        class="disclaimer__privacy mb-1 flex flex-row space-x-[8px] [&>p>a]:italic [&>p>a]:text-blue [&>p]:-mb-0 [&>p]:text-xs [&>p]:leading-[22px]"
      >
        <RichTextRenderer
          :document="props.content"
          :nodeRenderers="nodeRenderers"
          :markRenderers="markRenderers"
        />
      </div>
      <div
        v-if="props.label"
        class="disclaimer__marketing space-x-[8px] text-center [&>p]:text-xs [&>p]:italic [&>p]:leading-[22px]"
      >
        <template v-if="optInRequired">
          <FormKit
            v-if="optInRequired"
            type="checkbox"
            :label="props.label"
            name="terms"
            label-class="italic ml-1/2 font-normal"
            wrapper-class="!text-center"
            input-class=" border-0"
            :value="false"
            v-model="optInStatus"
          />
          <pre wrap>{{ value }}</pre>
        </template>
        <p v-else>{{ props.label }}</p>
      </div>
    </div>
  </div>
</template>
<script setup>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers, markRenderers } from '~/utils/contentful-helpers';

  const props = defineProps({
    content: {
      type: Object,
      required: true,
    },
    label: {
      type: Object,
      required: true,
    },
  });

  const optInStatus = defineModel(false);

  const { locale } = useI18n();
  const store = usePricingFormStore();

  const optInRequired = computed(
    () => locale.value !== 'en-US' && locale.value !== 'mx'
  );

  watch(optInStatus, (value) => {
    store.disclaimers.marketingOptIn = value;
  });
</script>
