<template>
  <div>
    <div class="cursor-pointer" @click.prevent="openModal = !openModal">
      <ImagesPicture v-if="card.image" :image="card.image.fields" />
      <LinksContentfulLink v-if="id" :id="id" label="card" />
    </div>
    <ModalsTheModal bg="bg-white" :is-open="openModal" @close="closeModal">
      <div class="-mx-gap flex flex-wrap">
        <div class="w-12/12 md:w-4/12">
          <ImagesPicture v-if="card.image" :image="card.image.fields" />
        </div>
        <div class="w-12/12 text-left md:w-8/12 md:pl-2">
          <MarkdownTheMarkdown :source="card.content" />
        </div>
      </div>
    </ModalsTheModal>
  </div>
</template>
<style lang="scss" scoped>
  .card--image-modal {
    @apply mx-1 mb-1 inline-flex whitespace-normal;

    max-width: 120px;
    @screen md {
      max-width: 130px;
      @apply m-1;
    }

    :deep(img) {
      vertical-align: middle;
    }
  }
  .card--image-modal :deep(.modal__inner) {
    @apply h-auto p-2;

    max-width: 800px;

    @screen md {
      @apply p-4;
    }

    img {
      @media (max-width: theme('screens.md')) {
        @apply block pb-1;

        max-width: 120px;
      }
    }
  }
</style>
<script>
  export default {
    props: {
      card: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        openModal: false,
      };
    },
    methods: {
      closeModal() {
        this.openModal = false;
      },
    },
  };
</script>
