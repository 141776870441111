<template>
  <span v-if="props.link === undefined" :class="$attrs.class"></span>
  <NuxtLink
    v-else-if="props.link.includes('http')"
    :class="$attrs.class"
    :to="props.link"
    target="_blank"
    external
    ><slot></slot
  ></NuxtLink>
  <NuxtLink
    v-else-if="props.link.includes('mailto:')"
    :class="$attrs.class"
    external
    :to="props.link"
    ><slot></slot
  ></NuxtLink>
  <NuxtLink
    v-else-if="!link.charAt(0).includes('/') && !link.includes('mailto:')"
    :class="$attrs.class"
    :to="props.link"
    :target="props.target"
    ><slot></slot
  ></NuxtLink>
  <NuxtLink
    v-else
    :key="uuid"
    :class="$attrs.class"
    :to="localePath(props.link)"
    :target="props.target"
    v-bind:external="isExternal() ? true : undefined"
    ><slot></slot
  ></NuxtLink>
</template>

<script setup>
  const localePath = useLocalePath();
  const uuid = useId();

  const props = defineProps({
    link: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: false,
      default: '_self',
    },
  });

  const isExternal = () => {
    const externalTerms = ['docs', 'hardware-warranty', 'referrals', 'pdf'];
    const segments = props.link.split('/');
    return segments.some((segment) => externalTerms.includes(segment));
  };
</script>
