<template>
  <FormKit
    type="submit"
    :label="label"
    :disabled="!props.state.valid"
    :input-class="`text-white border-1 uppercase rounded tracking-wide text-xs text-center font-bold inline-block pt-[14px] pb-[12px] px-[20px] cursor-pointer min-w-[200px] ${
      !props.state.valid
        ? 'bg-gray-light border-gray-light'
        : 'bg-blue border-blue'
    }`"
  >
    <template v-if="state.submitted">
      <IconsSpinner />
    </template>
    <template v-else>{{ label }}</template>
  </FormKit>
</template>

<script setup>
  const props = defineProps({
    state: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: 'Submit',
    },
  });
</script>
