import { createClient } from 'contentful';
import * as allContentful from 'contentful';

export default defineNuxtPlugin(() => {
  const {
    isSandbox,
    isStaging,
    ctflProdSpaceId,
    ctflStagingAccessToken,
    ctflProdAccessToken,
  } = useRuntimeConfig().public;

  const config =
    isSandbox || isStaging
      ? {
          space: ctflProdSpaceId,
          accessToken: ctflStagingAccessToken,
          host: 'preview.contentful.com',
          environment: 'master',
        }
      : {
          space: ctflProdSpaceId,
          accessToken: ctflProdAccessToken,
          environment: 'master',
        };

  // issue with contentful working in different environments
  // so we will get the client this way
  // https://github.com/contentful/contentful.js/issues/1233
  const createClientFunction = createClient || allContentful.createClient;

  const chooseConfig = createClientFunction(config);
  return {
    provide: {
      contentfulClient: chooseConfig,
    },
  };
});
