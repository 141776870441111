import {
  transformQueryToDict,
  setUTMCookies,
  setTSCookie,
  setOneTimeCookie,
  setCIDCookie,
  determineOrigin,
} from './cookie-helpers';

export function runUTMCookies() {
  let exclude = 'False';

  if (document.getElementById('organic_tracking')) {
    exclude = document.getElementById('organic_tracking').content;
  }
  const params = transformQueryToDict();
  let wasUpdated = false;
  const pathNameTrailingSlashTrimmed = location.pathname.replace(/\/$/, '');

  wasUpdated = setUTMCookies(wasUpdated, params);
  setTSCookie(wasUpdated);
  setOneTimeCookie('fpv', pathNameTrailingSlashTrimmed);
  setOneTimeCookie('referrer', document.referrer);
  setCIDCookie(params);

  if (exclude !== 'True') {
    determineOrigin(document.referrer, pathNameTrailingSlashTrimmed);
  }
}
