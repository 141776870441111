<template>
  <div id="footer">
    <ModularStickyFooter
      v-if="
        props.data === null ||
        getNestedProperty(props.data, 'fields.stickyFooter', false) !== false
      "
      :sticky-footer="
        getNestedProperty(props.data, 'fields.stickyFooter.fields', false)
          ? getNestedProperty(props.data, 'fields.stickyFooter.fields', false)
          : null
      "
      :hide-pricing="props.hidePricing"
    />

    <FootersFooterNavigation
      v-if="
        props.data === null ||
        getNestedProperty(props.data, 'fields.staticFooter', false) ===
          'Default'
      "
    />

    <FootersFooterTermsNavigation
      v-if="
        props.data !== null &&
        getNestedProperty(props.data, 'fields.staticFooter', false) === 'Legal'
      "
    />
    <div v-else></div>
  </div>
</template>

<script setup>
  import { getNestedProperty } from '~/utils';

  const props = defineProps({
    data: {
      type: Object,
      required: false,
      default: () => {
        return null;
      },
    },
    hidePricing: {
      type: Boolean,
      required: false,
      default: false,
    },
  });
</script>
