<template>
  <div>
    <div class="video__container relative">
      <div
        v-if="video.gradientTop"
        :style="{
          background: video.gradientColor
            ? `linear-gradient(180deg, ${video.gradientColor} 0%, rgba(17, 17, 17, 0.76) 20.3%, rgba(17, 17, 17, 0.51) 36.9%, rgba(0, 0, 0, 0.00) 92.25%);`
            : 'linear-gradient(180deg, #000 -20.25%, rgba(0, 0, 0, 0) 87%);',
        }"
        class="gradient-top absolute top-0 z-20 block h-[50%] w-full"
      ></div>
      <div
        v-if="video.gradientBottom"
        :style="{
          background: video.gradientColor
            ? `linear-gradient(0deg, ${video.gradientColor} 0%, rgba(17, 17, 17, 0.76) 20.3%, rgba(17, 17, 17, 0.51) 36.9%, rgba(0, 0, 0, 0.00) 92.25%);`
            : 'linear-gradient(0deg, #000 -20.25%, rgba(0, 0, 0, 0) 87%);',
        }"
        class="gradient-bottom pointer-events-none absolute bottom-0 z-20 block h-[50%] w-full"
      ></div>
      <video
        v-if="video.video?.fields?.file && isHeroVideo"
        ref="assetVideo"
        class="transition-all"
        data-not-lazy
        playsinline
        autoplay
        muted
        preload="auto"
        loop
        :poster="
          video.poster?.fields?.file?.contentType === 'image/png'
            ? `https:${video.poster.fields.file.url}`
            : null
        "
      >
        <source v-if="videoUrl" :src="videoUrl" type="video/mp4" />
      </video>
      <video
        v-else-if="video.video?.fields?.file"
        ref="assetVideo"
        v-lazy-load
        playsinline
        muted
        preload="none"
        :autoplay="video.autoplay ?? true"
        :loop="video.loop ?? true"
        :controls="video.controls ?? false"
        :poster="
          video.poster?.fields?.file?.contentType === 'image/png'
            ? `https:${video.poster.fields.file.url}`
            : null
        "
      >
        <source v-if="videoUrl" :src="videoUrl" type="video/mp4" />
      </video>
      <slot></slot>
      <div
        v-if="video.coverImage && !hideCover && !autoplay"
        class="image--cover-image absolute top-0 h-full w-full"
      >
        <LazyImagesNuxtImg
          v-if="video.coverImage.fields.file"
          :url="video.coverImage.fields.file.url"
          class="h-full w-full object-cover"
          :alt="'cover image'"
        />
        <div
          class="image--cover-image__text absolute bottom-0 left-0 z-10 ml-2 h-full w-full cursor-pointer"
          tabindex="0"
          @click="
            hideCover = true;
            play();
          "
          @keydown="
            hideCover = true;
            play();
          "
        >
          <div v-if="video.coverImageText" class="absolute bottom-0 mb-2">
            <p class="mb-0 inline text-xl font-medium text-white">
              {{ video.coverImageText }}
            </p>
            <IconsSvgPlayIconBackground class="svg--play-icon" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <p v-if="video.style === 'Blog' && video.footnote" class="mt-1 text-xs">
        {{ video.footnote }}
      </p>
      <LinksContentfulLink v-if="id" :id="id" label="video" />
    </div>
  </div>
</template>

<script>
  import { inject } from 'vue';

  export default {
    props: {
      video: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
      state: {
        type: String,
        default: 'play',
      },
    },
    emits: ['video-loaded'],
    setup(props) {
      const isHeroVideo = inject('isHeroVideo', false);
      const isMounted = ref(false);

      useHead({
        link: [
          {
            rel: 'preload',
            as: 'video',
            href: props.video.video.fields.file.url,
          },
        ],
      });

      return {
        isMounted,
        isHeroVideo,
      };
    },
    data() {
      return {
        hideCover: false,
        videoUrl: '',
        observer: null,
      };
    },
    watch: {
      autoplay(newValue) {
        this.handleVideoPlayback(newValue ? 'play' : 'pause');
      },
      state: {
        handler(newValue) {
          if (this.isMounted && this.$refs.assetVideo) {
            const videoElement = this.$refs.assetVideo;

            if (newValue === 'play') {
              videoElement.play().catch(() => {
                videoElement.pause();
              });
            } else if (newValue === 'pause') {
              videoElement.pause();
            }
          }
        },
      },
      video: {
        handler(newVideo) {
          this.videoUrl = newVideo.video.fields.file.url;
        },
        deep: true,
      },
    },
    beforeMount() {
      // If video is hero video we have to load video up here because it's not lazy loaded
      if (this.isHeroVideo) {
        this.setVideoUrl();
      }
    },
    mounted() {
      this.isMounted = true;
      this.observer = new MutationObserver((mutations) => {
        for (const m of mutations) {
          const newValue = m.target.getAttribute(m.attributeName);
          this.$nextTick(() => {
            this.onClassChange(newValue, m.oldValue);
          });
        }
      });

      if (this.isHeroVideo) {
        if (this.$refs.assetVideo) {
          this.$emitter.emit('video-loaded');
          this.$refs.assetVideo.play().catch(() => {
            this.$refs.assetVideo.load();
          });
        }
      }

      if (this.$refs.assetVideo && !this.isHeroVideo) {
        this.observer.observe(this.$refs.assetVideo, {
          attributes: true,
          attributeOldValue: true,
          attributeFilter: ['class'],
        });
      }
      if (!this.isHeroVideo) {
        this.setVideoUrl();
      }
    },
    beforeUnmount() {
      this.observer.disconnect();
      this.$emitter.off('video-loaded');
    },
    methods: {
      onClassChange(classAttrValue) {
        const classList = classAttrValue.split(' ');
        if (classList.includes('isLoaded')) {
          this.$emitter.emit('video-loaded');
          this.handleVideoPlayback('play');
        }
      },
      handleVideoPlayback(method) {
        if (this.isMounted && this.$refs.assetVideo) {
          const videoElement = this.$refs.assetVideo;

          if (method === 'play') {
            if (videoElement.classList.contains('isLoaded')) {
              videoElement.play().catch(() => {
                videoElement.load(); // Reload the video if play fails
              });
            }
          } else if (method === 'pause') {
            videoElement.pause(); // Pause the video if it exists
          }
        }
      },
      setVideoUrl() {
        const desktopWidth = window.matchMedia('(min-width: 991px)');
        const desktopVideo = this.video.video.fields.file
          ? this.video.video.fields.file.url
          : '';

        // if a mobile video is not uploaded just use desktop
        const mobileVideo =
          this.video.mobileVideo !== undefined &&
          this.video.mobileVideo.fields.file
            ? this.video.mobileVideo.fields.file.url
            : desktopVideo;

        if (desktopWidth.matches) {
          this.videoUrl = desktopVideo;
        } else {
          this.videoUrl = mobileVideo;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .image--cover-image {
    &:hover {
      .svg--play-icon {
        transform-origin: center;
        transition: transform 300ms ease;
        transform: scale(1.1);
      }
    }
    &::after {
      content: '';
      @apply absolute bottom-0 w-full;

      height: 66%;
      background: linear-gradient(0deg, #000 -20.25%, rgba(0, 0, 0, 0) 87%);
    }
  }
</style>
