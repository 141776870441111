import { localeMappings, supportedEULocales } from '~/constants/locales';

export function formatLocaleDate(dateString, locale, abbreviated) {
  const nth = function (d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  // calculate daylight savings time offsets for European countries
  const getEuropeanDLSOffsets = (dateTime) => {
    const date = new Date(dateTime);
    const utcDate = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );

    const lastSunday = (d, weekDay) => {
      const lastDayOfMonth = new Date(
        d.getFullYear(),
        d.getMonth() + 1,
        0
      ).getDay();
      return new Date(
        d.setDate(d.getDate() + ((weekDay - lastDayOfMonth - 1 + 7) % 7) - 1)
      );
    };

    const euStartSavingsTime = new Date(
      `03/${lastSunday(
        utcDate,
        2
      ).getUTCDate()}/${utcDate.getUTCFullYear()} 01:00:00`
    );

    const euEndSavingsTime = new Date(
      `10/${lastSunday(
        utcDate,
        3
      ).getUTCDate()}/${utcDate.getUTCFullYear()} 01:00:00`
    );

    const isDayLightSavings =
      utcDate >= euStartSavingsTime && utcDate < euEndSavingsTime;

    return {
      cetZone: isDayLightSavings ? 'CEST' : 'CET',
      gmtZone: isDayLightSavings ? 'BST' : 'GMT',
      addedTime: isDayLightSavings ? 2 : 0,
    };
  };

  const format = (datetime, month) => {
    const [date, time] = datetime.split('T');
    const [, , d] = date.match(/\d+/g);
    const [t] = time.split(/(?=[+-])/);
    const tSplit = t.split(':');
    const tResolved = tSplit[0];
    const timeReset = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const tAdded = parseInt(tResolved) + 3;
    const tAddedCT = parseInt(tResolved) + 2;
    const tPT =
      tResolved > 12 ? timeReset[tResolved - 13] + 'PM' : tResolved + 'AM';
    const tET = tAdded > 12 ? timeReset[tAdded - 13] + 'PM' : tAdded + 'AM';
    const tETMil = tAdded > 24 ? timeReset[tAdded - 25] : tAdded;
    const tCT =
      tAddedCT > 12 ? timeReset[tAddedCT - 13] + 'PM' : tAddedCT + 'AM';
    const { cetZone, gmtZone, addedTime } = getEuropeanDLSOffsets(date);

    const worldTimeZones = {
      cet: `${month} ${d}${nth(d)} ${parseInt(tResolved) + 9}:${
        tSplit[1]
      } ${cetZone}${addedTime > 24 ? '+1' : ''}`,
      cst: `${d} ${month} ${tCT} CST`,
      gmt: `${month} ${d}${nth(d)} ${parseInt(tResolved) + 8}:${
        tSplit[1]
      } ${gmtZone}${addedTime > 24 ? '+1' : ''}`,
    };

    const dateDict = {
      default: `${month} ${d}${nth(d)} ${tPT} PT | ${tET} ET`,
      'fr-ca': `${d} ${month} ${tETMil}:00`,
      de: worldTimeZones.cet,
      fr: worldTimeZones.cet,
      mx: worldTimeZones.cst,
      nl: worldTimeZones.cet,
      uk: worldTimeZones.gmt,
    };
    return dateDict[locale] ? dateDict[locale] : dateDict.default;
  };

  const parsedDateString =
    dateString.split(':').length >= 3
      ? dateString.split(':').slice(0, -1).join(':')
      : dateString;
  Date.prototype.stdTimezoneOffset = function () {
    const jan = new Date(this.getFullYear(), 0, 1);
    const jul = new Date(this.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  };

  Date.prototype.isDstObserved = function () {
    return this.getTimezoneOffset() < this.stdTimezoneOffset();
  };
  const timeObject = new Date(parsedDateString);
  if (timeObject.isDstObserved()) {
    timeObject.setHours(timeObject.getHours() + 1);
  }

  const day = parseInt(dateString.slice(8));
  const year = dateString.slice(0, 4);
  const numericalMonth = dateString.slice(5, 7);
  const rawDateWithMonthMaintained = new Date(`${year}-${numericalMonth}-15`);
  const month = rawDateWithMonthMaintained.toLocaleString(
    localeMappings[locale],
    {
      month: `${abbreviated ? 'short' : 'long'}`,
    }
  );
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);

  if (abbreviated) {
    return `${format(parsedDateString, capitalizedMonth)}`;
  } else {
    return `${capitalizedMonth} ${day}, ${year}`;
  }
}

export function getNestedProperty(
  obj: any,
  path: string,
  defaultValue: any = undefined
): any {
  const keys = path.split('.');
  let result = obj;

  for (const key of keys) {
    if (result !== null && result !== undefined) {
      result = result[key];
    } else {
      return defaultValue;
    }
  }

  return result !== undefined ? result : defaultValue;
}

export function isEULocale(currentLocale: string) {
  return !!supportedEULocales.includes(currentLocale);
}

export function urlify(str: string): string {
  if (!str) return '';
  return str.trim().replace(/\s+/g, '-').toLowerCase();
}

export function dashes(value: string): string {
  if (!value) return '';
  return value.toString().replace(/_/g, '-').toLowerCase();
}

export function scrollIntoView(
  element: HTMLElement,
  behavior: 'auto' | 'smooth' = 'smooth',
  block: 'start' | 'center' | 'end' | 'nearest' = 'start'
) {
  element.scrollIntoView({ behavior, block });
}

// takes a string camelCase and returns Camel Case
export function formatCamelCase(str: string) {
  // regex that splits a string at the point where a lowercase letter is followed by an uppercase letter
  // thx ChatGPT
  let words = str.split(/(?<=[a-z])(?=[A-Z])/);

  words = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  return words.join(' ');
}

export function truncateFilter(
  text: string,
  length: number,
  suffix: string = ''
): string {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
}

export function checkEntriesLocale(entries, type, items = false) {
  if (entries.total > 0) {
    const obj = items ? entries.items[0] : entries;
    if (obj.fields.availableInLocale !== true) {
      if (type === 'modularPage') {
        return { ...obj, error: 'reroute' };
      }
      if (type === 'page') {
        return { ...obj.fields, error: 'reroute' };
      }
    } else {
      if (type === 'modularPage') {
        return { ...obj };
      }
      if (type === 'page') {
        return { ...obj.fields };
      }
    }
  }
}

export function fireMultipleEmits(emitter, eventName, payloads) {
  let delay = 0;
  for (const payload of payloads) {
    setTimeout(() => {
      emitter.emit(eventName, payload);
    }, delay);
    delay += 250;
  }
}
