<template>
  <FormKit
    v-model="value"
    type="radio"
    :label="props.label"
    :options="options"
    :name="props.name"
    validation="required"
    label-class="text-base peer-has-[:checked]:font-bold relative block h-[50px] w-full cursor-pointer rounded-md outline outline-1 outline-gray-300 peer-has-[:checked]:outline-2 peer-has-[:checked]:outline-blue peer-has-[:checked]:text-blue flex justify-center items-center text-gray-mid px-1/2"
    options-class="flex list-none !ml-0 gap-[14px] flex-wrap"
    option-class="block grow max-w-[110px]"
    inner-class="invisible peer block h-0"
    legend-class="text-xs text-blue-dark font-medium mb-1/4"
  />
  <pre wrap>{{ value }}</pre>
</template>

<script setup>
  const props = defineProps({
    errorMessages: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  });

  const { query } = useRoute();
</script>

<style lang="scss" scoped>
  .label-asterick::before {
    content: '*';
    color: theme('colors.red.DEFAULT');
    padding-right: 5px;
  }
</style>
