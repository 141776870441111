<template>
  <div class="pt-3 md:pt-[60px] lg:pt-4">
    <div class="container">
      <h2
        v-if="carousel.title"
        class="mb-0"
        :class="{
          'text-left': carousel.textAlignment === 'Left',
          'text-center': carousel.textAlignment === 'Center',
          'text-right': carousel.textAlignment === 'Right',
        }"
        v-html="carousel.title"
      />
    </div>
    <div
      class="flex w-full items-center justify-between px-gap2 lg:container md:mb-auto"
    >
      <div class="hidden self-end md:flex">
        <ButtonsSemanticButton
          :id="'dcs--prev-arrow'"
          :text="''"
          :aria-label="'Previous'"
          :classes="'rounded-full w-3 h-3 shadow-1'"
          class="dcs--prev-arrow mx-1/2 active:scale-90 active:shadow-none"
          :disabled="previousButtonDisabled"
          :class="{
            '!opacity-100 !shadow-none': previousButtonDisabled,
          }"
          @click="watchButtonState($event)"
          ><IconsPreviousArrowIcon
            :dropshadow="true"
            class="pointer-events-none h-full w-full"
            :fill-color="!previousButtonDisabled ? '#0384FB' : '#ccc'"
          />
        </ButtonsSemanticButton>
        <ButtonsSemanticButton
          :id="'dcs--next-arrow'"
          :text="''"
          :aria-label="'Next'"
          :classes="'rounded-full w-3 h-3 shadow-1'"
          :class="{
            '!opacity-100 !shadow-none': nextButtonDisabled,
          }"
          class="dcs--next-arrow active:scale-90 active:shadow-none"
          :disabled="nextButtonDisabled"
          @click="watchButtonState($event)"
        >
          <IconsNextArrowIcon
            :dropshadow="true"
            class="pointer-events-none h-full w-full"
            :fill-color="!nextButtonDisabled ? '#0384FB' : '#ccc'"
          />
        </ButtonsSemanticButton>
      </div>
    </div>
    <div class="wrapper--swiper w-full px-gap pb-1 pr-0 lg:container lg:px-gap">
      <Swiper v-bind="options" class="!py-2" :modules="[SwiperNavigation]">
        <SwiperSlide v-for="(slide, index) in slides" :key="index">
          <div class="h-full px-1/2">
            <div
              class="block h-full text-center transition-all duration-100 ease-in-out"
            >
              <LinksLocaleLink
                :link="`${prefix(slide?.fields?.type || 'customers')}${
                  slide?.fields?.blogPageSlug || ''
                }`"
                class="relative flex h-auto flex-col overflow-hidden bg-white shadow-2"
                :aria-label="slide?.fields?.metaDescription || ''"
              >
                <div class="aspect--4x3 relative h-0 overflow-hidden">
                  <ImagesNuxtImg
                    :url="
                      slide?.fields?.backgroundImage?.fields?.file?.url || ''
                    "
                    class="absolute bottom-0 left-0 right-0 top-0 h-full w-full object-cover object-left"
                    :alt="
                      slide?.fields?.backgroundImage?.fields?.description || ''
                    "
                  />
                </div>
                <h3 class="my-1 pl-1 text-left">
                  {{ slide?.fields?.title || '' }}
                </h3>
              </LinksLocaleLink>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      carousel: {
        type: Object,
        required: true,
        default: {
          title: '',
          textAlignment: 'Left',
          labelFontSize: 'Default',
          labelsType: 'Bars',
          backgroundColor: 'White',
          layout: 'Two Columns',
          animated: true,
          navigationPlacement: 'Bottom Center',
          arrowNavigation: true,
        },
      },
      slides: {
        type: Array,
        required: true,
      },
      rotatorId: {
        type: Number,
        required: false,
        default: 1,
      },
      icon: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data() {
      return {
        options: {
          loop: false,
          navigation: {
            nextEl: `#dcs--next-arrow`,
            prevEl: `#dcs--prev-arrow`,
            disabledClass: 'swiper-button-disabled',
          },
          lazy: {
            loadPrevNext: true,
          },
          breakpoints: {
            0: {
              allowTouchMove: true,
              slidesPerView: 1.2,
              slidesPerGroup: 1,
            },
            400: {
              allowTouchMove: true,
              slidesPerView: 1.3,
              slidesPerGroup: 1,
            },
            500: {
              allowTouchMove: true,
              slidesPerView: 1.5,
              slidesPerGroup: 1,
            },
            600: {
              allowTouchMove: true,
              slidesPerView: 1.75,
              slidesPerGroup: 1,
            },
            736: {
              allowTouchMove: false,
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
          },
        },
        previousButtonDisabled: true,
        nextButtonDisabled: false,
      };
    },
    watch: {
      previousButtonDisabled(newValue, oldValue) {
        this.previousButtonDisabled = newValue;
      },
      nextButtonDisabled(newValue, oldValue) {
        this.nextButtonDisabled = newValue;
      },
    },
    methods: {
      prefix(value) {
        const dictionary = {
          'Blog Post': '/blog/',
          'Case Study': '/customers/',
          'Customer Tip': '/customers/tips/',
          'Guide Page': '/guides/',
          Webinar: '/webinars/',
        };

        return dictionary[value];
      },
      watchButtonState(el) {
        if (el.target.classList.contains('swiper-button-disabled')) {
          if (el.target.classList.contains('dcs--prev-arrow')) {
            this.previousButtonDisabled = true;
          }
          if (el.target.classList.contains('dcs--next-arrow')) {
            this.nextButtonDisabled = true;
          }
        } else {
          this.nextButtonDisabled = false;
          this.previousButtonDisabled = false;
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .swiper-slide {
    height: auto;
  }

  .wrapper--carousel {
    .swiper-container {
      min-height: 520px;
    }
  }
</style>
