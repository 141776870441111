<template>
  <div
    v-if="active"
    :class="{ fixed: sticky, relative: !sticky }"
    class="ams--banner flex h-auto w-full items-center justify-center overflow-hidden bg-blue-dark px-1 text-center md:h-[46px] md:px-3"
  >
    <p
      v-if="text"
      class="mb-0 py-1 pr-2 text-left font-medium text-white md:py-0 md:pr-0 md:text-center"
    >
      {{ text }}
      <LinksLocaleLink
        v-if="link !== undefined && linkText"
        class="border-b-1 border-white"
        :link="link"
        >{{ linkText }}</LinksLocaleLink
      >
    </p>
    <svg
      class="absolute right-0 mr-1/4 cursor-pointer hover:opacity-50"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="closeBanner()"
    >
      <rect
        x="17.501"
        y="5.47986"
        width="1.5"
        height="17"
        transform="rotate(45 17.501 5.47986)"
        fill="white"
      />
      <rect
        x="18.5615"
        y="17.5007"
        width="1.5"
        height="17"
        transform="rotate(135 18.5615 17.5007)"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        required: true,
        default: '',
      },
      linkText: {
        type: String,
        required: false,
        default: '',
      },
      sticky: {
        type: Boolean,
        required: true,
        default: true,
      },
      link: {
        type: String,
        required: false,
        default: '',
      },
    },
    emits: ['bannerExists'],
    setup() {
      const active = ref(true);

      return {
        active,
      };
    },
    data() {
      return {
        content: '',
        loading: true,
      };
    },
    methods: {
      closeBanner() {
        this.$emit('bannerExists', false);
        this.active = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  p {
    font-size: 14px;
  }
  svg {
    @media (max-width: theme('screens.md')) {
      margin-top: 4px;
    }
  }
  .ams--banner {
    :deep() {
      a {
        @apply border-b-1 border-white;

        &:hover {
          color: rgba(#fff, 0.5);
          border-bottom: 1px solid rgba(#fff, 0.5);
        }
      }
    }
  }
</style>
