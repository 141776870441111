import debounce from 'lodash.debounce';
import robots from '~/constants/bots-list';

let isPageTracked = false; // Flag to prevent duplicate tracking

async function analyticsPage(customProperties = {}, silent = false) {
  const { isProduction } = useRuntimeConfig().public;
  const { $emitter } = useNuxtApp();
  const analytics = window.analytics || {};

  if (analytics.initialized && !isPageTracked) {
    try {
      const route = useRoute();
      const { isMobile } = useDevice();
      const queryParameters = route.query;
      const userAgent = window.navigator.userAgent || '';
      const { history } = useRouter().options;

      const baseProperties = {
        cid: queryParameters.cid || '',
        gclid: queryParameters.gclid || '',
        keyword: queryParameters.keyword || '',
        msclkid: queryParameters.msclkid || '',
        utm_ext_ad_id: queryParameters.utm_ext_ad_id || '',
        utm_ext_adset_id: queryParameters.utm_ext_adset_id || '',
        utm_ext_campaign_id: queryParameters.utm_ext_campaign_id || '',
        is_mobile: isMobile,
        user_agent_mp: userAgent,
        previous_page_url: history.state.back || '',
        app: 'horizon',
        session_id: sessionStorage.getItem('_session_id') || '',
      };

      const properties = { ...baseProperties, ...customProperties };

      const isRobot = robots.some((robot) =>
        userAgent.toLowerCase().includes(robot)
      );

      if (!isRobot) {
        // Track only if this page has not been tracked
        await analytics.page(properties);

        await analytics.track('page viewed', {
          ...properties,
          'page name': document.title,
          url: route.fullPath,
        });

        // Set the flag to true to avoid duplicate tracking
        isPageTracked = true;
        ($emitter as { emit: (event: string, payload: boolean) => void }).emit(
          'pageTracked',
          true
        );
      }
    } catch (error) {
      ($emitter as { emit: (event: string, payload: boolean) => void }).emit(
        'pageTracked',
        false
      );
      if (isProduction && !silent) {
        report(error);
      }
    }
  } else {
    ($emitter as { emit: (event: string, payload: boolean) => void }).emit(
      'pageTracked',
      false
    );
  }
}

const debouncedAnalyticsPage = debounce(analyticsPage, 300); // 300ms debounce

export default defineNuxtPlugin((nuxtApp) => {
  // Reset the tracking flag when a new page starts loading
  nuxtApp.hook('page:loading:start', () => {
    isPageTracked = false;
  });

  nuxtApp.hook('page:loading:end', () => {
    debouncedAnalyticsPage();
  });
});
