<template>
  <section class="carousel--dash-cam">
    <div class="w-full px-gap2 md:container">
      <div class="row -mx-gap flex flex-wrap justify-center text-center">
        <div class="col w-12/12 lg:w-11/12">
          <div class="labels mb-2">
            <div
              v-for="(slide, index) in processSlides"
              :key="index"
              class="labels__label"
              :class="{ active: index == realIndex }"
              @click.prevent="goToSlide(index)"
            >
              <h5>
                {{ getNestedProperty(slide, 'fields.label', false) }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel__wrapper">
      <div class="nav--dash-cam hidden lg:flex">
        <div class="nav--prev hidden md:block">
          <svg
            width="60"
            height="61"
            viewBox="0 0 60 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="30"
              cy="30.5"
              r="30"
              transform="rotate(-180 30 30.5)"
              fill="#0384FB"
            />
            <path
              d="M27.9308 40.8447L17.5859 30.4999L27.9308 20.1551"
              stroke="white"
              stroke-width="5"
            />
            <path
              d="M17.5856 30.5L45.5166 30.5"
              stroke="white"
              stroke-width="5"
            />
          </svg>
        </div>
        <div class="nav--next hidden md:block">
          <svg
            width="60"
            height="61"
            viewBox="0 0 60 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="30" cy="30.5" r="30" fill="#0384FB" />
            <path
              d="M32.0691 20.1553L42.4139 30.5001L32.0691 40.8449"
              stroke="white"
              stroke-width="5"
            />
            <path d="M42.4143 30.5H14.4833" stroke="white" stroke-width="5" />
          </svg>
        </div>
      </div>
      <div class="container">
        <div class="-mx-gap flex flex-wrap justify-center text-center">
          <div
            class="column w-12/12 overflow-hidden bg-blue-dark text-left lg:w-10/12 lg:w-11/12"
          >
            <div class="container__main-carousel flex flex-wrap">
              <div
                class="column carousel--dash-cont owl-carousel w-12/12 pb-0 pl-3 pr-2 pt-2 text-white md:pl-1 lg:w-4/12 lg:pb-2"
              >
                <Swiper
                  ref="dashContRotate"
                  class="h-full"
                  v-bind="swiperOptions"
                  :modules="[SwiperNavigation]"
                  @slideChange="setIndex"
                  @swiper="onContRotate"
                >
                  <SwiperSlide
                    v-for="(slide, index) in processSlides"
                    :key="index"
                  >
                    <div
                      class="main-carousel__slide-text lg:flex lg:h-full lg:flex-col lg:items-start"
                      :data-dot="`<h5>${slide.label}</h5>`"
                    >
                      <RichTextRenderer
                        v-if="slide.fields.content"
                        :document="slide.fields.content"
                        :node-renderers="nodeRenderers"
                      />
                      <a
                        v-if="slide.fields.link"
                        class="btn--audio cursor-pointer md:mb-1 lg:mb-0"
                        @click.prevent="
                          playAudio(
                            slide.fields.label.replace(/ /g, '').toLowerCase()
                          )
                        "
                      >
                        {{ modules.json['button_label'] }}</a
                      >
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div
                class="column carousel--dash-video owl-carousel w-12/12 lg:w-8/12"
              >
                <Swiper
                  ref="videoMain"
                  class="h-full"
                  v-bind="swiperOptions"
                  :modules="[SwiperNavigation]"
                  @swiper="onVideoMain"
                >
                  <SwiperSlide
                    v-for="(slide, index) in processSlides"
                    :key="index"
                  >
                    <div>
                      <VideosTheVideo
                        v-if="
                          slide.fields.image.sys.contentType.sys.id === 'video'
                        "
                        :state="'play'"
                        :video="slide.fields.image.fields"
                        :lazy="true"
                      />
                      <ImagesPicture
                        v-else
                        :image="slide.fields.image.fields"
                        :lazy="true"
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
          <div
            class="column nav--next-dash owl-carousel hidden w-12/12 px-gap lg:block"
          >
            <Swiper
              ref="dashNext"
              class="h-full"
              v-bind="swiperOptionsNext"
              :modules="[SwiperNavigation]"
              @swiper="onDashNext"
            >
              <SwiperSlide v-for="(slide, index) in processSlides" :key="index">
                <video
                  v-if="
                    slide.fields.image.sys.contentType.sys.id === 'video' &&
                    slide.fields.image.fields.video.fields.file
                  "
                  ref="video"
                  muted
                  preload="metadata"
                  :lazy="false"
                >
                  <source
                    :src="`${slide.fields.image.fields.video.fields.file.url}#t=0.5`"
                  />
                </video>
                <ImagesPicture
                  v-else
                  :image="slide.fields.image.fields"
                  :lazy="true"
                />
              </SwiperSlide>
            </Swiper>
          </div>
          <div
            class="column nav--prev-dash owl-carousel hidden w-12/12 px-gap lg:block"
          >
            <Swiper
              ref="dashPrev"
              class="h-full"
              v-bind="swiperOptionsPrev"
              :modules="[SwiperNavigation]"
              @swiper="onDashPrev"
            >
              <SwiperSlide v-for="(slide, index) in processSlides" :key="index">
                <video
                  v-if="
                    slide.fields.image.sys.contentType.sys.id === 'video' &&
                    slide.fields.image.fields.video.fields.file
                  "
                  ref="video"
                  muted
                  preload="metadata"
                  :lazy="false"
                >
                  <source
                    :src="`${slide.fields.image.fields.video.fields.file.url}#t=0.5`"
                  />
                </video>
                <ImagesPicture
                  v-else
                  :image="slide.fields.image.fields"
                  :lazy="true"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
    <audio
      v-for="(slide, index) in processSlides"
      :key="slide.fields.label"
      :ref="slide.fields.label.replace(/ /g, '').toLowerCase()"
      :src="slide.fields.link"
      preload="none"
      autoplay="false"
    ></audio>
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { getNestedProperty } from '~/utils';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: ['modules'],
    setup(props) {
      const realIndex = ref(0);
      const dashContInstance = ref();
      const videoMainInstance = ref();
      const dashNextInstance = ref();
      const dashPrevInstance = ref();
      const setIndex = (swiper) => {
        realIndex.value = swiper.realIndex;
      };
      const onContRotate = (swiper) => {
        dashContInstance.value = swiper;
      };
      const onVideoMain = (swiper) => {
        videoMainInstance.value = swiper;
      };
      const onDashNext = (swiper) => {
        dashNextInstance.value = swiper;
      };
      const onDashPrev = (swiper) => {
        dashPrevInstance.value = swiper;
      };

      const processSlides = computed(() => {
        return props.modules.slides.filter(
          (slide) => slide.fields.isVisible !== false
        );
      });
      return {
        setIndex,
        realIndex,
        onContRotate,
        onVideoMain,
        onDashNext,
        onDashPrev,
        dashContInstance,
        videoMainInstance,
        dashNextInstance,
        dashPrevInstance,
        processSlides,
      };
    },
    data() {
      return {
        selectedIndex: 0,
        swiperOptions: {
          loop: true,
          navigation: {
            nextEl: '.nav--next',
            prevEl: '.nav--prev',
          },
          preloadImages: true,
          slidesPerView: 1,
          allowTouchMove: false,
        },
        swiperOptionsPrev: {
          loop: true,
          navigation: {
            nextEl: '.nav--next',
            prevEl: '.nav--prev',
          },
          preloadImages: true,
          slidesPerView: 1,
          allowTouchMove: false,
          initialSlide: this.processSlides.length,
        },
        swiperOptionsNext: {
          loop: true,
          navigation: {
            nextEl: '.nav--next',
            prevEl: '.nav--prev',
          },
          preloadImages: true,
          slidesPerView: 1,
          allowTouchMove: false,
          initialSlide: 1,
        },
      };
    },
    mounted() {
      this.pauseVideos(this.$refs.video);
    },
    methods: {
      pauseVideos(videos) {
        if (videos) {
          videos.forEach((video) => {
            video.pause();
          });
        }
      },
      playAudio(id) {
        const audio = this.$refs[id][0];
        audio.setAttribute('src', audio.getAttribute('data-src'));
        this.$refs[id][0].play();
      },
      goToSlide(index) {
        this.realIndex = index;
        this.dashContInstance.slideToLoop(index);
        this.videoMainInstance.slideToLoop(index);
        this.dashNextInstance.slideToLoop(
          index + 1 < this.processSlides.length ? index + 1 : 0
        );
        this.dashPrevInstance.slideToLoop(
          index - 1 < 0 ? this.processSlides.length - 1 : index - 1
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .carousel--dash-cam {
    @apply relative overflow-hidden;
    .carousel__wrapper {
      @apply relative;
      .container {
        @apply relative;
        @media (max-width: theme('screens.md')) {
          @apply p-0;
        }
        & > .row {
          @media (max-width: theme('screens.md')) {
            @apply p-0;
          }
        }
      }
    }
    .nav--dash-cam {
      @apply absolute flex;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      width: 100vw;
    }
    .nav--next-dash,
    .nav--prev-dash {
      @apply absolute top-0 h-full;
      opacity: 0.3;
      .slide--wrapper-outer,
      .slide--wrapper,
      .slide--dash-cam,
      video,
      :deep(img) {
        @apply h-full;
      }
      video,
      :deep(img) {
        @apply block;
      }
    }
    .nav--prev,
    .nav--next {
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      cursor: pointer;
    }
    .nav--prev {
      left: 0;
      margin-left: 20px;
    }
    .nav--next {
      right: 0;
      margin-right: 20px;
    }
    .nav--next-dash {
      @media (min-width: theme('screens.md')) {
        left: 91.6665%;
      }
      @media (min-width: theme('screens.lg')) {
        left: 95.8335%;
      }
      @media (max-width: theme('screens.md')) {
        @apply hidden;
      }
    }
    .nav--prev-dash {
      @media (min-width: theme('screens.md')) {
        right: 91.6665%;
      }
      @media (min-width: theme('screens.lg')) {
        right: 95.8335%;
      }
      @media (max-width: theme('screens.md')) {
        @apply hidden;
      }
      video,
      :deep(img) {
        @apply float-right;
      }
    }
    .labels {
      @apply flex justify-evenly;
      @media (max-width: theme('screens.md')) {
        @apply flex-wrap justify-between;
      }
      .labels__label {
        @apply cursor-pointer;
        border-bottom: 2px solid transparent;
        transition: 0.2s ease-in-out;
        @media (max-width: theme('screens.md')) {
          margin-bottom: 20px;
        }
        h5 {
          @apply mb-0;
        }
        &:hover {
          border-bottom: 2px solid theme('colors.blue.DEFAULT');
        }
        &.active {
          border-bottom: 2px solid theme('colors.blue.DEFAULT');
        }
      }
    }
    .container__main-carousel {
      @apply relative;
      @media (max-width: theme('screens.md')) {
        @apply m-0;
      }
      .col {
        @apply p-0;
      }
    }
    .main-carousel__slide-text {
      padding-bottom: 2px;
    }
    .btn--audio {
      @apply mt-auto inline-block font-medium uppercase text-white no-underline;
      font-size: 13px;
      letter-spacing: 1px;
      border: 1px solid #fff;
      position: relative;
      padding: 7px 17px 7px 35px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-radius: 5px;
      &::before {
        @apply absolute;
        content: '';
        height: 18px;
        width: 18px;
        left: 7px;
        top: 9px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.73408 6.5095H0V10.7833H2.73408L6.84 13.8769V3.41528L2.73408 6.5095Z' fill='white'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.35713 12.6637L8.68652 12.0201C10.3735 10.4004 10.3735 7.76544 8.68652 6.14572L9.35713 5.50269C11.4146 7.47682 11.4146 10.689 9.35713 12.6637Z' fill='white'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.9362 15.1384L11.2656 14.4947C12.7717 13.0492 13.601 11.1267 13.601 9.0826C13.601 7.03854 12.7717 5.11656 11.2656 3.67107L11.9362 3.02686C13.6214 4.6448 14.5495 6.79555 14.5495 9.0826C14.5495 11.3703 13.6214 13.521 11.9362 15.1384Z' fill='white'/%3e%3cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='13' y='0' width='6' height='18'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.8438 0.55249H18.1959V17.6132H13.8438V0.55249Z' fill='white'/%3e%3c/mask%3e%3cg mask='url(%23mask0)'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.5144 17.6133L13.8438 16.9696C16.0383 14.8627 17.2474 12.0619 17.2474 9.08257C17.2474 6.10389 16.0383 3.303 13.8438 1.19611L14.5144 0.55249C16.8886 2.83125 18.1959 5.86031 18.1959 9.08257C18.1959 12.3048 16.8886 15.3345 14.5144 17.6133Z' fill='white'/%3e%3c/g%3e%3c/svg%3e ");
      }
      &:hover {
        background: theme('colors.blue.DEFAULT');
        border: 1px solid theme('colors.blue.DEFAULT');
      }
    }
    .carousel--dash-cont {
      :deep(h2),
      :deep(p) {
        color: #fff;
      }
      @media (min-width: theme('screens.md')) {
        .slide--dash-cam > div {
          @apply flex flex-col items-start;
        }
        .slide--wrapper-outer,
        .slide--wrapper,
        .slide--dash-cam,
        .slide--dash-cam > div {
          @apply h-full;
        }
      }
    }
    .carousel--dash-video {
      video,
      :deep(img) {
        @apply block;
      }
    }
  }
</style>
