<template>
  <FormKit
    type="text"
    ref="name"
    :label="props.label"
    :name="props.name"
    label-class="label-asterick font-bold mb-[5px]"
    :input-class="`w-full focus:outline-0 rounded-md p-[5px] ${validState === ValidationState.VALID ? '!outline-green' : validState === ValidationState.INVALID ? '!outline-red' : ''}`"
    message-class="text-red mt-[4px] text-xs block"
    messages-class="!ml-0"
    :validation-messages="{
      required: props.errorMessages,
      matches: 'Please enter both first and last name',
    }"
    :validation="[['required'], ['matches', /^.+ .+/]]"
  >
    <template #suffix="{ value, state }">
      <IconsGreenCheckmark
        v-if="state.valid && !state.validating && state.blurred"
        class="absolute right-[15px] top-[15px]"
      />
      <FormsProcessingCheck
        v-else-if="state.validating && state.blurred"
        class="absolute right-[10px] top-[10px]"
      />
      <FormsInvalidCheck
        v-else-if="!state.valid && !state.validating && state.blurred"
        class="absolute right-[15px] top-[15px] h-auto w-auto"
      />
    </template>
  </FormKit>
</template>

<script setup>
  import { useGetValidationState } from '~/composables/formkit-valid-state';
  import { ValidationState } from '~/types/enums/validation-state';

  const props = defineProps({
    errorMessages: {
      type: String,
      required: false,
      default: 'Name is required',
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  });

  const name = ref(null);
  const validState = useGetValidationState(name);
  const { isProduction } = useRuntimeConfig().public;
  const { locale } = useI18n();
  const { fullPath } = useRoute();
</script>

<style scss>
  .label-asterick::before {
    content: '*';
    color: theme('colors.red.DEFAULT');
    padding-right: 5px;
  }
</style>
