<template>
  <div>
    <p
      class="p--subhead mb-0 cursor-pointer"
      @click.prevent="openModal(arrowlink.linkUrl)"
    >
      {{ arrowlink.text }}
      <span>
        <svg
          class="icon inline-block"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12.5" cy="12.5" r="12.5" fill="white" />
          <path
            d="M17 12.5L10.25 16.3971L10.25 8.60289L17 12.5Z"
            fill="#0384FB"
          />
        </svg>
      </span>
    </p>
  </div>
</template>

<script>
  import { fadeIn } from '~/utils/html-helpers';

  export default {
    props: {
      arrowlink: {
        type: Object,
        required: true,
      },
    },
    methods: {
      openModal(id) {
        const modal = document.querySelector(`#${id}`);
        fadeIn(modal, 'block');
        document.querySelector('html').classList.add('overflow-y-hidden');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    width: 21px;
    height: 21px;
    margin-left: 4px;
    &:hover {
      transform-origin: center;
      transition: transform 300ms ease;
      transform: scale(1.1);
    }
  }

  .p--subhead {
    color: #fff;
    margin: 0;
    padding: 0 20px 20px 20px;
    font-size: 16px;
    font-weight: 700;
  }
</style>
