export enum NavigationDirection {
  UP = 'UP',
  DOWN = 'DOWN',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
  UNSET = 'UNSET',
}

export enum ResourceTypes {
  WEBINARS = 'webinars',
}

export enum BlogCategories {
  LIFE_AT_SAMSARA = 'Life At Samsara',
}
