<template>
  <div class="el--external-link-icon relative w-[12px] h-[12px]">
    <div
      class="absolute w-[2px] h-full bg-blue-dark left-0 bottom-0 rounded-full el--border-left transition-all origin-bottom"
    ></div>
    <div
      class="absolute w-full h-[2px] bg-blue-dark left-0 bottom-0 rounded-full el--border-bottom transition-all origin-left"
    ></div>
    <div
      class="absolute w-[8px] h-[2px] bg-blue-dark right-0 top-0 rounded-full transition-all el--arrow"
    ></div>
    <div
      class="absolute w-[2px] h-[8px] bg-blue-dark right-0 top-0 rounded-full transition-all el--arrow"
    ></div>
    <div
      class="absolute w-[2px] h-[8px] bg-blue-dark right-0 top-0 rounded-full rotate-45 origin-top-left transition-all el--arrowbody"
    ></div>
  </div>
</template>
