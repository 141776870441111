<template>
  <div
    v-show="isOpen || modalIsOpen"
    ref="modal"
    class="modal z-100 modal--row"
    @keyup.esc="$emit('close'), closeModal()"
  >
    <span
      v-if="closeLocation === 'outer'"
      class="modular-modal-close absolute right-[20px] top-[20px] z-[120]"
      :class="{ outer: closeLocation === 'outer' }"
      @click.prevent="$emit('close'), closeModal()"
      @keydown.enter="$emit('close'), closeModal()"
    >
      <IconsSvgCloseIcon :stroke="closeStroke" />
    </span>
    <div
      ref="modalInner"
      class="modal__inner relative z-30 overflow-y-auto overflow-x-hidden"
      :class="bg ? bg : ''"
    >
      <span
        v-if="closeLocation === 'inner' || !closeLocation"
        class="modular-modal-close absolute right-[20px] top-[20px] z-[120]"
        @click.prevent="$emit('close'), closeModal()"
        @keydown.enter="$emit('close'), closeModal()"
      >
        <IconsSvgCloseIcon :stroke="'black'" />
      </span>
      <slot></slot>
    </div>
    <div
      v-if="isOpen || modalIsOpen"
      class="absolute left-0 top-0 z-10 h-screen w-screen"
      @click.prevent="$emit('close')"
      @keydown.enter="$emit('close')"
    ></div>
  </div>
</template>
<script>
  export default {
    props: {
      isOpen: {
        type: Boolean,
        required: false,
        default: false,
      },
      closeLocation: {
        type: String,
        required: false,
        default: 'inner',
      },
      bg: {
        type: String,
        required: false,
        default: null,
      },
      closeStroke: {
        type: String,
        required: false,
        default: 'white',
      },
    },
    emits: ['close'],
    data() {
      return {
        previousFocus: null,
        focusableContent: null,
        focusableElements: 'button, a',
        modalIsOpen: false,
      };
    },
    watch: {
      isOpen() {
        if (this.isOpen === false) {
          window.removeEventListener('keyup', this.onEscapeKeyUp);
          document.removeEventListener('keydown', this.keyDown);
          document.querySelector('html').classList.remove('overflow-y-hidden');
          this.modalIsOpen = false;
        } else {
          document.querySelector('html').classList.add('overflow-y-hidden');
          this.modalIsOpen = true;
        }
      },
    },
    methods: {
      closeModal() {
        document.removeEventListener('keydown', this.keyDown);
        this.modalIsOpen = false;
      },
      onEscapeKeyUp(event) {
        if (event.which === 27) {
          this.$emit('close');
          this.modalIsOpen = false;
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal {
    display: block;
    .bg-blue-dark {
      .modular-modal-close {
        :deep() {
          svg {
            line {
              @apply stroke-white;
            }
          }
        }
      }
    }
  }
  .modular-modal-close.outer {
    @screen md {
      @apply right-[10vw] top-[5vh];
      &:hover {
        @apply scale-105;
      }
    }
  }
</style>
