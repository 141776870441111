import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["AnnouncementBannerFooter","ArticleList","BlogNav","CardWrapperAppMarketplace","CardWrapperExpertMarketplace","CardWrapperBlogPost","CareersNavigation","CaseStudyPostsTags","CaseStudyPosts","FooterNavigation","FooterTermsNavigation","GeolocationBanner","GlobalNavigation","GuideCardSection","RelatedStoriesSlider","StickyFooter","WebinarPosts","CorpWebDB","BlogCategoryRoutes","BlogCategory","BlogIndex","BlogRoutes","CompanyNews","CustomersCategory","CustomersTips","EventRoutes","FleetRoutes","GuidesYourPartner","HomepageRoute","IntegrationRoutes","MarketplaceCategoryRoutes","ModularRoutes","ProductPageRoutes","ResourcesExperts","ResourcesMarketplaceSearch","ResourcesMarketplace","ResourcesVideos","SupportRoutes","WebinarsIndex","BlogPostFilter","BlogPostOrder","SamsaraIntegrationsFilter"]}
export const GqlAnnouncementBannerFooter = (...params) => useGql()('AnnouncementBannerFooter', ...params)
export const GqlArticleList = (...params) => useGql()('ArticleList', ...params)
export const GqlBlogCategory = (...params) => useGql()('BlogCategory', ...params)
export const GqlBlogCategoryRoutes = (...params) => useGql()('BlogCategoryRoutes', ...params)
export const GqlBlogIndex = (...params) => useGql()('BlogIndex', ...params)
export const GqlBlogNav = (...params) => useGql()('BlogNav', ...params)
export const GqlBlogRoutes = (...params) => useGql()('BlogRoutes', ...params)
export const GqlCardWrapperAppMarketplace = (...params) => useGql()('CardWrapperAppMarketplace', ...params)
export const GqlCardWrapperBlogPost = (...params) => useGql()('CardWrapperBlogPost', ...params)
export const GqlCardWrapperExpertMarketplace = (...params) => useGql()('CardWrapperExpertMarketplace', ...params)
export const GqlCareersNavigation = (...params) => useGql()('CareersNavigation', ...params)
export const GqlCaseStudyPosts = (...params) => useGql()('CaseStudyPosts', ...params)
export const GqlCaseStudyPostsTags = (...params) => useGql()('CaseStudyPostsTags', ...params)
export const GqlCompanyNews = (...params) => useGql()('CompanyNews', ...params)
export const GqlCorpWebDB = (...params) => useGql()('CorpWebDB', ...params)
export const GqlCustomersCategory = (...params) => useGql()('CustomersCategory', ...params)
export const GqlCustomersTips = (...params) => useGql()('CustomersTips', ...params)
export const GqlEventRoutes = (...params) => useGql()('EventRoutes', ...params)
export const GqlFleetRoutes = (...params) => useGql()('FleetRoutes', ...params)
export const GqlFooterNavigation = (...params) => useGql()('FooterNavigation', ...params)
export const GqlFooterTermsNavigation = (...params) => useGql()('FooterTermsNavigation', ...params)
export const GqlGeolocationBanner = (...params) => useGql()('GeolocationBanner', ...params)
export const GqlGlobalNavigation = (...params) => useGql()('GlobalNavigation', ...params)
export const GqlGuideCardSection = (...params) => useGql()('GuideCardSection', ...params)
export const GqlGuidesYourPartner = (...params) => useGql()('GuidesYourPartner', ...params)
export const GqlHomepageRoute = (...params) => useGql()('HomepageRoute', ...params)
export const GqlIntegrationRoutes = (...params) => useGql()('IntegrationRoutes', ...params)
export const GqlMarketplaceCategoryRoutes = (...params) => useGql()('MarketplaceCategoryRoutes', ...params)
export const GqlModularRoutes = (...params) => useGql()('ModularRoutes', ...params)
export const GqlProductPageRoutes = (...params) => useGql()('ProductPageRoutes', ...params)
export const GqlRelatedStoriesSlider = (...params) => useGql()('RelatedStoriesSlider', ...params)
export const GqlResourcesExperts = (...params) => useGql()('ResourcesExperts', ...params)
export const GqlResourcesMarketplace = (...params) => useGql()('ResourcesMarketplace', ...params)
export const GqlResourcesMarketplaceSearch = (...params) => useGql()('ResourcesMarketplaceSearch', ...params)
export const GqlResourcesVideos = (...params) => useGql()('ResourcesVideos', ...params)
export const GqlStickyFooter = (...params) => useGql()('StickyFooter', ...params)
export const GqlSupportRoutes = (...params) => useGql()('SupportRoutes', ...params)
export const GqlWebinarPosts = (...params) => useGql()('WebinarPosts', ...params)
export const GqlWebinarsIndex = (...params) => useGql()('WebinarsIndex', ...params)