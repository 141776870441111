export const report = (error: unknown) => {
  const { isSandbox, isStaging } = useRuntimeConfig().public;

  if (isSandbox || isStaging) {
    if (error instanceof Error) {
      console.error(`[ERROR] ${error.message}`);
    }
  } else {
    // here we could send to Sentry
    // sentry.captureException(event);
  }
};
