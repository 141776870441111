<template>
  <NuxtImg
    v-if="queryParams === '' && url"
    :src="url"
    :style="styles"
    :class="imageClasses"
    :alt="alt"
    :preload="!!preload"
    :sizes="height ? null : 'xl:100vw lg:100vw md:100vw sm:100vw xs:100vw'"
    :width="width !== '' ? width : naturalWidth ? naturalWidth : ''"
    :height="height !== '' ? height : naturalHeight ? naturalHeight : ''"
    :modifiers="{ fit: `${modifier}` }"
    :provider="provider"
    :loading="nuxtImgLoadingType"
    :format="format"
    @load="$emit('update:imageLoaded')"
  />
  <img
    v-else-if="url"
    :src="url + queryParams"
    :style="styles"
    :class="imageClasses"
    :alt="alt"
    :loading="lazy ? 'lazy' : 'eager'"
  />
</template>

<script>
  import { inject } from 'vue';

  export default {
    props: {
      image: {
        type: Object,
        required: false,
        default: null,
      },
      preload: {
        type: Boolean,
        required: false,
        default: false,
      },
      classes: {
        type: String,
        required: false,
        default: '',
      },
      isInline: {
        type: Boolean,
        required: false,
        default: false,
      },
      queryParams: {
        type: String,
        required: false,
        default: '',
      },
      alt: {
        type: [String, Object],
        required: false,
        default: '',
      },
      lazy: {
        type: Boolean,
        required: false,
        default: true,
      },
      styles: {
        type: String,
        required: false,
        default: null,
      },
      url: {
        type: String,
        required: true,
      },
      modifier: {
        type: String,
        required: false,
        default: '',
      },
      provider: {
        type: String,
        required: false,
        default: 'contentful',
      },
      naturalWidth: {
        type: String,
        required: false,
        default: null,
      },
      naturalHeight: {
        type: String,
        required: false,
        default: null,
      },
    },
    setup(props) {
      const { isSandbox, isStaging } = useRuntimeConfig().public;

      if (props.url == null || props.url === '') {
        if (isSandbox || isStaging) {
          console.warn(
            `NuxtImg needs an image url to render, double check that an image has been added to your content`
          );
        }
      }

      const format =
        props.url.includes('.png') ||
        props.url.includes('.jpg') ||
        props.url.includes('.jpeg')
          ? 'webp'
          : undefined;

      const rowStyle = inject('modularRow:rowStyle', undefined);

      return {
        format,
        rowStyle,
      };
    },
    computed: {
      nuxtImgLoadingType() {
        let loadingType = '';
        if (this.rowStyle !== undefined && this.rowStyle === 'Hero Top') {
          loadingType = 'eager';
        } else {
          loadingType = this.lazy ? 'lazy' : 'eager';
        }

        return loadingType;
      },
      imageClasses() {
        return this.classes
          ? this.classes
          : this.isInline
            ? 'inline-block align-middle'
            : 'inline-block align-bottom';
      },
      width() {
        return this.image !== null &&
          this.image.width !== undefined &&
          this.image.width !== null
          ? this.image.width
          : '';
      },
      height() {
        return this.image !== null &&
          this.image.height !== undefined &&
          this.image.height !== null
          ? this.image.height
          : '';
      },
    },
    watch: {
      url: {
        handler(newVal) {
          return this.processUrl(newVal);
        },
      },
      alt: {
        handler(newVal) {
          return this.processAlt(newVal);
        },
      },
    },
    methods: {
      processAlt(alt) {
        if (alt === null) {
          return null;
        }

        if (alt === undefined) {
          return '';
        }

        if (alt !== undefined || alt !== null) {
          return alt;
        }
      },
      processUrl(url) {
        return url.replace('//images.ctfassets.net/', '');
      },
    },
  };
</script>
