<template>
  <button
    :data-id="props.button?.id"
    class="uppercase"
    :class="[
      buttonClasses,
      { 'flex flex-row justify-between gap-1': iconComponent },
    ]"
    @click="buttonAction"
  >
    <component
      :is="iconComponent"
      v-if="
        iconComponent && props.button?.iconPosition === IconPosition.BEFORE_TEXT
      "
    />
    {{ props.button?.text }}
    <component
      :is="iconComponent"
      v-if="
        iconComponent && props.button?.iconPosition === IconPosition.AFTER_TEXT
      "
    />
  </button>
</template>

<script setup lang="ts">
  import {
    ButtonAction,
    Icon,
    IconPosition,
    ButtonVariant,
  } from '~/types/enums/button';
  import { scrollIntoView } from '~/utils';

  const props = defineProps({
    button: {
      type: Object,
      required: true,
    },
  });

  const playAudio = () => {
    const audio = new Audio(props.button?.sourceUrl ?? '');
    audio.play();
  };

  const scrollToHash = () => {
    if (props.button?.id && document.getElementById(props.button?.id)) {
      const element = document.getElementById(props.button.id);
      if (element) {
        scrollIntoView(element, 'smooth', 'start');
      }
    }
  };

  const actionMap = new Map([
    [ButtonAction.PLAY_AUDIO, playAudio],
    [ButtonAction.JUMP_LINK, scrollToHash],
  ]);

  const iconMap = new Map([
    [Icon.AUDIO, defineAsyncComponent(() => import('../icons/SvgAudio.vue'))],
  ]);

  const iconComponent = computed(() => iconMap.get(props.button?.icon as Icon));

  const buttonClasses = computed(() => {
    let variantClasses;

    switch (props.button?.variant) {
      case ButtonVariant.PRIMARY_BLUE:
        variantClasses = 'btn btn--blue btn--blue:hover';
        break;
      default:
        variantClasses = '';
    }

    return props.button?.classes
      ? variantClasses + ' ' + props.button?.classes.join(' ')
      : variantClasses;
  });

  const buttonAction = computed(() => {
    return actionMap.get(props.button?.action as ButtonAction);
  });
</script>
