<template>
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="button-icon--solid"
  >
    <rect width="4" height="16" :fill="fill" />
    <rect x="8" width="4" height="16" :fill="fill" />
  </svg>
</template>
<script>
  export default {
    props: {
      fill: {
        type: String,
        required: false,
        default: '#00263E',
      },
    },
  };
</script>
