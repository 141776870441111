<template>
  <div
    class="card relative flex cursor-pointer flex-col bg-white shadow-lg transition-all duration-100 ease-in-out"
    @click.prevent="openModal(content.link)"
  >
    <slot></slot>
  </div>
</template>

<script>
  import { fadeIn } from '~/utils/html-helpers';

  export default {
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
    methods: {
      openModal(id) {
        const modal = document.querySelector(`#${id}`);
        fadeIn(modal, 'block');
        document.querySelector('html').classList.add('overflow-y-hidden');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card {
    &:hover {
      @apply shadow-xl;

      transform: scale(1.05);
    }
  }
  .card :deep(p) {
    @apply mb-0;
  }
  .card :deep(h3) {
    @apply mb-0;
  }
</style>
