<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
  >
    <path
      d="M12 1L1 12"
      :stroke="stroke"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M12 12L1 1"
      :stroke="stroke"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
<script>
  export default {
    props: {
      stroke: {
        type: String,
        required: true,
        default: 'white',
      },
    },
  };
</script>
