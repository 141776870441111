<template>
  <section
    class="carousel carousel--vertical w-full p-0 pt-4 lg:container md:px-gap2 md:pt-5"
  >
    <div class="-mx-gap flex flex-wrap">
      <div
        class="column relative mb-3 w-12/12 px-gap md:mb-0 md:w-10/12 lg:w-8/12"
        style="text-align: left"
      >
        <div v-if="document" class="wrapper px-gap2 md:px-0">
          <RichTextRenderer
            :document="document"
            :node-renderers="nodeRenderers"
          />
        </div>
        <div
          v-if="carousel.slides"
          class="-mx-gap mt-1 hidden flex-wrap md:flex lg:mt-4"
        >
          <div class="column w-12/12 px-gap md:w-4/12">
            <div class="location-indicator">
              <ul ref="nav" class="list list--bullets">
                <div
                  ref="navBorderRight"
                  class="absolute left-[7px] top-[12px] h-[100%] w-[1px] bg-[#caced4]"
                ></div>
                <li
                  v-for="(slide, index) in carousel.slides"
                  :id="`slide-impact${index + slide.sys.id}`"
                  ref="navItems"
                  :key="index + slide.sys.id"
                  class="bullet--label relative mb-1 last:mb-0"
                  :class="{
                    active: clickIndex === `${index + slide.sys.id}`,
                  }"
                >
                  <span
                    @click="slideTo(index, slide.sys.id)"
                    v-if="slide.fields.label"
                    class="inline-block cursor-pointer pl-2 text-sm font-semibold uppercase tracking-wide text-gray-dark opacity-25"
                    >{{ slide.fields.label }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="column w-12/12 px-gap md:w-8/12 lg:ml-2 lg:w-7/12">
            <Swiper
              v-if="verticalOptions"
              v-bind="verticalOptions"
              class=""
              :modules="[
                SwiperNavigation,
                SwiperMousewheel,
                SwiperController,
                SwiperHashNavigation,
              ]"
              @slideChange="setIndex"
              :controller="{ control: controlledSwiper }"
              @swiper="onSwiperInit"
            >
              <SwiperSlide
                v-for="(slide, index) in carousel.slides"
                :key="index + slide.sys.id"
                :data-hash="`impact${index + slide.sys.id}`"
              >
                <div class="carousel__slide" :class="getLinkStyles(slide)">
                  <RichTextRenderer
                    v-if="slide.fields.content"
                    :document="slide.fields.content"
                    :node-renderers="nodeRenderers"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <div
          class="carousel--vertical-image absolute top-0 m-0 hidden h-full w-full p-0 md:block"
        >
          <Swiper
            v-if="carousel.slides"
            ref="verticalImageSwiper"
            v-bind="verticalImageOptions"
            class=""
            :modules="[SwiperNavigation, SwiperController]"
            @swiper="setControlledSwiper"
          >
            <SwiperSlide
              v-for="(slide, index) in carousel.slides"
              :key="index + slide.sys.id"
              :data-slide="`slide-impact${index + slide.sys.id}`"
            >
              <div class="carousel__slide" :class="transformAspectRatio(slide)">
                <ImagesNuxtImg
                  :url="
                    slide.fields.image.fields.image.fields.file.url
                      ? slide.fields.image.fields.image.fields.file.url
                      : ''
                  "
                  :is-inline="true"
                  :alt="
                    slide.fields.image.fields.alt
                      ? slide.fields.image.fields.alt
                      : slide.fields.image.fields.image.fields.description
                  "
                  :class="{
                    'absolute bottom-0 left-0 right-0 top-0 mb-1 h-full w-full object-cover':
                      transformAspectRatio(slide) !== '',
                    'block w-full': transformAspectRatio(slide) === '',
                  }"
                  :lazy="false"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div
          v-if="carousel.slides"
          class="accordion--vertical relative mt-1 block md:hidden"
        >
          <div
            v-for="(slide, index) in carousel.slides"
            :key="index + slide.sys.id"
            class="accordion__slide mt-1"
            :class="{ active: isActive === index + slide.sys.id }"
            @click="toggleItem(index + slide.sys.id)"
          >
            <RichTextRenderer
              v-if="slide.fields.content"
              :document="slide.fields.content"
              :node-renderers="nodeRenderers"
            />
            <ImagesPicture
              v-if="slide.fields.image.fields"
              :image="slide.fields.image.fields"
              classes="w-full block"
              :lazy="true"
            />
            <div class="accordion__content exclude hidden pl-3"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      carousel: {
        type: Object,
        required: true,
      },
    },
    setup() {
      const controlledSwiper = ref(null);
      const setControlledSwiper = (swiper) => {
        controlledSwiper.value = swiper;
      };

      const verticalSwiper = ref();
      const onSwiperInit = (swiper) => {
        verticalSwiper.value = swiper;
      };

      return {
        controlledSwiper,
        setControlledSwiper,
        verticalSwiper,
        onSwiperInit,
      };
    },
    data() {
      return {
        isActive: 0 + this.carousel.slides[0].sys.id,
        clickIndex: 0 + this.carousel.slides[0].sys.id,
        document: this.carousel.content,
        hasAspectRatio: false,
        verticalOptions: {
          loop: false,
          direction: 'vertical',
          mousewheel: {
            releaseOnEdges: true,
          },
          slidesPerView: 1,
          allowTouchMove: false,
          height: 400,
          autoHeight: true,
          autoplay: {
            delay: 10000,
            disableOnInteraction: false,
          },
        },
        verticalImageOptions: {
          loop: false,
          direction: 'vertical',
          slidesPerView: 1,
          allowTouchMove: false,
          autoHeight: true,
          height: 270,
          breakpoints: {
            1200: {
              height: 700,
            },
          },
        },
      };
    },
    computed: {
      calcNavBorderheight() {
        return `${
          this.$refs.nav.offsetHeight -
          Math.trunc(
            this.$refs.navItems[this.$refs.navItems.length - 1].offsetHeight
          )
        }px`;
      },
    },
    mounted() {
      window.addEventListener('hashchange', this.hashChangeHandler, false);

      if (this.$refs.nav && this.$refs.navItems && this.$refs.navBorderRight) {
        this.$refs.navBorderRight.style.height = this.calcNavBorderheight;
      }

      const accordionSlides = document.querySelectorAll('.accordion__slide');
      accordionSlides.forEach((el) => {
        const header = el.querySelector('h1, h2, h3, h4, h5, h6');
        header.classList.add('accordion__expand', 'exclude', 'pl-3');
        const content = el.querySelectorAll(':scope > *:not(.exclude)');
        const expandContainer = el.querySelector('.accordion__content');
        for (const item of content) {
          expandContainer.appendChild(item);
        }
      });
    },
    unmounted() {
      window.removeEventListener('hashchange', this.hashChangeHandler, false);
    },
    methods: {
      slideTo(index, slideId) {
        this.clickIndex = index + slideId;
        this.verticalSwiper.slideTo(index);
      },
      setIndex() {
        this.clickIndex =
          this.verticalSwiper.activeIndex +
          this.carousel.slides[this.verticalSwiper.activeIndex].sys.id;
      },
      transformAspectRatio(slide) {
        const dictionary = {
          '16:9': 'aspect--16x9 h-0 relative block overflow-hidden',
          '3:2': 'aspect--3x2 h-0 relative block overflow-hidden',
          '1:1': 'aspect--1x1 h-0 relative block overflow-hidden',
          '4:3': 'aspect--4x3 h-0 relative block overflow-hidden',
          '': '',
        };

        return dictionary[
          slide.fields.mediaAspectRatio ? slide.fields.mediaAspectRatio : ''
        ];
      },
      toggleItem(index) {
        this.isActive = this.isActive === index ? null : index;
      },
      getLinkStyles(slide) {
        return slide.fields.jsonContent?.linkStyle ?? '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .carousel--vertical-image {
    left: 114%;

    @media (max-width: 1200px) {
      position: relative;
      left: auto;
      width: 66.667%;
      margin-left: 33.33%;
      padding-left: 10px;
      height: auto;
    }
  }
  .list--bullets {
    @apply relative list-none;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 90%;
      background: #caced4;
      left: 6px;
    }
  }
  .bullet--label {
    &.active > span {
      opacity: 1;
    }

    &.active {
      &::after {
        background-color: #caced4;
      }
    }

    &::after {
      content: '';
      background-color: #fff;
      border: 1px solid #caced4;
      border-radius: 100%;
      background-size: 12px 12px;
      background-repeat: no-repeat;
      width: 12px;
      height: 12px;
      position: absolute;
      left: 1px;
      top: 6px;
      transition: 0.1s ease-in-out;
    }
  }
  .accordion--vertical {
    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      height: 95%;
      width: 1px;
      background: #caced4;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;
    }
  }
  .accordion__slide {
    cursor: pointer;
    :deep(.p--large) {
      @apply px-3;
    }

    :deep(.accordion__expand) {
      position: relative;
      font-size: 22px;
      line-height: 28px;
      padding-left: 50px;

      &::before {
        background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/1QIRGpaF7nRqw0L8aTCPgV/dac79092bdb1498711d00c0c27bb468e/circle-non-active-mobile.png');
        width: 16px;
        height: 16px;
        background-size: contain;
        background-repeat: no-repeat;
        content: '';
        position: absolute;
        left: 22px;
        top: 5px;
        z-index: 3;
      }
    }
    .accordion__content hr {
      @apply ml-3;
    }

    &.active {
      .accordion__expand {
        &::before {
          background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/2gT4FVAwE4ZPZ9tF5QuzXg/e3c084f6f55300b15f1eb5970449d935/circle-active-mobile.png');
        }
      }
      .accordion__content {
        display: block;
      }
    }
  }
  .bold-blue-link {
    :deep(a) {
      @apply font-semibold text-blue;
    }
  }
</style>
