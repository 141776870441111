<template>
  <div>
    <a
      class="p--subhead scroll--arrow mb-0 cursor-pointer"
      :href="href"
      @click="scroll()"
    >
      {{ text }}
      <span>
        <svg
          class="ml-0.5 inline-block"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12.5"
            cy="12.5"
            r="11.5"
            stroke="#0384FB"
            stroke-width="2"
          />
          <path
            d="M16.9014 12L12.4508 17.1648L8.00027 12"
            stroke="#0384FB"
            stroke-width="2"
          />
          <path
            d="M12.4014 15L12.4014 8"
            stroke="#0384FB"
            stroke-width="2"
            stroke-linecap="square"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </a>
  </div>
</template>

<script>
  import { fadeIn } from '~/utils/html-helpers';

  export default {
    props: {
      text: {
        type: String,
        required: true,
      },
      href: {
        type: String,
        required: true,
      },
    },
    methods: {
      openModal(id) {
        const modal = document.querySelector(`#${id}`);
        fadeIn(modal, 'block');
        document.querySelector('html').classList.add('overflow-y-hidden');
      },
      scroll() {
        if (document.querySelector(this.href)) {
          document
            .querySelector(this.href)
            .scrollIntoView({ behavior: 'smooth' });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .p--subhead {
    @apply m-0 text-sm font-bold leading-5;

    color: theme('colors.blue.DEFAULT');
  }

  .scroll--arrow {
    &:hover {
      svg {
        circle {
          fill: #0384fb;
        }
        path {
          stroke: #fff;
        }
      }
    }
  }
</style>
