<template>
  <div class="carousel--cards w-full">
    <Swiper
      v-if="carousel.slides"
      ref="cardSwiper"
      v-bind="cardOptions"
      :modules="[SwiperNavigation]"
    >
      <SwiperSlide v-for="(slide, index) in carousel.slides" :key="index">
        <div class="carousel__slide h-full bg-white">
          <LinksLocaleLink
            v-if="slide.fields.link"
            :link="slide.fields.link"
            class="wrapper--card m-1/2 mt-1 block h-full shadow-lg transition-all duration-100 ease-in-out"
          >
            <div v-if="slide.fields.image.fields" class="wrapper--image">
              <ImagesPicture
                :image="slide.fields.image.fields"
                classes="object-cover"
                :lazy="true"
              />
            </div>
            <div v-if="slide.fields.content" class="wrapper--content p-1">
              <RichTextRenderer
                :document="slide.fields.content"
                :node-renderers="nodeRenderers"
              />
            </div>
          </LinksLocaleLink>
        </div>
      </SwiperSlide>
      <div slot="button-prev" class="prev--arrow">
        <svg
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="26" cy="26" r="26" fill="white" fill-opacity="1" />
          <path d="M29 16L39 26L29 36" stroke="#00263E" stroke-width="2" />
          <path d="M39 26H12" stroke="#00263E" stroke-width="2" />
        </svg>
      </div>
      <div slot="button-next" class="next--arrow">
        <svg
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="26" cy="26" r="26" fill="white" fill-opacity="1" />
          <path d="M29 16L39 26L29 36" stroke="#00263E" stroke-width="2" />
          <path d="M39 26H12" stroke="#00263E" stroke-width="2" />
        </svg>
      </div>
    </Swiper>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },

    props: {
      carousel: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        document: this.carousel.content,
        cardOptions: {
          loop: true,
          navigation: {
            nextEl: '.next--arrow',
            prevEl: '.prev--arrow',
          },
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 3,
          allowTouchMove: true,
          breakpoints: {
            0: {
              slidesPerView: 1,
            },
            550: {
              slidesPerView: 1,
            },
            736: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 3,
            },
            1440: {
              slidesPerView: 4,
            },
            2250: {
              slidesPerView: 5,
            },
          },
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .prev--arrow,
  .next--arrow {
    position: absolute;
    width: 52px;
    height: 52px;
    border-radius: 100%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background: white;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    cursor: pointer;
  }

  .prev--arrow {
    left: 0;
    margin-left: 20px;

    svg {
      transform: scaleX(-1);
    }
  }

  .next--arrow {
    right: 0;
    margin-right: 20px;
  }

  .swiper-container {
    padding: 0 100px 40px 100px;
    position: relative;

    @media (max-width: theme('screens.md')) {
      padding: 0 50px 40px 50px;
    }

    &::before,
    &::after {
      background: linear-gradient(
        90deg,
        #f0f6fe,
        hsla(0, 0%, 100%, 0.0001) 98.11%
      );
      position: absolute;
      top: 0;
      height: 100%;
      width: 100px;
      content: '';
      z-index: 1;
    }

    &::before {
      right: 0;
      transform: scale(-1);
      z-index: 2;
    }

    &::after {
      left: 0;
    }
  }

  .swiper-slide {
    height: auto;
  }

  .wrapper--card {
    border-bottom: 4px solid transparent;

    &:hover {
      @apply shadow-xl;

      border-bottom: 4px solid theme('colors.teal.DEFAULT');
      transform: translateY(-10px);
    }
  }

  :deep(.p--xs-eyebrow) {
    @apply text-blue;
  }
</style>
