<template>
  <div ref="markdown" v-html="compiledMarkdownMarkdown"></div>
</template>

<script>
  import { marked } from 'marked';

  export default {
    props: {
      source: { default: '', type: String },
    },
    computed: {
      compiledMarkdownMarkdown: function () {
        return marked(this.source);
      },
    },
  };
</script>
