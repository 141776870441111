<template>
  <LinksLocaleLink
    v-if="card.link"
    :link="card.link"
    :class="{
      'lc-filter-greyscale': filter === 'Greyscale',
      'shadow-3': dropshadow,
      'lc-filter-color': filter === 'Color',
    }"
    class="lc-card--logo-link flex flex-1 rounded-[16px]"
  >
    <div class="lc-card--logo-link-content w-full self-center">
      <ImagesPicture
        v-if="card.image"
        :image="card.image.fields"
        class="relative mt-1/2 flex w-full"
      />
      <slot name="copy"></slot>
      <LinksContentfulLink v-if="id" :id="id" label="card" />
    </div>
  </LinksLocaleLink>
  <div
    v-else
    :class="{
      'lc-filter-greyscale': filter === 'Greyscale',
      'shadow-3': dropshadow,
      'lc-filter-color': filter === 'Color',
    }"
    class="hover--none flex flex-1 rounded-[16px]"
  >
    <div class="lc-card--logo-link-content w-full self-center">
      <ImagesPicture
        v-if="card.image"
        :image="card.image.fields"
        class="relative mt-1/2 flex w-full"
      />
      <slot name="copy"></slot>
      <LinksContentfulLink v-if="id" :id="id" label="card" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      card: {
        type: Object,
        required: true,
      },
      mobileLayout: {
        type: String,
        required: false,
        default: '2 Columns',
      },
      filter: {
        type: String,
        required: false,
        default: 'Greyscale',
      },
      dropshadow: {
        type: Boolean,
        required: true,
        default: true,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .lc-card--logo-link {
    @apply inline-flex bg-white transition-transform duration-100 ease-in-out my-1/2;

    &:deep(img) {
      @apply relative w-full max-w-[95px];

      margin: 0 auto;
    }

    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    &:hover {
      transform: none;
    }

    @screen md {
      &:hover {
        transform: scale(1.1);
        z-index: 999;
      }
    }

    &.lc-filter-greyscale :deep(img) {
      filter: grayscale(100%);
      opacity: 0.5;
    }

    &.lc-filter-greyscale:hover :deep(img) {
      filter: none;
      opacity: 1;
    }

    &.hover--none {
      &:hover {
        transform: none;
      }
    }
  }
</style>
