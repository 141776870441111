import { localeMappings } from '~/constants/locales';
import { checkEntriesLocale } from '~/utils';

const getContentfulPage = async ({ options }) => {
  const { locale } = useI18n();
  const { $contentfulClient } = useNuxtApp();

  const pageType =
    options.content_type === 'modularPage' ? 'modularPage' : 'page';
  const defaultOptions = {
    locale: localeMappings[locale.value],
    include: 6,
  };

  const parameters = {
    ...options,
    ...defaultOptions,
  };

  try {
    const entries = await $contentfulClient.getEntries(parameters);
    return checkEntriesLocale(entries, pageType, true);
  } catch (error) {
    // wrap non-Error objects in an Error to avoid Sentry issues
    throw createError({
      statusCode: 500,
      statusMessage:
        error instanceof Error
          ? error.message
          : String(error) || 'Unknown error',
    });
  }
};

export default defineNuxtPlugin(() => {
  return {
    provide: {
      getContentfulPage,
    },
  };
});
