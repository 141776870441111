<template>
  <div
    class="Rtable-cell flex w-full flex-grow list-none overflow-hidden"
    :class="[
      {
        'border-0 bg-blue-dark uppercase':
          props.cell.fields.type !== undefined
            ? props.cell.fields.type === 'Header'
            : false,
        'justify-left items-center':
          props.cell.fields.textAlignment !== undefined
            ? props.cell.fields.textAlignment ===
              'Left Justified Text, Vertical Center'
            : false,
        'items-center justify-center text-center':
          props.cell.fields.textAlignment !== undefined
            ? props.cell.fields.textAlignment ===
              'Center Justified Text, Horizontal Center'
            : false,
        borderless: cell.fields.type === 'Borderless',
      },
      backgroundColor,
    ]"
  >
    <p
      v-if="props.cell.fields"
      :class="{
        'font-semibold text-white':
          props.cell.fields.type !== undefined
            ? props.cell.fields.type === 'Header'
            : false,
      }"
      v-html="props.cell.fields.content ? props.cell.fields.content : ''"
    ></p>
    <ImagesPicture
      v-if="props.cell.fields && props.cell.fields.image"
      :image="props.cell.fields.image.fields"
    />
  </div>
</template>

<script setup>
  const props = defineProps({
    cell: {
      type: Object,
      required: true,
    },
  });

  const backgroundColor = computed(() => {
    const colorsDict = {
      'Light Blue': 'bg-blue-lighter',
    };

    return colorsDict[props.cell.fields.backgroundColor]
      ? colorsDict[props.cell.fields.backgroundColor]
      : 'bg-white';
  });
</script>

<style lang="scss" scoped>
  .Rtable-cell {
    padding: 0.8em 1.2em;
    outline: 1px solid #e1e1e1;
    p {
      margin: 0;

      @media (max-width: theme('screens.md')) {
        font-size: 13px;
        line-height: 20px;
      }
    }

    @media (max-width: 500px) {
      padding: 0.8em 0.8em;
      font-size: 0.9em;
    }
  }

  .Rtable-cell > h1,
  .Rtable-cell > h2,
  .Rtable-cell > h3,
  .Rtable-cell > h4,
  .Rtable-cell > h5,
  .Rtable-cell > h6 {
    margin: 0;
  }

  .Rtable-cell.borderless {
    outline: none !important;
  }
</style>
