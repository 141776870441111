import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src4016038410/src/horizon/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src4016038410/src/horizon/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src4016038410/src/horizon/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/codebuild/output/src4016038410/src/horizon/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src4016038410/src/horizon/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src4016038410/src/horizon/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src4016038410/src/horizon/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src4016038410/src/horizon/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src4016038410/src/horizon/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_hwlJ5PmHMh from "/codebuild/output/src4016038410/src/horizon/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import plugin_jBToDIZpFa from "/codebuild/output/src4016038410/src/horizon/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import nuxt_plugin_6wEQMY3tee from "/codebuild/output/src4016038410/src/horizon/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import i18n_yfWm7jX06p from "/codebuild/output/src4016038410/src/horizon/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_t2GMTTFnMT from "/codebuild/output/src4016038410/src/horizon/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src4016038410/src/horizon/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/codebuild/output/src4016038410/src/horizon/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import analytics_mixpanel_client_KsTLYJ1HGU from "/codebuild/output/src4016038410/src/horizon/plugins/analytics-mixpanel.client.ts";
import analytics_page_client_Z0Ih4fJOaW from "/codebuild/output/src4016038410/src/horizon/plugins/analytics-page.client.ts";
import contentful_rest_jatwBhQm2u from "/codebuild/output/src4016038410/src/horizon/plugins/contentful-rest.ts";
import datadog_client_cfAAU11B0P from "/codebuild/output/src4016038410/src/horizon/plugins/datadog.client.ts";
import emitter_eaMgM0oZTb from "/codebuild/output/src4016038410/src/horizon/plugins/emitter.ts";
import get_contentful_page_locales_yHw6Zyjjap from "/codebuild/output/src4016038410/src/horizon/plugins/get-contentful-page-locales.ts";
import get_contentful_page_ePclQmDq2k from "/codebuild/output/src4016038410/src/horizon/plugins/get-contentful-page.ts";
import graphql_on_error_3QzmGOazd4 from "/codebuild/output/src4016038410/src/horizon/plugins/graphql-on-error.ts";
import launch_darkly_client_QMCmz2rq1I from "/codebuild/output/src4016038410/src/horizon/plugins/launch-darkly.client.ts";
import sentry_client_shVUlIjFLk from "/codebuild/output/src4016038410/src/horizon/plugins/sentry.client.ts";
import set_session_client_s47LienHRC from "/codebuild/output/src4016038410/src/horizon/plugins/set-session.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_hwlJ5PmHMh,
  plugin_jBToDIZpFa,
  nuxt_plugin_6wEQMY3tee,
  i18n_yfWm7jX06p,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  analytics_mixpanel_client_KsTLYJ1HGU,
  analytics_page_client_Z0Ih4fJOaW,
  contentful_rest_jatwBhQm2u,
  datadog_client_cfAAU11B0P,
  emitter_eaMgM0oZTb,
  get_contentful_page_locales_yHw6Zyjjap,
  get_contentful_page_ePclQmDq2k,
  graphql_on_error_3QzmGOazd4,
  launch_darkly_client_QMCmz2rq1I,
  sentry_client_shVUlIjFLk,
  set_session_client_s47LienHRC
]