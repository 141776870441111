<template>
  <div :class="inline ? 'inline-block' : 'block'">
    <p
      class="a--link-arrow-arrowless relative inline-block cursor-pointer pb-0 font-bold text-blue no-underline"
      :class="inline ? 'mb-1 md:mb-0' : ''"
      @click.prevent="openModal(arrowlink.linkUrl)"
    >
      {{ arrowlink.text
      }}<span
        ><svg
          class="ml-1/2 inline-block"
          width="13"
          height="14"
          viewBox="0 0 13 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.45453 1H12V7.54545" stroke="#0384FB" stroke-width="1.5" />
          <rect
            x="1.09091"
            y="4.27271"
            width="7.63636"
            height="8.72727"
            fill="white"
            stroke="#0384FB"
            stroke-width="1.5"
          />
        </svg>
      </span>
    </p>
  </div>
</template>

<script>
  import { fadeIn } from '~/utils/html-helpers';

  export default {
    props: {
      arrowlink: {
        type: Object,
        required: true,
      },
      inline: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    methods: {
      openModal(id) {
        const modal = document.querySelector(`#${id}`);
        fadeIn(modal, 'block');
        document.querySelector('html').classList.add('overflow-y-hidden');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .p--subhead {
    @apply font-medium text-blue-dark;
  }
</style>
