<template>
  <div v-if="image.type === 'Mobile Device'" class="device device--mobile">
    <ImagesPicture
      :lazy="true"
      :image="image"
      :is-inline="inline"
      :class="wrapperClasses"
    />
    <LinksContentfulLink v-if="id" :id="id" label="image" />
  </div>
  <div
    v-else-if="image.type === 'Desktop Device'"
    class="device device--laptop"
  >
    <ImagesPicture
      :lazy="true"
      :image="image"
      :is-inline="inline"
      :class="wrapperClasses"
    />
    <LinksContentfulLink v-if="id" :id="id" label="image" />
  </div>
  <div v-else-if="image.type === 'Right Aligned'" class="clearfix float-right">
    <ImagesPicture
      :lazy="true"
      :image="image"
      :is-inline="inline"
      :class="wrapperClasses"
    />
    <LinksContentfulLink v-if="id" :id="id" label="image" />
  </div>
  <span v-else-if="image.type === 'Inline'">
    <LinksLocaleLink
      v-if="image.href"
      :link="image.href"
      :class="wrapperClasses"
    >
      <ImagesPicture
        :image="image"
        :is-inline="inline"
        :lazy="true"
        classes="float-left clearfix pr-1/2"
      />
    </LinksLocaleLink>
    <ImagesPicture
      v-else
      :class="wrapperClasses"
      :image="image"
      :is-inline="inline"
      :lazy="true"
      classes="float-left clearfix pr-1/2"
    />
    <LinksContentfulLink v-if="id" :id="id" label="image" />
  </span>
  <div v-else-if="image.type === 'Grow'">
    <LinksLocaleLink
      v-if="image.href"
      :link="image.href"
      :class="wrapperClasses"
    >
      <ImagesPicture
        :image="image"
        :is-inline="inline"
        :lazy="true"
        :query-params="'?fit=pad&w=300&h=225&fm=jpg&q=60'"
        classes="duration-200 transition-all rounded-sm hover--shadow-scale mb-1 w-full"
      />
    </LinksLocaleLink>
    <ImagesPicture
      v-else
      :class="wrapperClasses"
      :lazy="true"
      :image="image"
      :is-inline="inline"
      :query-params="'?fit=pad&w=300&h=225&fm=jpg&q=60'"
      :classes="'duration-200 transition-all rounded-sm hover--shadow-scale mb-1'"
    />
    <LinksContentfulLink v-if="id" :id="id" label="image" />
  </div>
  <LinksLocaleLink
    v-else-if="image.href"
    :link="image.href"
    :class="wrapperClasses"
  >
    <ImagesPicture
      ref="picture"
      :image="image"
      :is-inline="inline"
      :lazy="true"
    />
    <LinksContentfulLink v-if="id" :id="id" label="image" />
  </LinksLocaleLink>
  <ImagesPicture
    v-else
    :id="id"
    ref="picture"
    :image="image"
    :is-inline="inline"
    :class="wrapperClasses"
    :lazy="true"
  />
</template>
<style lang="scss" scoped>
  :deep(.hover--shadow-scale) {
    &:hover {
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
      transform: scale(1.05);
    }
  }
</style>
<script>
  export default {
    props: {
      image: {
        type: Object,
        required: true,
      },
      inline: {
        type: Boolean,
        required: false,
        default: false,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
      notLazy: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      wrapperClasses() {
        if (this.image.classes !== '' && this.image.classes !== undefined) {
          return this.image.classes.join(' ');
        } else {
          return '';
        }
      },
    },
  };
</script>
