export const options = {
  "images": false,
  "videos": true,
  "audios": true,
  "iframes": false,
  "native": false,
  "directiveOnly": true,
  "defaultImage": "/public/default-01.png",
  "loadingClass": "isLoading",
  "loadedClass": "isLoaded",
  "appendClass": "lazyLoad",
  "observerConfig": {}
}