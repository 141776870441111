<template>
  <div
    v-if="isModalOpen"
    class="modal z-100 modal--row"
    @keyup.esc="$emit('closeVideoModal'), closeModal()"
  >
    <span
      v-if="closeLocation === 'outer'"
      class="modular-modal-close absolute right-[20px] top-[20px] z-[120]"
      :class="{ outer: closeLocation === 'outer' }"
      @click.prevent="$emit('closeVideoModal'), closeModal()"
      @keydown.enter="$emit('closeVideoModal'), closeModal()"
    >
      <IconsSvgCloseIcon :stroke="closeStroke" />
    </span>
    <div
      class="modal__inner relative z-30 overflow-y-auto overflow-x-hidden"
      :class="bg ? bg : ''"
    >
      <span
        v-if="closeLocation === 'inner' || !closeLocation"
        class="modular-modal-close absolute right-[20px] top-[20px] z-[120]"
        @click.prevent="$emit('close'), closeModal()"
        @keydown.enter="$emit('close'), closeModal()"
      >
        <IconsSvgCloseIcon :stroke="'black'" />
      </span>
      <div class="yt-embed-container relative h-full w-full">
        <iframe
          v-if="youtubePlayerState === 'play'"
          id="youtubeiframe"
          ref="ytVideo"
          v-lazy-load
          :title="title"
          :src="'https://www.youtube.com/embed/' + video.id"
          class="youtubeIframe relative h-full w-full"
          allow="autoplay; fullscreen"
        ></iframe>
        <LinksContentfulLink v-if="id" :id="id" label="video" />
      </div>
    </div>
    <div
      v-if="isModalOpen"
      class="absolute left-0 top-0 z-10 h-screen w-screen"
      @click.prevent="$emit('close')"
      @keydown.enter="$emit('close')"
    ></div>
  </div>
</template>
<script>
  export default {
    props: {
      isOpen: {
        type: Boolean,
        required: false,
        default: false,
      },
      closeLocation: {
        type: String,
        required: false,
        default: 'inner',
      },
      bg: {
        type: String,
        required: false,
        default: null,
      },
      closeStroke: {
        type: String,
        required: false,
        default: 'white',
      },
      video: {
        type: Object,
        required: true,
      },
      youtubePlayerState: {
        type: String,
        default: 'unstarted',
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
      title: {
        type: String,
        required: false,
        default: 'Youtube Video',
      },
    },
    emits: ['closeVideoModal', 'close', 'modal-close'],
    data() {
      return {
        interval: null,
        youtubeVideoEl: null,
        isModalOpen: false,
      };
    },
    watch: {
      isOpen() {
        if (this.isOpen === false) {
          window.removeEventListener('keyup', this.onEscapeKeyUp);
          document.removeEventListener('keydown', this.keyDown);
          document.querySelector('html').classList.remove('overflow-y-hidden');
          this.isModalOpen = false;
        } else {
          document.querySelector('html').classList.add('overflow-y-hidden');
          this.isModalOpen = true;
        }
      },
      youtubePlayerState(newValue) {
        if (newValue === 'play') {
          this.checkYtVideo();
        }
      },
    },
    mounted() {
      if (this.youtubePlayerState === 'play') {
        this.checkYtVideo();
      }
    },
    methods: {
      closeModal() {
        document.removeEventListener('keydown', this.keyDown);
        this.isModalOpen = false;
        this.clearIntervalPoll();
      },
      onEscapeKeyUp(event) {
        if (event.which === 27) {
          this.$emit('modal-close');
        }
      },
      checkYtVideo() {
        if (this.interval === null) {
          this.interval = setInterval(this.playYtVideo, 300);
        }
      },
      clearIntervalPoll() {
        clearInterval(this.interval);
        this.interval = null;
        this.youtubeVideoEl = null;
      },
      playYtVideo() {
        if (!this.youtubeVideoEl) {
          this.youtubeVideoEl = document.querySelector('#youtubeiframe');
        }
        if (this.youtubeVideoEl !== undefined) {
          const src = this.youtubeVideoEl.src + '?autoplay=1';
          this.youtubeVideoEl.setAttribute('src', src);
          this.clearIntervalPoll();
        } else {
          this.clearIntervalPoll();
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal {
    display: block;
  }

  .modular-modal-close.outer {
    @screen md {
      @apply right-[10vw] top-[5vh];
      &:hover {
        @apply scale-105;
      }
    }
  }
  .image--cover-image {
    &:hover {
      .svg--play-icon {
        transform-origin: center;
        transition: transform 300ms ease;
        transform: scale(1.1);
      }
    }
    &::after {
      content: '';
      @apply absolute bottom-0 w-full;

      height: 66%;
      background: linear-gradient(0deg, #000 -20.25%, rgba(0, 0, 0, 0) 87%);
    }
  }
</style>
