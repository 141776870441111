<template>
  <div v-if="symbol.type === 'Vertical Divider Line'" :class="symbolClasses">
    <LinksContentfulLink v-if="id" :id="id" label="symbol" />
  </div>
  <!-- add link -->
  <hr :class="symbolClasses" />
</template>

<script>
  export default {
    props: {
      symbol: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
    },
    computed: {
      symbolClasses() {
        const styles = {
          'Vertical Divider Line': 'hr hr--vertical-divider',
          'Full Divide No Padding': 'border-t-0 pt-0 mb-0 border-b-1',
          'Small Divide Line': 'hr hr--short-divider border-b-1',
          'Full Width Divider': 'hr border-b-1 w-full block',
          'Thick Full Width Divider': 'hr w-full block border-b-2',
        };
        const colors = {
          Teal: 'border-teal',
          Grey: 'border-gray-light',
          White: 'border-white',
          Navy: 'border-blue-dark',
          Default: 'border-blue-dark',
          'Dark Grey': 'border-gray-dark',
          Green: 'border-green',
          'Light Grey': 'border-gray-light',
          Blue: 'border-blue',
        };
        const color = this.symbol.color
          ? colors[this.symbol.color]
          : colors.Default;
        const alignments = {
          Default: 'mx-auto',
          Left: 'ml-0 mr-auto',
          Center: 'mx-auto',
          Right: 'ml-auto mr-0',
        };
        const alignment = this.symbol.alignment
          ? alignments[this.symbol.alignment]
          : alignments.Default;

        return `${styles[this.symbol.type]} ${color} ${alignment}`;
      },
    },
  };
</script>
