import { NavigationDirection } from '../types/enums'; // import the enums we want to use

export function useScrollDirection() {
  const scrollDirection = ref(NavigationDirection.UNSET); // 'up' or 'down' to use Enums!
  let lastScrollPosition = 0;

  const handleScroll = () => {
    const currentScrollPosition =
      window.scrollY || document.documentElement.scrollTop;

    if (currentScrollPosition === 0) {
      scrollDirection.value = NavigationDirection.TOP;
    } else {
      if (currentScrollPosition > lastScrollPosition) {
        scrollDirection.value = NavigationDirection.DOWN;
      } else if (currentScrollPosition < lastScrollPosition) {
        scrollDirection.value = NavigationDirection.UP;
      }
      lastScrollPosition =
        currentScrollPosition <= 0 ? 0 : currentScrollPosition;
    }
  };

  onMounted(() => {
    if (window.scrollY > 0) {
      scrollDirection.value = NavigationDirection.UNSET;
    }
    window.addEventListener('scroll', handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });

  return scrollDirection;
}
