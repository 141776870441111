import { defineNuxtPlugin } from '#app/nuxt'
import { LazyCarouselsCardSlider, LazyCarouselsContentSlider, LazyCarouselsDashCamCarousel, LazyCarouselsDefaultSlider, LazyCarouselsDynamicSlider, LazyCarouselsImageSlider, LazyCarouselsInstagramSlider, LazyCarouselsMobileOnly, LazyCarouselsModalSlider, LazyCarouselsPricingPlansSlider, LazyCarouselsProductSlider, LazyCarouselsSuccessSlider, LazyCarouselsVerticalSlider } from '#components'
const lazyGlobalComponents = [
  ["CarouselsCardSlider", LazyCarouselsCardSlider],
["CarouselsContentSlider", LazyCarouselsContentSlider],
["CarouselsDashCamCarousel", LazyCarouselsDashCamCarousel],
["CarouselsDefaultSlider", LazyCarouselsDefaultSlider],
["CarouselsDynamicSlider", LazyCarouselsDynamicSlider],
["CarouselsImageSlider", LazyCarouselsImageSlider],
["CarouselsInstagramSlider", LazyCarouselsInstagramSlider],
["CarouselsMobileOnly", LazyCarouselsMobileOnly],
["CarouselsModalSlider", LazyCarouselsModalSlider],
["CarouselsPricingPlansSlider", LazyCarouselsPricingPlansSlider],
["CarouselsProductSlider", LazyCarouselsProductSlider],
["CarouselsSuccessSlider", LazyCarouselsSuccessSlider],
["CarouselsVerticalSlider", LazyCarouselsVerticalSlider],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
