<template>
  <div class="relative my-2 flex flex-row md:my-3">
    <div
      class="quote__illustration flex w-2/12 flex-col items-center md:absolute md:h-full md:w-auto"
    >
      <svg
        width="58"
        height="55"
        viewBox="0 0 58 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mb-1 w-full md:w-auto"
      >
        <path
          d="M21.5684 31.0521V55H0V36.0938C0 25.8576 1.19204 18.4479 3.57611 13.8646C6.7052 7.75347 11.6596 3.13194 18.4393 0L23.3565 8.02083C19.2588 9.77778 16.2415 12.4132 14.3044 15.9271C12.3674 19.3646 11.2871 24.4062 11.0636 31.0521H21.5684ZM56.2119 31.0521V55H34.6435V36.0938C34.6435 25.8576 35.8356 18.4479 38.2197 13.8646C41.3487 7.75347 46.3031 3.13194 53.0829 0L58 8.02083C53.9024 9.77778 50.885 12.4132 48.948 15.9271C47.0109 19.3646 45.9306 24.4062 45.7071 31.0521H56.2119Z"
          fill="#0A88FB"
        />
      </svg>
      <div class="line--blue mt-1/4 flex-auto bg-blue opacity-50"></div>
    </div>
    <div class="md:px-auto w-10/12 px-gap md:w-12/12">
      <MarkdownTheMarkdown :source="content.content" class="content--quote pt-1" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .card--blog-quote {
    .line--blue {
      width: 1px;
    }
    .content--quote {
      :deep() {
        p:first-child {
          @apply text-blue-dark;
          @apply text-lg;

          line-height: 30px;
          @screen md {
            font-size: 28px;
            line-height: 46px;
          }
        }
        h5 {
          @apply mb-0;
        }
        p:last-child {
          @apply mb-0;

          font-family: 'Times New Roman', Times, serif;
          font-size: 15px;
        }
      }
    }
    .quote__illustration {
      @screen md {
        @apply mr-2;

        right: 100%;
      }
    }
  }
</style>
<script>
  export default {
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
  };
</script>
